export default list => {
  const countries = [];
  list.forEach(function(item, index) {
    countries.push(new country(item, index));
  });
  return countries;
};

function country(code, index) {
  this.index = index;
  this.nationalityCode = code;
}
