export const joinPath = (base, path) => {
  return base.charAt(base.length - 1) === "/" ? base + path : base + "/" + path;
};

export const wait = duration =>
  new Promise(resolve => setTimeout(resolve, duration));

export const isNotEmptyObject = obj => {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return true;
    }
  }
  return false;
};

export const isEmptyObject = obj => {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
};

export const sanitiseField = fieldVal => {
  if (typeof fieldVal !== "undefined") {
    var trimmedField = fieldVal
      .trim()
      .replace(/&nbsp;/g, "")
      .replace(/\s\s+/g, " ")
      .replace(/[’‘]/g, "'");
    return trimmedField !== "" ? trimmedField : undefined;
  }
};

export const removeFields = (values, array) => {
  for (var i = 0; i < array.length; i++) {
    values[array[i]] = undefined;
  }
};
