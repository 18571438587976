import { businessRole } from "../../i18n/lookups/businessRole";

export default code => {
  var status;
  for (var i = businessRole.length; i--; ) {
    if (businessRole[i].value === code) {
      status = businessRole[i].label;
      break;
    }
  }
  return status;
};
