const leadingZeros = input => {
  if (input < 10) {
    return "0" + input;
  } else return input;
};

const year = value => parseInt(value.split("-")[0]);
const month = value => parseInt(value.split("-")[1]);

export default (startDate, endDate) => {
  let endDateYears = endDate ? year(endDate) : new Date().getFullYear();
  let endDateMonths = endDate ? month(endDate) : new Date().getMonth() + 1;
  let startDateYears = year(startDate);
  let startDateMonths = month(startDate);
  let endDateTotalMonths = endDateYears * 12 + endDateMonths;
  let startDateTotalMonths = startDateYears * 12 + startDateMonths;
  let diffInMonths = endDateTotalMonths - startDateTotalMonths;
  let years = parseInt(diffInMonths / 12);
  let months = diffInMonths % 12;
  return leadingZeros(years) + ":" + leadingZeros(months);
};
