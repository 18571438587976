export default {
  pageTitle: "Contact details",
  pageIntro:
    "Let us know how we can get in touch. We'll only contact you about your application.",
  sectionTitle: "Email and phone number",
  email: {
    label: "Your email address",
    hint: "We'll use this email to contact you about your application.",
    errorBlank: "Enter your email address",
    errorInvalid: "Enter a valid email address"
  },
  telephone: {
    label: "Mobile number",
    hint: "UK mobile numbers only.",
    errorBlank: "Enter your mobile telephone number",
    errorInvalid: "Enter a valid telephone number"
  },
  telephoneHome: {
    label: "Home number (optional)",
    review: "Home number",
    hint: "UK telephone numbers only.",
    errorInvalid: "Enter a valid telephone number"
  },
  telephoneWork: {
    label: "Work number (optional)",
    review: "Work number",
    hint: "UK telephone numbers only.",
    errorInvalid: "Enter a valid telephone number"
  },
  currentAddress: {
    title: "Current residential address",
    introCopy: "We need this to help us verify your identity."
  },
  postCode: {
    label: "Postcode",
    errorBlank: "Enter your postcode",
    errorInvalid: "Enter a valid postcode",
    errorCiiom:
      "We are not able to accept applications from the Channel Islands and the Isle of Man"
  },
  houseNumber: {
    label: "House number",
    errorBlank:
      "You must enter either your house number, flat number or house name",
    errorInvalid:
      "Enter your house number using only alphanumeric characters and basic punctuation"
  },
  flatNumber: {
    label: "Flat number",
    errorBlank:
      "You must enter either your flat number, house name or house number",
    errorInvalid:
      "Enter your flat number using only alphanumeric characters and basic punctuation"
  },
  houseName: {
    label: "House name",
    errorBlank:
      "You must enter either your house name, house number or flat name",
    errorInvalid:
      "Enter your house name using only alphanumeric characters and basic punctuation"
  },
  address1: {
    label: "Address line 1",
    errorBlank: "Enter address line 1",
    errorInvalid:
      "Enter address line 1 using only alphanumeric characters and basic punctuation"
  },
  address2: {
    label: "Address line 2",
    errorBlank: "Enter address line 2",
    errorInvalid:
      "Enter address line 2 using only alphanumeric characters and basic punctuation"
  },
  address3: {
    label: "Address line 3 (optional)",
    errorInvalid:
      "Enter address line 3 using only alphanumeric characters and basic punctuation"
  },
  residentialStatus: {
    label: "Residential status",
    errorBlank: "Select your residential status"
  },
  moveDate: {
    label: "When did you move to this address?",
    hint: "We need to understand where you've lived for the past 3 years.",
    errorBlank:
      "Tell us where you've lived for the past 3 years. We need a maximum of 5 addresses",
    errorInvalid:
      "Enter the date you moved to this address in the format MM YYYY",
    errorDOB:
      "You've entered a move date that's before your birth date. Check and re-enter",
    errorMax: "The date you entered is in the future",
    errorMin: "Check if the date you've entered is correct",
    errorNeedMore: "Tell us where you lived before ",
    errorOrder:
      "Enter your previous address history in order, from the most recent to the oldest, for the last 3 years"
  },
  countrySelect: {
    label: "Country",
    placeholder: "Please select",
    errorBlank: "Select the country for your previous address"
  }
};
