import { isNotEmptyObject } from "../utils";
import { sanitiseField } from "../utils";

export default previousNames => {
  const names = [];
  for (var i = 0; i < previousNames.length; i++) {
    if (isNotEmptyObject(previousNames[i])) {
      names.push(
        new name(
          sanitiseField(previousNames[i].previousForename),
          previousNames[i].previousMiddlenames
            ? sanitiseField(previousNames[i].previousMiddlenames)
            : undefined,
          sanitiseField(previousNames[i].previousSurname),
          i
        )
      );
    }
  }
  return names;
};

function name(forename, middlename, surname, index) {
  this.index = index;
  this.forename = forename;
  this.middleName = middlename;
  this.surname = surname;
}
