export default array => {
  if (array && array.filter(value => value !== "").length > 0) {
    const countries = [];
    array.forEach((countryCode, i) => {
      countries.push(new country(countryCode, i));
    });
    return countries;
  } else {
    return undefined;
  }
};

function country(country, index) {
  this.index = index;
  this.resCntryCodeForTax = country;
}
