import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ms: props.ms
    };
  }
  componentWillMount() {
    this.interval = setInterval(() => {
      if (this.state.ms <= 0) {
        clearInterval(this.interval);
        this.forceUpdate();
        return;
      }
      this.setState({ ms: this.state.ms - 1000 });
    }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  format() {
    const { ms } = this.state;
    let seconds = Math.floor(ms / 1000);
    seconds = seconds < 1 ? "00" : seconds < 10 ? `0${seconds}` : seconds;
    return `${seconds}`;
  }
  render() {
    return <Fragment>{this.format(this.state.ms)}</Fragment>;
  }
}

export default Countdown;

Countdown.propTypes = {
  ms: PropTypes.number
};
