import React, { Component } from "react";
import { AppConsumer } from "../../shared/appContext";
import {
  Callout,
  Heading,
  Paragraph,
  UnorderedList,
  ListItem,
  Section,
  PlaybackKey,
  PlaybackList,
  PlaybackValue,
  Reveal
} from "@piggybank/core";
import { FieldFeedback, Field, Label, NumberInput } from "@piggybank/form";
import "./Account.css";

import axios from "axios";
import FindAddressButton from "../../components/FindAddressButton";
import buttonContent from "../../i18n/content/validateBankDetails";
import bankDetailsContent from "../../i18n/content/bankDetails";
import BankDetailsErrors from "../../components/BankDetailsErrors";
import headers from "../../shared/headers";
import { title } from "../../shared/transformations";

const prependZeros = (n, len) => {
  if (n.length >= 1) {
    return (new Array(len + 1).join("0") + n).slice(-len);
  } else return n;
};

const concatenatedName = values => {
  let titleInput = title(values["title"]) + " ";
  let forenameInput = values["forename"];
  let forename = forenameInput.split(" ")[0] + " ";
  let surname = values["surname"];
  let firstInitial = forename.slice(0, 1) + " ";
  let fullNameConcatenation = titleInput + forename + surname;
  let firstInitialConcatenation = titleInput + firstInitial + surname;
  let noInitialConcatenation = titleInput + surname;
  if (fullNameConcatenation.length <= 30) {
    values["account-holders-name"] = fullNameConcatenation;
    return fullNameConcatenation;
  }
  if (fullNameConcatenation.length > 30) {
    if (firstInitialConcatenation.length <= 30) {
      values["account-holders-name"] = firstInitialConcatenation;
      return firstInitialConcatenation;
    } else {
      values["account-holders-name"] = noInitialConcatenation;
      return noInitialConcatenation;
    }
  }
};

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BDVValid: false,
      timerFinished: false,
      loading: false,
      BDVError: false,
      BDVReceived: false,
      BDVCallError: false
    };
    this.BVDCall = this.BVDCall.bind(this);
  }

  setFocus = values => {
    if (document.getElementById("accountNumber-field") != null) {
      document.getElementById("accountNumber-field").focus();
      document.getElementById("accountNumber-field").click();
    }
  };

  bdvCounterCheck = values => {
    if (this.context.BDV >= 2) {
      this.context.BDV = this.context.BDV + 1;
      this.context.BDVVerified = false;
      values["account-holders-name"] = undefined;
      values.bankDetailsCorrect = "N";
      values.bankName = "";
      var bankDetailsElement = document.getElementById("bank-details");
      bankDetailsElement.scrollIntoView();
    }
  };

  resetBdvValues = values => {
    this.context.BDVVerified = false;
    values.bankDetailsCorrect = "N";
    values.bankName = "";
  };

  timer = time => {
    setTimeout(() => {
      this.loaded();
    }, time);
  };

  loaded = () => {
    this.setState(() => ({
      timerFinished: !this.state.timerFinished
    }));

    this.state.timerFinished &&
      this.state.BDVReceived &&
      this.setState(() => ({
        loading: false,
        timerFinished: false,
        BDVReceived: false
      }));
  };

  clickHandler = async values => {
    this.context.Continue = false;

    // if sort code valid/invalid set flag
    if (this.validation(values)) {
      this.context.BDV = this.context.BDV + 1;
      this.context.verifiedSortCode = values.sortCode;
      this.context.verifiedAccountNumber = values.accountNumber;

      this.setState(
        {
          BDVError: false
        },
        () => this.BVDCall(values)
      );
    } else {
      this.context.Continue = true;
      this.bdvCounterCheck(values);
      this.setState({
        BDVError: true
      });
    }
  };

  validation = values => {
    return values.sortCode.length !== 6 || values.accountNumber.length !== 8
      ? false
      : true;
  };

  BVDCall = values => {
    this.timer(2000);
    this.setState({ loading: true });

    axios({
      url: process.env.REACT_APP_BDV,
      method: "post",
      data: JSON.stringify({
        sortCode: values.sortCode,
        accountNumber: values.accountNumber
      }),
      headers: headers,

      validateStatus: status => {
        this.timerFinished = true;
        return status === 200;
      }
    })
      // response received
      .then(response => {
        this.setState(() => ({ BDVReceived: true }));
        // are they valid?
        response.data.bankDetailsValid === true
          ? this.BDVValid(response, values)
          : this.BDVInvalid(response, values);
      })
      // No response received
      .catch(() => {
        this.setState({ loading: false, BDVCallError: true });
        this.context.BDV = this.context.BDV + 1;
        this.bdvCounterCheck(values);
      })
      .then(() => {
        this.setState({ timerFinished: false });
        this.loaded();
        this.props.validateForm();
      });
  };

  BDVValid = (response, values) => {
    values.bankDetailsCorrect = "Y";
    values.bankName = response.data.bankName;
    this.context.BDVVerified = true;
  };

  BDVInvalid = (response, values) => {
    this.setState({ BDVCallError: true });
    if (response.data.messageCode === "MSG000116") {
      this.context.BDV = this.context.BDV + 1;
    }
    this.bdvCounterCheck(values);
  };

  render() {
    const { values, content } = this.props;

    return (
      <Section marginBottom={7} aria-live="assertive">
        <div id="bank-details" data-bdd="bank-details">
          <Heading level={2}>{content.bankDetails.sectionTitle}</Heading>
          <Paragraph marginBottom={2}>
            {content.bankDetails.sectionIntro}
          </Paragraph>
          <UnorderedList>
            <ListItem>{content.bankDetails.criteria1}</ListItem>
            <ListItem>{content.bankDetails.criteria2}</ListItem>
            <ListItem>{content.bankDetails.criteria3}</ListItem>
          </UnorderedList>
          <Paragraph>{content.bankDetails.label}</Paragraph>
        </div>

        <Reveal marginTop={2}>
          {this.context.BDV >= 2 &&
            this.context.BDVVerified === false &&
            this.state.loading === false && (
              <Callout type="warning" aria-live="assertive">
                <Paragraph
                  style={{
                    fontWeight: "bold",
                    margin: 0
                  }}
                  className="bdvValid"
                >
                  {bankDetailsContent.accountDetails.errorFailed}
                </Paragraph>
              </Callout>
            )}
        </Reveal>
        <Section marginBottom={0}>
          <PlaybackList marginBottom={5} id="accountHolderName">
            <PlaybackKey>
              {bankDetailsContent.accountHolderName.label}
            </PlaybackKey>
            <PlaybackValue id="account-holder-name" marginBottom={3}>
              {concatenatedName(values)}
            </PlaybackValue>
          </PlaybackList>

          <Field
            name="sortCode"
            data-bdd="sortCode"
            onBlur={(next, rest) => {
              if (values.sortCode !== this.context.verifiedSortCode) {
                this.resetBdvValues(values);
                this.bdvCounterCheck(values);
              }
              next(rest);
            }}
          >
            <Label>{bankDetailsContent.accountDetails.sortCode.label}</Label>
            <NumberInput
              style={{ width: 190 }}
              maxLength={6}
              disabled={
                this.context.BDV >= 2 && this.context.BDVVerified === false
              }
            />
            <FieldFeedback />
          </Field>

          <Field
            debounceWait={10}
            name="accountNumber"
            data-bdd="accountNumber"
            marginBottom={4}
            onBlur={(next, rest) => {
              values.accountNumber = prependZeros(values.accountNumber, 8);
              if (values.accountNumber !== this.context.verifiedAccountNumber) {
                this.resetBdvValues(values);
                this.bdvCounterCheck(values);
              }
              next(rest);
            }}
          >
            <Label>
              {bankDetailsContent.accountDetails.accountNumber.label}
            </Label>
            <NumberInput
              style={{ width: 190 }}
              maxLength={8}
              allowLeadingZeros={true}
              disabled={
                this.context.BDV >= 2 && this.context.BDVVerified === false
              }
            />
            <FieldFeedback />
          </Field>

          {this.context.BDVVerified !== true && this.context.BDV < 2 && (
            <Paragraph
              marginBottom={5}
              style={{ fontWeight: "bold" }}
              data-bdd="descText"
            >
              {bankDetailsContent.descriptiveText}
            </Paragraph>
          )}

          <div
            className="validateButton"
            style={{
              display: "block",
              minHeight: "50px",
              marginLeft: "-20px",
              // hack to make it look semi ok in meantime
              marginBottom: "20px"
            }}
          >
            <FindAddressButton
              path={""}
              content={buttonContent}
              loading={this.state.loading}
              disabled={
                this.context.BDV >= 2 ||
                (this.context.BDVVerified === true &&
                  values.sortCode === this.context.verifiedSortCode &&
                  values.accountNumber === this.context.verifiedAccountNumber)
                  ? true
                  : false
              }
              clickHandler={e => {
                e.stopPropagation();
                this.clickHandler(values, "");
                this.setFocus(values);
              }}
            />
          </div>
          <BankDetailsErrors accountState={this.state} values={values} />
        </Section>
      </Section>
    );
  }
}

Account.contextType = AppConsumer;

export default Account;
