import React from "react";

const IncomeIcon = props => (
  <span style={{ marginBottom: props.marginBottom }}>
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      version="1.1"
      viewBox="0 0 18 18"
      width={props.width}
      height={props.width}
    >    <path d="M10.294 4.833H7.676a.442.442 0 1 1 0-.884h2.618a.442.442 0 0 1 0 .884zM15.16 14.051c.09-1.171-.426-3.437-1.164-4.697-1.054-1.796-2.824-3.048-3.44-3.937H7.445c-.614.893-2.386 2.141-3.44 3.937-.739 1.257-1.255 3.522-1.163 4.697.091 1.175.24 3.083 1.51 3.41 1.437.367 2.883.528 4.51.528 1.626 0 3.214-.161 4.649-.534 1.27-.325 1.558-2.236 1.65-3.404zM11.017 15.2H6.131v-1.224c.34-.062.59-.204.75-.43.158-.224.24-.574.24-1.05v-.347h-.785v-.884h.785v-.51c0-.844.186-1.466.556-1.867.371-.4.89-.6 1.556-.601.325-.004.648.05.954.16.286.103.553.251.79.44l-.57 1.008a2.009 2.009 0 0 0-.51-.295 1.471 1.471 0 0 0-.52-.092c-.328 0-.569.095-.724.285-.16.192-.235.51-.235.96v.51h1.378v.884h-1.38v.54c0 .633-.207 1.062-.622 1.287h3.223v1.226zM10.145.286C9.754.34 9.37.436 9 .578 8.352.653 8 .034 7.393.003c-.825-.042-1.319.363-1.131.78.114.254.723 1.473 1.096 2.591h3.283C11.03 2.22 11.66.957 11.741.783c.124-.283-.774-.587-1.596-.497z" />
  </svg>
  </span>
);

export default IncomeIcon;
