import { maritalStatus } from "../../i18n/lookups/maritalstatus";

export default code => {
  var status;
  for (var i = maritalStatus.length; i--; ) {
    if (maritalStatus[i].value === code) {
      status = maritalStatus[i].label;
      break;
    }
  }
  return status;
};
