import { sanitiseField } from "./utils";
import { address, yesNo, timeAt } from "./transformations";
import {
  previousNames,
  additionalNationalities,
  previousAddresses,
  financialDetails
} from "./helpers";

export const productDecisionRequest = values => {
  return {
    productType: "220",
    sourceCode: values.sourceCode.toUpperCase(),
    etbntbIndicator: yesNo(values.existingCustomer),
    loan: {
      purpose: values["select-purpose"][0],
      value: values.loanAmount,
      term: values.loanDuration,
      deferPayments: yesNo(values.defer)
    },
    personalDetails: {
      name: {
        title: values.title,

        forename: sanitiseField(values.forename),
        middleName: values.middlenames
          ? sanitiseField(values.middlenames)
          : undefined,
        surname: sanitiseField(values.surname)
      },
      previousNameExists: yesNo(values["more-names"]),
      previousNames: previousNames(values.previousNames),
      dateOfBirth: values["date-of-birth"],
      gender: values.gender,
      maritalStatus: values["marital-status"],
      numberOfChildren: values["number-of-dependants"],
      birthCountryCode: values["country-of-birth"],
      residenceCountryCode: values["country-of-residence"],
      nationalityCode: values.nationality,
      additionalNationalities: additionalNationalities(
        values["additional-nationalities"]
      )
    },
    contactDetails: {
      emailAddress: sanitiseField(values.email),
      mobilePhone: sanitiseField(values["mobile-number"].value.replace("+44", "0")),
      homeTelephoneNumber: values["home-number"]
        ? sanitiseField(values["home-number"].value.replace("+44", "0"))
        : undefined,
      workTelephoneNumber: values["work-number"]
        ? sanitiseField(values["work-number"].value.replace("+44", "0"))
        : undefined,
      currentAddress: address(values),
      residentialStatus: values["residential-status"],
      previousAddresses: previousAddresses(
        values.previousAddresses,
        values["date-moved"]
      )
    },
    employmentDetails: {
      employmentStatus: values["employment-status"],
      occupation: values.occupation,
      jobTitle: sanitiseField(values["job-title"]),
      businessRole: values["role-in-business"],
      businessSubcategoryCode: values["business-subcategory"],
      employerName: sanitiseField(values["company-name"]),
      timeWithEmployer: values["employment-start-date"]
        ? timeAt(values["employment-start-date"])
        : undefined,
      employerAddressLine1: sanitiseField(values["address-line-1"]),
      employerAddressLine2: sanitiseField(values["address-line-2"]),
      employerPostCode: sanitiseField(values.postcode),
      hoursWorked: values.hoursWorked,
      atHomeBenefits: values["income-from-benefits"]
        ? yesNo(values["income-from-benefits"])
        : undefined,
      isContractRenewed: values["fixed-term-contract"]
        ? yesNo(values["fixed-term-contract"])
        : undefined
    },
    financialDetails: financialDetails(values),
    preferences: {
      accountHoldersName: sanitiseField(values["account-holders-name"]),
      bankName: values.bankName ? values.bankName : "",
      bankSortCode: sanitiseField(values.sortCode) ? values.sortCode : "",
      bankAccountNumber: sanitiseField(values.accountNumber)
        ? values.accountNumber
        : "",
      bankDetailsCorrect: values.bankDetailsCorrect,
      isNonUkPayments: 'N',
      marketingPreferences: yesNo(values["marketing-preferences"])
    }
  };
};
