export default {
  pageTitle: "Calculate your loan",
  infoBanner: "Customers who do not meet our normal criteria may be offered a different APR to our standard rates. The amount and term you request will also impact the rate offered.",
  loanAmount: {
    label: "How much would you like to borrow?",
    errorBlank: "Tell us how much you would like to borrow",
    errorMin: "Minimum loan amount is £1,000",
    errorMax: "Maximum loan amount is £25,000"
  },
  loanDuration: {
    label: "Over how many months?",
    errorMin: "Minimum loan duration is 12 months"
  },
  defer: {
    label: "Defer your repayments for 3 months?",
    copy:
      "You can start repaying your loan after 1 or 3 months. If you choose to defer repayments for 3 months, interest is charged over the full term of the loan, including the deferred period, for example on a 60 month loan, you will pay 63 months' interest.",
    errorBlank: "Tell us if you would like to defer your repayments for 3 months"
  },
  representativeExample: {
    title: "Representative example",
    key1: "Monthly repayments",
    key2: "Total amount payable",
    key3: "Representative",
    key4: "Annual interest rate (fixed)",
    defer: "With defer",
    noDefer: "Without defer"
  },
  interestRates: {
    link: "Interest rates explained",
    url: "https://bank.marksandspencer.com/personal-loans/rates-table/"
  }
};
