import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Heading,
  FormLayout,
  Paragraph,
  Loading,
  LogoMsGlobal,
  VisuallyHidden
} from "@piggybank/core";
import { Wizard, WizardPage } from "@piggybank/form";
import axios from "axios";

/* eslint-env browser */
import GenericPage from "./pages/GenericPage";
import PersonalDetails from "./templates/PersonalDetails";
import ContactDetails from "./templates/ContactDetails";
import EmploymentDetails from "./templates/EmploymentDetails";
import FinancialDetails from "./templates/FinancialDetails";
import SetUpPreferences from "./templates/SetUpPreferences";
import PurposeOfLoan from "./templates/PurposeOfLoan";
import LoanCalculator from "./templates/LoanCalculator/LoanCalculator";
import Review from "./templates/Review";
import Error from "./templates/Error";
import LoanMasthead from "./organisms/LoanMasthead";
import AcceptMasthead from "./organisms/AcceptMasthead";
import Headers from "./shared/headers";
import { view } from "./shared/analytics/tealium/events";
import { utagGlobal } from "./shared/analytics/tealium";
import { TRACK_PROCESSING_PAGE } from "./shared/analytics/pageData";

import ApplicationTimeout from "./templates/ApplicationTimeout";
import ApplicationComplete from "./templates/ApplicationComplete";
import Accept from "./i18n/Accept";
import Decline from "./templates/Decline";
import { Switch, Route } from "react-router-dom";
import { productDecisionRequest } from "./shared/requestBuilder";
import { afisCall } from "./shared/afisCall";
import { NavHelper } from "./devTools";
import { AppConsumer } from "./shared/appContext";
import uuidv4 from "uuid/v4";

// Create a wrapper for the <App /> passing in context as props

if (process.env.NODE_ENV !== "production") {
  var axe = require("react-axe");
  axe(React, ReactDOM, 1000);
}

const AppContextWrapper = () => (
  <AppConsumer.Consumer>
    {context => <App context={context} />}
  </AppConsumer.Consumer>
);

const sessionId = uuidv4();

export class App extends Component {
  state = {
    loading: false,
    timerFinished: false,
    responded: false,
    devMode: false,
    submitResponse: ""
  };

  componentDidMount() {
    process.env.REACT_APP_DEV_MODE === "true"
      ? this.setState({
          devMode: true
        })
      : this.setState({
          devMode: false
        });
  }

  componentDidUpdate() {
    if (this.state.responded && this.state.timerFinished) {
      this.setState({ loading: !this.state.loading });
      this.setState({ responded: !this.state.responded });
      this.setState({ timerFinished: !this.state.timerFinished });
    }
  }

  requiresLoanMasthead() {
    const pages = [
      "/error",
      "/complete",
      "/timeout",
      "/unsuccessful",
      "/accept"
    ];
    return !pages.includes(window.location.pathname);
  }

  requiresAcceptMasthead() {
    const pages = ["/accept"];
    return pages.includes(window.location.pathname);
  }

  extractAcceptFields = values => {
    const acceptFields = {
      loanAmount:
        values.amount !== undefined ? values.amount.amount : undefined,
      loanApr: values.apr,
      loanTerm: values.term,
      firstRepaymentAmount:
        values.firstRepaymentAmount !== undefined
          ? values.firstRepaymentAmount.amount
          : undefined,
      subsequentRepaymentAmount:
        values.subsequentRepaymentAmount !== undefined
          ? values.subsequentRepaymentAmount.amount
          : undefined,
      totalAmountPayable:
        values.totalAmountPayable !== undefined
          ? values.totalAmountPayable.amount
          : undefined,
      isLoanDeferred: values.isLoanDeferred
    };
    return acceptFields;
  };

  handleSubmit = (values, history) => {
    view(Object.assign(utagGlobal, TRACK_PROCESSING_PAGE));

    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({ timerFinished: true });
    }, 2000);

    this.handleAfis(values, history);
  };

  handleAfis = (values, history) => {
    Headers["x-hsbc-journey-id"] = process.env.REACT_APP_JOURNEY_ID;
    const afisData = JSON.stringify(afisCall(values, sessionId));
    return axios({
      url: process.env.REACT_APP_AFIS,
      method: "post",
      data: afisData,
      headers: Headers
    })
      .then(response => {
        Headers["x-hsbc-state"] = response.headers["x-hsbc-state"];
        this.xState = response.headers["x-hsbc-state"];

        this.handleProductDecision(values, history);
      })
      .catch(error => {
        this.handleProductDecision(values, history);
      });
  };

  handleProductDecision = (values, history) => {
    const data = JSON.stringify(productDecisionRequest(values));
    return axios({
      url: process.env.REACT_APP_PRODUCT_DECISION,
      method: "post",
      data,
      headers: Headers
    })
      .then(response => {
        response.data.conductGScheck
          ? this.handleGlobalCheck(response, data, history)
          : this.handleDecision(response, history);
      })
      .catch(error => {
        this.handleError(error, history);
      });
  };

  handleGlobalCheck = (response, data, history) => {
    Headers["x-hsbc-state"] = response.headers["x-hsbc-state"];

    return axios({
      url: process.env.REACT_APP_GLOBAL_CHECK,
      method: "post",
      data,
      headers: Headers
    })
      .then(() => {
        this.handleDecision(response, history);
      })
      .catch(error => {
        this.handleError(error, history);
      });
  };

  handleError = (error, history) => {
    this.setState({ responded: true });
    this.setState({ submitResponse: error.response.status });
    history.push("/error", { redirection: true, page: "error" });
  };

  handleDecision = (response, history) => {
    this.setState({
      responded: true,
      submitResponse: response.status.toString()
    });
    response.data.eqaNumber
      ? (this.wip = response.data.eqaNumber.toString())
      : (this.wip = response.data.code.toString());

    const decisionArray =
      response.data.decisions && response.data.decisions.decision
        ? response.data.decisions.decision
        : [];

    const decisionCode = decisionArray.length > 0 ? decisionArray[0].code : "";
    const decisionReasonCode =
      decisionArray.length > 0 ? decisionArray[0].reasonCode : "";

    const loanValues =
      response.data.loan &&
      response.data.loan.amount &&
      response.data.loan.firstRepaymentAmount &&
      response.data.loan.subsequentRepaymentAmount &&
      response.data.loan.totalAmountPayable
        ? response.data.loan
        : "";
    let url = "";
    let pageName = "";

    switch (true) {
      case (typeof response.data.exceptionCode !== "undefined" &&
        response.data.exceptionCode.startsWith("X")) ||
        response.data.code.startsWith("ERR"):
        this.referPageType = "exception";
        url = "/complete";
        pageName = "referException";
        break;

      case decisionCode === "A":
        if (loanValues !== "") {
          this.acceptFields = this.extractAcceptFields(loanValues);
        }
        url = "/accept";
        pageName = "accept";
        break;

      case decisionCode === "D":
        url = "/unsuccessful";
        pageName = "decline";
        break;

      case decisionArray.length === 1 &&
        decisionCode === "R" &&
        decisionReasonCode === "09V":
        this.referPageType = "income";
        url = "/complete";
        pageName = "refer";
        break;

      case decisionArray.length === 1 &&
        decisionCode === "R" &&
        decisionReasonCode === "09T":
        this.referPageType = "affordability";
        url = "/complete";
        pageName = "refer";
        break;

      case decisionCode === "R":
        this.referPageType = "general";
        url = "/complete";
        pageName = "refer";
        break;

      default:
        url = "/error";
        pageName = "error";
        break;
    }
    history.push(url, {
      redirection: true,
      page: pageName
    });
  };

  render() {
    const context = this.props.context;
    document.head.querySelector("meta[name='bcsid']").content = sessionId;
    document.head.querySelector("meta[name='session_id']").content = sessionId;
    if (context.sourceCodeValid === false || context.refDataLoaded === false) {
      return (
        <GenericPage requiresMastHead={false}>
          <Route
            render={props => (
              <Error
                sourceCodeValid={context.sourceCodeValid}
                errorCode={this.state.submitResponse}
              />
            )}
          />
        </GenericPage>
      );
    } else {
      return (
        <GenericPage
          context={context}
          requiresMastHead={
            this.requiresLoanMasthead() || this.requiresAcceptMasthead()
          }
        >
          {this.requiresLoanMasthead() && <LoanMasthead context={context} />}
          {this.requiresAcceptMasthead() && <AcceptMasthead />}{" "}
          <Switch>
            <Route exact path="/">
              {({ history }) => (
                <Wizard history={history} onSubmit={this.handleSubmit}>
                  {this.state.devMode && (
                    <NavHelper
                      history={history}
                      handleSubmit={this.handleSubmit}
                    />
                  )}
                  <WizardPage component={PurposeOfLoan} />
                  <WizardPage component={LoanCalculator} />
                  <WizardPage>
                    <PersonalDetails />
                  </WizardPage>
                  <WizardPage>
                    <ContactDetails />
                  </WizardPage>
                  <WizardPage>
                    <EmploymentDetails />
                  </WizardPage>
                  <WizardPage component={FinancialDetails} />
                  <WizardPage>
                    <SetUpPreferences />
                  </WizardPage>
                  <WizardPage component={Review} />
                </Wizard>
              )}
            </Route>

            <Route path="/timeout" component={ApplicationTimeout} />
            <Route
              path="/accept"
              render={props => (
                <Accept
                  acceptFields={this.acceptFields}
                  applicationId={this.wip}
                  context={context}
                />
              )}
            />

            <Route
              path="/complete"
              render={props => (
                <ApplicationComplete
                  applicationId={this.wip}
                  pageType={this.referPageType}
                />
              )}
            />
            <Route
              path="/unsuccessful"
              render={props => <Decline applicationId={this.wip} />}
            />
            <Route
              path="/error"
              render={props => (
                <Error
                  sourceCodeValid={context.sourceCodeValid}
                  errorCode={this.state.submitResponse}
                />
              )}
            />
          </Switch>
          <FormLayout>
            <Loading
              show={this.state.loading}
              getApplicationNode={() => document.getElementById("root")}
              logoSlot={<LogoMsGlobal />}
            >
              <Heading level={3}>Processing your application</Heading>
              <Paragraph marginBottom={0}>
                Please don&#39;t refresh your browser or use the back button
              </Paragraph>
            </Loading>
          </FormLayout>
          <VisuallyHidden aria-hidden="true">{`msrf-version:${process.env.COMMIT_HASH}`}</VisuallyHidden>
        </GenericPage>
      );
    }
  }
}

export default AppContextWrapper;
