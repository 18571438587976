export const loanPurpose = [
  { value: "VEHICLE", label: " for a vehicle purchase" },
  { value: "HOLIDAY", label: " for a holiday" },
  { value: "ANNUAL", label: " for annual commitments" },
  { value: "WEDDING", label: " for a wedding" },
  { value: "HOUSE", label: " for home improvements" },
  { value: "DEBT", label: " for debt consolidation" },
  { value: "FURNITURE", label: " for furniture & electrical" },
  { value: "OTHER", label: "" }
];
