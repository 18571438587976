import yup from "./common-yup.js";
import { DateTime } from "luxon";
import purposeOfLoanContent from "../i18n/content/PurposeOfLoan.js";
import loanCalculatorContent from "../i18n/content/loanCalculator.js";
import personalDetailsContent from "../i18n/content/personalDetails.js";
import contactDetailsContent from "../i18n/content/contactDetails.js";
import employmentDetailsContent from "../i18n/content/employmentDetails.js";
import financialDetailsContent from "../i18n/content/financialDetails.js";
import bankDetailsContent from "../i18n/content/bankDetails.js";
import reviewContent from "../i18n/content/review.js";

const minDate = years => {
  let event = new Date();
  event.setFullYear(event.getFullYear() - years);
  let newDate = new Date(event.toString());
  return newDate;
};

const maxDate = () => {
  let event = new Date();
  let newDate = new Date(event.toString());
  return newDate;
};

const nameRegex = /^([a-zA-Z]{0,1})+([ \\'‘’\\-]*([a-zA-Z]){1})*$/;
const addressRegex = /^[0-9a-zA-Z,./"&amp;()\s\-\\'‘’!]+$/;
const emailRegex = /^[\w-+&amp;'‘’~=#]+(\.[\w-+&amp;'‘’~=#]+)*@[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9](\.[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])*(\.[A-Za-z]{2,4})$|^$/;
const postCodeRegex = /^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}$/;
const textRegex = /^[0-9a-zA-Z, &\-'‘’()./"!\\]+$/;

export const purposeOfLoanSchema = yup.object().shape({
  existingCustomer: yup
    .string()
    .matches(/(yes|no)/)
    .required(purposeOfLoanContent.existingCustomer.errorBlank),

  existingLoan: yup.string().when("existingCustomer", {
    is: "yes",
    then: yup
      .string()
      .matches(/(yes|no)/)
      .required(purposeOfLoanContent.existingLoan.errorBlank)
  }),

  settlingExistingLoan: yup
    .string()
    .when(["existingLoan", "existingCustomer"], {
      is: "yes",
      then: yup
        .string()
        .matches(/(yes|no)/)
        .required(purposeOfLoanContent.settlingExistingLoan.errorBlank)
    }),

  "select-purpose": yup
    .string()
    .required(purposeOfLoanContent.loanPurpose.errorBlank)
});

export const loanCalculatorSchema = yup.object().shape({
  loanAmount: yup
    .number()
    .moreThan(0, loanCalculatorContent.loanAmount.errorBlank)
    .min(1000, loanCalculatorContent.loanAmount.errorMin)
    .max(25000, loanCalculatorContent.loanAmount.errorMax),

  loanDuration: yup
    .number()
    .min(12, loanCalculatorContent.loanDuration.errorMin),

  defer: yup.string().required(loanCalculatorContent.defer.errorBlank)
});
export const personalDetailsSchema = yup.object().shape({
  title: yup
    .string()
    .required(personalDetailsContent.currentName.title.errorBlank),
  forename: yup
    .string()
    .required(personalDetailsContent.currentName.forename.errorBlank)
    .min(2, personalDetailsContent.currentName.forename.errorMin)
    .matches(
      nameRegex,
      personalDetailsContent.currentName.forename.errorInvalid
    ),
  middlenames: yup
    .string()
    .min(2, personalDetailsContent.currentName.middlenames.errorMin)
    .matches(
      nameRegex,
      personalDetailsContent.currentName.middlenames.errorInvalid
    ),
  surname: yup
    .string()
    .required(personalDetailsContent.currentName.surname.errorBlank)
    .min(1, personalDetailsContent.currentName.surname.errorMin)
    .matches(
      nameRegex,
      personalDetailsContent.currentName.surname.errorInvalid
    ),
  "more-names": yup
    .string()
    .matches(/(yes|no)/)
    .required(personalDetailsContent.previousNames.errorRadio),
  previousNames: yup.array().when("more-names", {
    is: "yes",
    then: yup.array().of(
      yup.object().shape({
        previousForename: yup
          .string()
          .required(personalDetailsContent.previousNames.forename.errorBlank)
          .min(2, personalDetailsContent.previousNames.forename.errorMin)
          .matches(nameRegex, {
            message: personalDetailsContent.previousNames.forename.errorInvalid,
            excludeEmptyString: false
          }),
        previousMiddlenames: yup
          .string()
          .matches(/.{2,}/, {
            message: personalDetailsContent.previousNames.middlenames.errorMin,
            excludeEmptyString: true
          })
          .matches(nameRegex, {
            message:
              personalDetailsContent.previousNames.middlenames.errorInvalid,
            excludeEmptyString: true
          }),
        previousSurname: yup
          .string()
          .required(personalDetailsContent.previousNames.surname.errorBlank)
          .min(1, personalDetailsContent.previousNames.surname.errorMin)
          .matches(nameRegex, {
            message: personalDetailsContent.previousNames.surname.errorInvalid,
            excludeEmptyString: false
          })
      })
    )
  }),
  "date-of-birth": yup
    .date()
    .transform(function(value, originalValue) {
      // Check for invalid dates like 31st of September using Luxon
      // (this is not checked by normal JavaScript Date constructor).
      // See https://github.com/jquense/yup#extending-schema-types
      if (this.isType(originalValue)) return value;
      return DateTime.fromISO(originalValue).isValid
        ? new Date(originalValue + "T00:00:00Z")
        : new Date("");
    })
    .typeError(personalDetailsContent.dob.errorInvalid)
    .max(minDate(18), personalDetailsContent.dob.errorMax)
    .min("1900-01-01", personalDetailsContent.dob.errorMin)
    .required(personalDetailsContent.dob.errorBlank),
  gender: yup.string().required(personalDetailsContent.gender.error),
  "marital-status": yup
    .string()
    .required(personalDetailsContent.maritalStatus.error),
  "number-of-dependants": yup
    .number()
    .required(personalDetailsContent.numberOfDependants.error),
  "country-of-birth": yup
    .string()
    .required(personalDetailsContent.countryOfBirth.error),
  "country-of-residence": yup
    .string()
    .required()
    .matches(/(GB|UK)/, personalDetailsContent.countryOfResidence.error),
  nationality: yup.string().required(personalDetailsContent.nationality.error),
  "additional-nationalities": yup
    .array()
    .of(
      yup.string().required(personalDetailsContent.nationality.errorAdditional)
    )
});

export const contactDetailsSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, contactDetailsContent.email.errorInvalid)
    .required(contactDetailsContent.email.errorBlank),
  "mobile-number": yup.object().shape({
    raw: yup.string(),
    value: yup
      .string()
      .matches(/^[+]447\d{9}$/, contactDetailsContent.telephone.errorInvalid)
      .required(contactDetailsContent.telephone.errorBlank)
  }),
  "home-number": yup.object().shape({
    value: yup.string().matches(/^[+]44\d{9,10}$/, {
      message: contactDetailsContent.telephoneHome.errorInvalid,
      excludeEmptyString: true
    })
  }),
  "work-number": yup.object().shape({
    value: yup.string().matches(/^[+]44\d{9,10}$/, {
      message: contactDetailsContent.telephoneWork.errorInvalid,
      excludeEmptyString: true
    })
  }),
  "post-code": yup
    .string()
    .required(contactDetailsContent.postCode.errorBlank)
    .matches(postCodeRegex, contactDetailsContent.postCode.errorInvalid)
    .isNotCiiomPostcode(contactDetailsContent.postCode.errorCiiom),
  "house-number": yup
    .string()
    .isAddressOneOf(contactDetailsContent.houseNumber.errorBlank)
    .matches(addressRegex, contactDetailsContent.houseNumber.errorInvalid),
  "flat-number": yup
    .string()
    .isAddressOneOf(contactDetailsContent.flatNumber.errorBlank)
    .matches(addressRegex, contactDetailsContent.flatNumber.errorInvalid),
  "house-name": yup
    .string()
    .isAddressOneOf(contactDetailsContent.houseName.errorBlank)
    .matches(addressRegex, contactDetailsContent.houseName.errorInvalid),
  address1: yup
    .string()
    .required(contactDetailsContent.address1.errorBlank)
    .matches(addressRegex, contactDetailsContent.address1.errorInvalid),
  address2: yup
    .string()
    .required(contactDetailsContent.address2.errorBlank)
    .matches(addressRegex, contactDetailsContent.address2.errorInvalid),
  address3: yup
    .string()
    .matches(addressRegex, contactDetailsContent.address3.errorInvalid),
  "residential-status": yup
    .string()
    .required(contactDetailsContent.residentialStatus.errorBlank),
  "date-moved": yup
    .date()
    .transform(function(value, originalValue) {
      // Check for invalid dates like 31st of September using Luxon
      // (this is not checked by normal JavaScript Date constructor).
      // See https://github.com/jquense/yup#extending-schema-types
      if (this.isType(originalValue)) return value;
      return DateTime.fromISO(originalValue).isValid ? value : new Date("");
    })
    .typeError(contactDetailsContent.moveDate.errorInvalid)
    .max(new Date(), contactDetailsContent.moveDate.errorMax)
    .min("1900-01-01", contactDetailsContent.moveDate.errorMin)
    .required(contactDetailsContent.moveDate.errorBlank)
    .isBeforeDateOfBirth(contactDetailsContent.moveDate.errorDOB)
    .isAddressSequenceValid(contactDetailsContent.moveDate.errorOrder)
    .areMoreAddressesRequired(contactDetailsContent.moveDate.errorNeedMore),
  previousAddresses: yup.array().of(
    yup.object().shape({
      country: yup
        .string()
        .requiredForAddress2to5(contactDetailsContent.countrySelect.errorBlank),
      "post-code": yup
        .string()
        .requiredForUKAddress(contactDetailsContent.postCode.errorBlank)
        .matches(postCodeRegex, contactDetailsContent.postCode.errorInvalid),
      "house-number": yup
        .string()
        .isAddressOneOf(contactDetailsContent.houseNumber.errorBlank)
        .matches(addressRegex, {
          message: contactDetailsContent.houseNumber.errorInvalid,
          excludeEmptyString: true
        }),
      "flat-number": yup
        .string()
        .isAddressOneOf(contactDetailsContent.flatNumber.errorBlank)
        .matches(addressRegex, {
          message: contactDetailsContent.flatNumber.errorInvalid,
          excludeEmptyString: true
        }),
      "house-name": yup
        .string()
        .isAddressOneOf(contactDetailsContent.houseName.errorBlank)
        .matches(addressRegex, {
          message: contactDetailsContent.houseName.errorInvalid,
          excludeEmptyString: true
        }),
      address1: yup
        .string()
        .requiredAfterCountry(contactDetailsContent.address1.errorBlank)
        .matches(addressRegex, {
          message: contactDetailsContent.address1.errorInvalid,
          excludeEmptyString: true
        }),
      address2: yup
        .string()
        .requiredAfterCountry(contactDetailsContent.address2.errorBlank)
        .matches(addressRegex, {
          message: contactDetailsContent.address2.errorInvalid,
          excludeEmptyString: true
        }),
      address3: yup.string().matches(addressRegex, {
        message: contactDetailsContent.address3.errorInvalid,
        excludeEmptyString: true
      }),
      "date-moved": yup
        .date()
        .transform(function(value, originalValue) {
          // Check for invalid dates like 31st of September using Luxon
          // (this is not checked by normal JavaScript Date constructor).
          // See https://github.com/jquense/yup#extending-schema-types
          if (this.isType(originalValue)) return value;
          return DateTime.fromISO(originalValue).isValid ? value : new Date("");
        })
        .typeError(contactDetailsContent.moveDate.errorInvalid)
        .max(new Date(), contactDetailsContent.moveDate.errorMax)
        .min("1900-01-01", contactDetailsContent.moveDate.errorMin)
        .required(contactDetailsContent.moveDate.errorBlank)
    })
  )
});

export const employmentSchema = yup.object().shape({
  "employment-status": yup
    .string()
    .required(employmentDetailsContent.employmentStatus.error),
  occupation: yup.string().when("employment-status", {
    is: status => ["FULL", "PART", "SELF", "FIXED"].includes(status),
    then: yup.string().required(employmentDetailsContent.occupation.error)
  }),
  "fixed-term-contract": yup.string().when("employment-status", {
    is: "FIXED",
    then: yup
      .string()
      .matches(/(yes|no)/)
      .required(employmentDetailsContent.fixedTermContract.errorBlank)
  }),
  "job-title": yup.string().when("employment-status", {
    is: status => ["FULL", "PART", "SELF", "FIXED"].includes(status),
    then: yup
      .string()
      .required(employmentDetailsContent.jobTitle.errorBlank)
      .matches(textRegex, employmentDetailsContent.jobTitle.errorInvalid)
  }),
  hoursWorked: yup.string().when("employment-status", {
    is: status => ["PART", "SELF"].includes(status),
    then: yup.string().required(employmentDetailsContent.hoursWorked.errorBlank)
  }),
  "company-name": yup.string().when("employment-status", {
    is: status => ["FULL", "PART", "SELF", "FIXED"].includes(status),
    then: yup
      .string()
      .required(employmentDetailsContent.companyName.errorBlank)
      .min(2, employmentDetailsContent.companyName.errorMin)
      .matches(
        /^[0-9a-zA-Z, +&\-'’‘().]+$/,
        employmentDetailsContent.companyName.errorInvalid
      )
  }),
  "employment-start-date": yup.date().when(["employment-status"], {
    is: status => ["FULL", "PART", "SELF", "FIXED"].includes(status),
    then: yup
      .date()
      .transform(function(value, originalValue) {
        // Check for invalid dates like 31st of September using Luxon
        // (this is not checked by normal JavaScript Date constructor).
        // See https://github.com/jquense/yup#extending-schema-types
        if (this.isType(originalValue)) return value;
        return DateTime.fromISO(originalValue).isValid ? value : new Date("");
      })
      .typeError(
        employmentDetailsContent.employmentStartDate.errorInvalidFormat
      )
      .max(maxDate(), employmentDetailsContent.employmentStartDate.errorMax)
      .required(employmentDetailsContent.employmentStartDate.errorBlank)
      .isBeforeDateOfBirth(
        employmentDetailsContent.employmentStartDate.errorMin
      )
  }),
  "role-in-business": yup.string().when("employment-status", {
    is: status => ["FULL", "PART", "SELF", "FIXED"].includes(status),
    then: yup
      .string()
      .required(employmentDetailsContent.roleInBusiness.errorBlank)
  }),
  "business-type": yup
    .string()
    .requiredNotEmployee(employmentDetailsContent.business.type.errorBlank),
  "business-category": yup
    .string()
    .requiredNotEmployee(employmentDetailsContent.business.category.errorBlank),
  "business-subcategory": yup
    .string()
    .requiredNotEmployee(
      employmentDetailsContent.business.subcategory.errorBlank
    ),
  "address-line-1": yup.string().when(["employment-status"], {
    is: status => ["FULL", "PART", "SELF", "FIXED"].includes(status),
    then: yup
      .string()
      .required(employmentDetailsContent.addressLine1.errorBlank)
      .matches(addressRegex, employmentDetailsContent.addressLine1.errorInvalid)
  }),
  "address-line-2": yup.string().when(["employment-status"], {
    is: status => ["FULL", "PART", "SELF", "FIXED"].includes(status),
    then: yup
      .string()
      .required(employmentDetailsContent.addressLine2.errorBlank)
      .matches(addressRegex, employmentDetailsContent.addressLine1.errorInvalid)
  }),
  postcode: yup.string().when(["employment-status"], {
    is: status => ["FULL", "PART", "SELF", "FIXED"].includes(status),
    then: yup
      .string()
      .required(employmentDetailsContent.postcode.errorBlank)
      .matches(postCodeRegex, employmentDetailsContent.postcode.errorInvalid)
  }),
  "income-from-benefits": yup.string().when("employment-status", {
    is: status => ["HOME"].includes(status),
    then: yup
      .string()
      .required(employmentDetailsContent.atHome.errorBlank)
      .matches(/(yes|no)/)
  }),
  "unemployment-benefit": yup.string().when("employment-status", {
    is: status => ["UN"].includes(status),
    then: yup
      .string()
      .required(employmentDetailsContent.unemploymentBenefits.errorBlank)
      .matches(/(yes|no)/)
  }),
  "course-end-date": yup.date().when("employment-status", {
    is: status => ["ST"].includes(status),
    then: yup
      .date()
      .transform(function(value, originalValue) {
        // Check for invalid dates like 31st of September using Luxon
        // (this is not checked by normal JavaScript Date constructor).
        // See https://github.com/jquense/yup#extending-schema-types
        if (this.isType(originalValue)) return value;
        return DateTime.fromISO(originalValue).isValid ? value : new Date("");
      })
      .typeError(employmentDetailsContent.courseEndDate.errorInvalidFormat)
      .min(new Date(), employmentDetailsContent.courseEndDate.errorMin)
      .required(employmentDetailsContent.courseEndDate.errorBlank)
  })
});

export const financialDetailsSchema = yup.object().shape({
  "personal-income": yup
    .number()
    .min(10000, financialDetailsContent.personalIncome.errorMin)
    .required(financialDetailsContent.personalIncome.errorBlank),
  "time-at-bank": yup
    .date()
    .transform(function(value, originalValue) {
      // Check for invalid dates like 31st of September using Luxon
      // (this is not checked by normal JavaScript Date constructor).
      // See https://github.com/jquense/yup#extending-schema-types
      if (this.isType(originalValue)) return value;
      return DateTime.fromISO(originalValue).isValid ? value : new Date("");
    })
    .typeError(financialDetailsContent.timeAtBank.errorInvalid)
    .max(new Date(), financialDetailsContent.timeAtBank.errorMax)
    .required(financialDetailsContent.timeAtBank.errorBlank)
    .isBeforeDateOfBirth(financialDetailsContent.timeAtBank.errorMin),
  affordability: yup
    .string()
    .required(financialDetailsContent.affordability.errorBlank),

  "market-value": yup
    .string()
    .isAnOwner(financialDetailsContent.marketValue.errorBlank),
  "mortgage-left-pay": yup
    .string()
    .isAnOwner(financialDetailsContent.mortgageLeftPay.errorBlank),

  "monthly-outgoings-mortgage": yup
    .string()
    .required(financialDetailsContent.monthlyOutgoingsMortgage.errorBlank),
  "monthly-outgoings-council": yup
    .string()
    .required(financialDetailsContent.monthlyOutgoingsCouncil.errorBlank),
  "monthly-outgoings-insurance": yup
    .string()
    .required(financialDetailsContent.monthlyOutgoingsInsurance.errorBlank),

  "tax-residency-type": yup
    .string()
    .required(financialDetailsContent.taxResidency.errorBlank),
  "tax-residencies": yup.array().when("tax-residency-type", {
    is: value => ["O", "N"].includes(value),
    then: yup
      .array()
      .of(
        yup
          .string()
          .required(financialDetailsContent.taxResidency.errorSelection)
      )
  })
});

export const setUpPreferencesSchema = yup.object().shape({
  sortCode: yup
    .string()
    .required(bankDetailsContent.accountDetails.sortCode.errorBlank)
    .min(6, bankDetailsContent.accountDetails.sortCode.errorInvalid),
  accountNumber: yup
    .string()
    .required(bankDetailsContent.accountDetails.accountNumber.errorBlank)
});

export const reviewSchema = yup.object().shape({
  "email-confirm": yup
    .bool()
    .test(
      "email-confirm",
      reviewContent.emailConfirmation.errorBlank,
      value => value === true
    )
    .required(reviewContent.emailConfirmation.errorBlank)
});
