import { address } from "../transformations";
import { isNotEmptyObject } from "../utils";

export default (addressList, movedToCurrent) => {
  const previousAddresses = [];
  var movedOut;
  for (var i = 0; i < addressList.length; i++) {
    if (isNotEmptyObject(addressList[i])) {
      movedOut = i === 0 ? movedToCurrent : addressList[i - 1]["date-moved"];
      var previous = address(addressList[i], movedOut);
      previous.index = i;
      previousAddresses.push(previous);
    }
  }
  return previousAddresses;
};
