import React, { Component } from "react";
import "babel-polyfill";
import inRange from "lodash/fp/inRange";
import formatTitle from "../../shared/transformations/formatTitle";

import {
  Section,
  Heading,
  Row,
  Cell,
  HorizontalRule,
  Paragraph,
  // Reveal,
  Callout,
  Link,
  Grid,
  eventBus
} from "@piggybank/core";
import {
  Form,
  Navigation,
  RangeInput,
  Fieldset,
  Legend,
  InputPlayback,
  CurrencyInput,
  FormFeedback,
  FieldFeedback
  // YesNoRadio
} from "@piggybank/form";
import content from "../../i18n/content/loanCalculator";
import RepresentativeExample from "../../components/RepresentativeExample";
import { loanCalculatorSchema } from "../../shared/validation-schema";
import "./LoansCalculator.css";
import { link, view } from "../../shared/analytics/tealium/events";
import { TRACK_LOAN_CALCULATOR } from "../../shared/analytics/pageData";
import { utagGlobal, validationErrors } from "../../shared/analytics/tealium";
import { AppConsumer } from "../../shared/appContext";
import Timer from "../../components/Timer";

const initialValues = {
  loanAmount: "1000",
  loanDuration: "12",
  defer: "no"
};

const eventTaggingConstants = {
  page_name: TRACK_LOAN_CALCULATOR.page_name,
  page_url: TRACK_LOAN_CALCULATOR.page_url,
  page_type: utagGlobal.page_type,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_LOAN_CALCULATOR.funnel_step_name
};

class LoanCalculator extends Component {
  loanCalculatorEvents = [];
  loanAmountEvents = [];
  loanDurationEvents = [];
  loanAmountInteractions = [];
  isAndroid = false;

  componentDidMount() {
    const loanPurposeValue = this.props.values["select-purpose"][0];
    TRACK_LOAN_CALCULATOR.form_field_3 =
      "loan purpose: " + loanPurposeValue.toLowerCase();
    view(Object.assign(utagGlobal, TRACK_LOAN_CALCULATOR));
    this.logInteraction();
    document.title = formatTitle(content.pageTitle);
    this.isAndroid = this.isAndroidCheck();
  }

  getRate = ({ loanAmount, loanDuration }) => {
    if (inRange(0, 3000, +loanAmount)) {
      return { apr: 14.9 };
    }

    if (inRange(3000, 5000, +loanAmount)) {
      return { apr: 9.9 };
    }

    if (inRange(5000, 7500, +loanAmount)){
      return { apr: 9.9 };
    };

    if (inRange(7500, 15001, +loanAmount)) {
      return { apr: 6.2 }
    };

    if (inRange(15001, 20001, +loanAmount)) {
      return { apr: 6.2 }
    };

    if(loanAmount >= 20001) {
      return { apr: 6.9 }
    };
  };

  logInteraction = () => {
    eventBus.subscribe(action => {
      if (action.name === "loanAmount") {
        this.loanCalculatorEvents.push(action);
        this.loanAmountEvents.push(action);
      }
      if (action.name === "loanDuration") {
        this.loanCalculatorEvents.push(action);
        this.loanDurationEvents.push(action);
      }

      if (
        (action.name === "loanAmount" || action.name === "loanDuration") &&
        this.loanCalculatorEvents.length === 1
      ) {
        link({
          ...eventTaggingConstants,
          event_category: "tool",
          event_subcategory: "interaction",
          event_action: `${
            this.loanCalculatorEvents[0].component === "RangeInput"
              ? "slider"
              : "textbox"
            }`,
          event_content:
            action.name === "loanAmount" ? "loan amount" : "loan term",
          tool_type: "calculator",
          tool_name: "loan calculator"
        });
      }
    });
  };

  finalCalculatorInteraction = () => {
    let loanCalculatorInteractions = [];

    if (this.loanDurationEvents.length > 0) {
      const loanDurationInteraction = "loan term: slider";
      loanCalculatorInteractions.push(loanDurationInteraction);
    }

    for (let i = 0; i < this.loanAmountEvents.length; i++) {
      if (this.loanAmountEvents[i].component === "RangeInput") {
        if (!this.loanAmountInteractions.includes("slider")) {
          this.loanAmountInteractions.push("slider");
        }
      } else {
        if (!this.loanAmountInteractions.includes("textbox")) {
          this.loanAmountInteractions.push("textbox");
        }
      }
    }

    if (this.loanAmountInteractions.length !== 0) {
      const loanAmountInteraction =
        "loan amount: " + this.loanAmountInteractions.join(" & ");
      loanCalculatorInteractions.push(loanAmountInteraction);
    }

    return loanCalculatorInteractions.join(" & ");
  };

  isAndroidCheck = () => {
    // For details: https://medium.com/@justenphelps/mobile-os-detection-1f14b46565cc
    return !!navigator.platform && /Android|Linux/.test(navigator.platform);
  };

  render() {
    return (
      <div className="LoanCalculatorWrapper">
        <Grid>
          <Row marginBottom={5}>
            <Cell sizeLarge={10} offsetLarge={1} style={{}}>
              <Form
                initialValues={initialValues}
                validationSchema={loanCalculatorSchema}
                debounceWait={0}
                onError={errors => {
                  link({
                    ...eventTaggingConstants,
                    event_category: "error",
                    event_action: "field validation",
                    event_content: validationErrors(errors.errors)
                  });
                }}
                onSubmit={(next, rest) => {
                  rest.values.apr = this.getRate(
                    rest.values,
                    rest.values.loanDuration
                  ).apr;
                  link({
                    ...eventTaggingConstants,
                    event_category: "content",
                    event_action: "radio",
                    event_content: `defer repayments:
                      ${rest.values["defer"]}`
                  });
                  link({
                    ...eventTaggingConstants,
                    event_category: "tool",
                    event_subcategory: "complete",
                    event_action: "slider&textbox",
                    event_content: this.finalCalculatorInteraction(),
                    tool_type: "calculator",
                    tool_name: "loan calculator"
                  });
                  next(rest);
                }}
              >
                {({ values, setFieldValue }) => (
                  <>
                    <FormFeedback />

                    <AppConsumer.Consumer>
                      {val => {
                        val.loanPurpose = values["select-purpose"];
                        val.loanAmount = "";
                        val.loanDuration = values.loanDuration;
                      }}
                    </AppConsumer.Consumer>

                    <Section>
                      <Heading data-bdd="page-title" level={1}>
                        {content.pageTitle}
                      </Heading>
                      <HorizontalRule marginBottom={7} />
                      <Row>
                        <Cell sizeLarge={6} style={{}} id="loanCalculator">
                          <div
                            className="loansCalculator"
                            style={{ height: "100%" }}
                          >
                            <Fieldset
                              data-bdd="loanAmount"
                              marginBottom={6}
                              name="loanAmount"
                              onChange={(next, { value, ...rest }) => {
                                this.logInteraction();
                                next({
                                  value,
                                  ...rest
                                });
                              }}
                              onBlur={(next, { value, ...rest }) => {
                                if (values.loanAmount === "") {
                                  setFieldValue("loanAmount", "1000");
                                }
                                next({
                                  value,
                                  ...rest
                                });
                              }}
                            >
                              <Legend marginBottom={3}>
                                {content.loanAmount.label}
                              </Legend>
                              <InputPlayback
                                marginBottom={3}
                                inputSlot={
                                  <CurrencyInput symbol="£" maxLength={5} />
                                }
                                playbackSlot={`${
                                  this.getRate(values, values.loanDuration).apr
                                  }% APR`}
                              />
                              {this.isAndroid ? (
                                <div
                                  id="loanAmountSrValue"
                                  class="sr-only"
                                  aria-hidden="true"
                                  aria-live="assertive"
                                >
                                  {values.loanAmount}
                                </div>
                              ) : (
                                  ""
                                )}
                              <RangeInput
                                aria-labelledby={
                                  this.isAndroid
                                    ? "loanAmountSrValue"
                                    : undefined
                                }
                                fullWidth
                                min="1000"
                                max="25000"
                                step="100"
                                tickLocations={[
                                  "1000",
                                  "3000",
                                  "5000",
                                  "7500",
                                  "20000",
                                  "25000"
                                ]}
                                trackLabels={{
                                  "0": "£1000",
                                  "25000": "£25,000"
                                }}
                              />
                              <FieldFeedback />
                            </Fieldset>

                            <Fieldset
                              marginBottom={6}
                              name="loanDuration"
                              data-bdd="loanDuration"
                              onChange={(next, { value, ...rest }) => {
                                this.logInteraction();
                                if (value === "42") {
                                  if (values.loanDuration < value) {
                                    value = "48";
                                  } else {
                                    value = "36";
                                  }
                                }

                                if (value === "54") {
                                  if (values.loanDuration < value) {
                                    value = "60";
                                  } else {
                                    value = "48";
                                  }
                                }
                                if (value === "66") {
                                  if (values.loanDuration < value) {
                                    value = "72";
                                  } else {
                                    value = "60";
                                  }
                                }
                                if (value === "78") {
                                  if (values.loanDuration < value) {
                                    value = "84";
                                  } else {
                                    value = "72";
                                  }
                                }
                                next({ value, ...rest });
                              }}
                            >
                              <Legend marginBottom={3}>
                                {" "}
                                {content.loanDuration.label}
                              </Legend>
                              <div className="loanDuration__Playback">
                                <Paragraph marginBottom={0}>
                                  <span
                                    id="loanDurationValue"
                                    aria-live={
                                      this.isAndroid ? "assertive" : undefined
                                    }
                                  >
                                    {values.loanDuration}
                                  </span>{" "}
                                  {"Months"}
                                </Paragraph>
                              </div>
                              <RangeInput
                                aria-labelledby={
                                  this.isAndroid
                                    ? "loanDurationValue"
                                    : undefined
                                }
                                fullWidth
                                name="loanDuration"
                                min="12"
                                max="84"
                                step={"6"}
                                tickLocations={[
                                  "12",
                                  "18",
                                  "24",
                                  "30",
                                  "36",
                                  "48",
                                  "60",
                                  "72",
                                  "84"
                                ]}
                                trackLabels={{
                                  "0": "12",
                                  "84": "84"
                                }}
                              />
                              <FieldFeedback />
                            </Fieldset>
                            {/*
                            <Fieldset
                              name="defer"
                              data-bdd="defer"
                              marginBottom={0}
                            >
                              <Legend
                                marginBottom={2}
                                style={{ width: "100%" }}
                              >
                                {content.defer.label}
                              </Legend>
                              <YesNoRadio
                                marginBottom={values.defer === "yes" ? 2 : 0}
                              />
                              <FieldFeedback marginTop={1} />
                            </Fieldset> */}
                            {/* {values.defer === "yes" && (
                              <Reveal accentBar>
                                <Paragraph
                                  data-bdd="defer-copy"
                                  marginBottom={0}
                                >
                                  {content.defer.copy}
                                </Paragraph>
                              </Reveal>
                            )} */}
                          </div>
                        </Cell>
                        <Cell
                          sizeLarge={4}
                          marginBottom={0}
                          id="representativeExample"
                        >
                          <RepresentativeExample
                            values={values}
                            apr={this.getRate(values, values.loanDuration).apr}
                          />
                        </Cell>
                      </Row>
                      <Row marginBottom={5} id="additionalInfo">
                        <Cell sizeLarge={4} offsetLarge={6} id="link">
                          <Link
                            external
                            strong
                            href={content.interestRates.url}
                            textMap={{ newWindow: "Opens in a new window" }}
                            style={{ float: "right", color: "#4d8210" }}
                          >
                            {content.interestRates.link}
                          </Link>
                        </Cell>
                        <Cell sizeLarge={6}>
                          <div className="loansCalcInfo">
                            <Callout type="information" marginBottom={0}>
                              <Paragraph marginBottom={0} data-bdd="infoBanner">
                                {content.infoBanner}
                              </Paragraph>
                            </Callout>
                          </div>
                        </Cell>
                      </Row>
                    </Section>

                    <HorizontalRule hiddenOnSmallViewport />

                    <Navigation
                      textMap={{
                        previous: "Back",
                        next: "Start Application"
                      }}
                      id="next"
                      onPrevious={(previous, rest) => {
                        link({
                          ...eventTaggingConstants,
                          event_category: "content",
                          event_action: "button",
                          event_content: "back"
                        });
                        previous(rest);
                      }}
                    />
                  </>
                )}
              </Form>
              <Timer pageTags={eventTaggingConstants} />
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default LoanCalculator;
