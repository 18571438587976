import React, { Component } from "react";
import { Paragraph } from "@piggybank/core";
import content from "../../i18n/content/findAddressHint";
import "./FindAddressHint.css";

class FindAddressHint extends Component {
  render() {
    const { addressFound, pafError, initialEntry, loading } = this.props.state;
    const path = this.props.path;
    const pathDot = path ? `${path}.` : "";
    return (
      <div
        id={`${pathDot}findAddressHint`}
        aria-live="assertive"
        className={`findAddressHint ${
          loading || initialEntry ? " findAddressHintHidden" : ""
        }`}
      >
        <Paragraph marginBottom={5}>
          <span
            id={`${pathDot}addressFoundHint`}
            className={`${addressFound ? "" : " findAddressHintHidden"}`}
          >
            {content.addressFound}
          </span>
          <span
            id={`${pathDot}addressNotFoundHint`}
            className={`${
              !addressFound && !pafError ? "" : " findAddressHintHidden"
            }`}
          >
            {content.addressNotfound}
          </span>
          <span
            id={`${pathDot}pafErrorHint`}
            className={`${pafError ? "" : " findAddressHintHidden"}`}
          >
            {content.pafError}
          </span>
        </Paragraph>
      </div>
    );
  }
}

export default FindAddressHint;
