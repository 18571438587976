import { titles } from "../../i18n/lookups/titles";

export default code => {
  var title;
  for (var i = titles.length; i--; ) {
    if (titles[i].value === code) {
      title = titles[i].label;
      break;
    }
  }
  return title;
};
