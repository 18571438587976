import React, { Component } from "react";
import {
  Section,
  Heading,
  HorizontalRule,
  Link,
  IconEdit,
  PlaybackKey,
  PlaybackList,
  PlaybackValue,
  VisuallyHidden
} from "@piggybank/core";
import content from "../../i18n/content/review.js";

import setUpPreferencesContent from "../../i18n/content/setUpPreferences.js";
import bankDetailsContent from "../../i18n/content/bankDetails.js";

import {
  boolean
} from "../../shared/transformations";

class ReviewSetUpPreferences extends Component {
  render() {
    const { values, onClick } = this.props;
    return (
      <Section marginBottom={7} data-bdd="set-up-preferences-review">
        <div className="heading">
          <div className="heading heading--header">
            <Heading
              marginBottom={3}
              level={2}
              data-bdd="set-up-preferences-heading"
            >
              {setUpPreferencesContent.pageTitle}
            </Heading>
          </div>
          <div className="heading heading--editlink">
            <Link
              elementType="button"
              type="button"
              buttonPadding
              data-bdd="set-up-preferences-edit"
              id="set-up-preferences-edit"
              onClick={onClick}
              strong
              iconSlotRight={<IconEdit inline />}
            >
              {content.editButton}
              <VisuallyHidden>
                {setUpPreferencesContent.pageTitle}
              </VisuallyHidden>
            </Link>
          </div>
        </div>
        <HorizontalRule />
        <Heading level={3} marginBottom={3}>
          {content.setUpPreferences.directDebitSection}
        </Heading>
        <PlaybackList id="accountDetailsSection">
          <PlaybackKey>
            {bankDetailsContent.accountHolderName.label}
          </PlaybackKey>
          <PlaybackValue id="account-holders-name" marginBottom={3}>
            {values["account-holders-name"]}
          </PlaybackValue>
          <PlaybackKey>
            {bankDetailsContent.accountDetails.sortCode.label}
          </PlaybackKey>
          <PlaybackValue id="sortCode" marginBottom={3}>
            {values["sortCode"]}
          </PlaybackValue>
          <PlaybackKey>
            {bankDetailsContent.accountDetails.accountNumber.label}
          </PlaybackKey>
          <PlaybackValue id="accountNumber" marginBottom={4}>
            {values["accountNumber"]}
          </PlaybackValue>
        </PlaybackList>

        <Heading level={3} marginBottom={3}>
          {setUpPreferencesContent.marketingPreferences.sectionTitle}
        </Heading>
        <PlaybackList id="marketingPreferencesSection">
          <PlaybackKey>
            {setUpPreferencesContent.marketingPreferences.review}
          </PlaybackKey>
          <PlaybackValue marginBottom={4} id="marketing-preferences">
            {boolean(values["marketing-preferences"])}
          </PlaybackValue>
        </PlaybackList>
      </Section>
    );
  }
}

export default ReviewSetUpPreferences;
