import { occupation } from "../../i18n/lookups/occupation";

export default code => {
  var desc;
  for (var i = occupation.length; i--; ) {
    if (occupation[i].value === code) {
      desc = occupation[i].label;
      break;
    }
  }
  return desc;
};
