import React, { Component } from "react";
import {
  Heading,
  Panel,
  PlaybackList,
  PlaybackKey,
  PlaybackValue,
  Paragraph
} from "@piggybank/core";
import content from "../../i18n/content/loanCalculator.js";
import { displayCurrency } from "../../shared/transformations";

const shift = (number, precision) => {
  const numArray = ("" + number).split("e");
  return +(
    numArray[0] +
    "e" +
    (numArray[1] ? +numArray[1] + precision : precision)
  );
};

const round = (number, precision) => {
  return shift(Math.round(shift(number, +precision)), -precision);
};

const getDeferredAmount = (loanAmount, apr) => {
  let deferredAmount = parseFloat(loanAmount);
  const deferPeriod = 3;

  for (let i = 1; i < deferPeriod; i++) {
    deferredAmount =
      deferredAmount + deferredAmount * (Math.pow(1 + apr / 100, 1 / 12) - 1);
  }
  return parseFloat(deferredAmount.toFixed(2));
};

export const calculateMonthlyRepayments = (values, apr, defer) => {
  let loanAmount;
  if (values.loanAmount === "") {
    loanAmount = "0";
  } else {
    loanAmount = defer
      ? getDeferredAmount(values.loanAmount, apr)
      : parseFloat(values.loanAmount);
  }
  const loanDuration = parseFloat(values.loanDuration);

  const factor1 = Math.pow(1 + apr / 100, 1 / 12) - 1;
  const factor2 = round(1 / Math.pow(1 + factor1, loanDuration), 8);
  const flatRateWithoutInsurance = round(
    (1200 / loanDuration) * ((factor1 * loanDuration) / (1 - factor2) - 1),
    3
  );
  const totalInterest = round(
    ((loanAmount * flatRateWithoutInsurance) / 100) * (loanDuration / 12),
    4
  );

  const monthlyRepayments = parseFloat(
    (loanAmount + totalInterest) / loanDuration
  ).toFixed(2);
  if (defer === true) {
    values.monthlyRepaymentsDefer = monthlyRepayments;
  } else {
    values.monthlyRepayments = monthlyRepayments;
  }
  return monthlyRepayments;
};

export const calculateTotalAmountPayable = (values, apr, defer) => {
  const totalAmountPayable = parseFloat(
    calculateMonthlyRepayments(values, apr, defer) * values.loanDuration
  ).toFixed(2);
  if (defer === true) {
    values.totalAmountPayableDefer = totalAmountPayable;
  } else {
    values.totalAmountPayable = totalAmountPayable;
  }
  return totalAmountPayable;
};

class RepresentativeExample extends Component {
  render() {
    const values = this.props.values;
    const apr = this.props.apr;
    return (
      <>
        <Panel
          data-bdd="representativeExample"
          marginBottom={0}
          style={{ height: "100%" }}
        >
          <Heading level={2} marginBottom={6}>
            {content.representativeExample.title}
          </Heading>
          <PlaybackList>
            <PlaybackKey marginBottom={1}>
              {content.representativeExample.key1}
            </PlaybackKey>
            <div style={{ display: "flex" }}>
              {values.defer === "yes" && (
                <div style={{ width: "50%" }}>
                  <Paragraph small marginBottom={0}>
                    {content.representativeExample.defer}
                  </Paragraph>
                  <PlaybackValue>
                    {displayCurrency(
                      calculateMonthlyRepayments(values, apr, true)
                    )}
                  </PlaybackValue>
                </div>
              )}
              <div style={{ width: "50%" }}>
                {values.defer === "yes" && (
                  <Paragraph small marginBottom={0}>
                    {content.representativeExample.noDefer}
                  </Paragraph>
                )}
                <PlaybackValue
                  marginBottom={7}
                  style={values.defer === "yes" ? { fontWeight: "100" } : {}}
                >
                  {displayCurrency(
                    calculateMonthlyRepayments(values, apr, false)
                  )}
                </PlaybackValue>
              </div>
            </div>
            <PlaybackKey marginBottom={1}>
              {content.representativeExample.key2}
            </PlaybackKey>
            <div style={{ display: "flex" }}>
              {values.defer === "yes" && (
                <div style={{ width: "50%" }}>
                  <Paragraph small marginBottom={0}>
                    {content.representativeExample.defer}
                  </Paragraph>
                  <PlaybackValue>
                    {displayCurrency(
                      calculateTotalAmountPayable(values, apr, true)
                    )}
                  </PlaybackValue>
                </div>
              )}
              <div style={{ width: "50%" }}>
                {values.defer === "yes" && (
                  <Paragraph small marginBottom={0}>
                    {content.representativeExample.noDefer}
                  </Paragraph>
                )}
                <PlaybackValue
                  marginBottom={7}
                  style={values.defer === "yes" ? { fontWeight: "100" } : {}}
                >
                  {displayCurrency(
                    calculateTotalAmountPayable(values, apr, false)
                  )}
                </PlaybackValue>
              </div>
            </div>
            <PlaybackKey marginBottom={1}>
              {content.representativeExample.key3}
            </PlaybackKey>
            <PlaybackValue marginBottom={7}>{apr}% APR</PlaybackValue>
            <PlaybackKey marginBottom={1}>
              {content.representativeExample.key4}
            </PlaybackKey>
            <PlaybackValue>{apr}%</PlaybackValue>
          </PlaybackList>
        </Panel>
      </>
    );
  }
}

export default RepresentativeExample;
