import React, { Component } from "react";
import { Paragraph, IconStatusErrorOnlight, Callout } from "@piggybank/core";
import content from "../../i18n/content/bankDetails";
import { AppConsumer } from "../../shared/appContext";
class BankDetailsErrors extends Component {
  render() {
    const values = this.props.values;
    const accountState = this.props.accountState;
    return (
      <div id="BankDetailsErrors">
        {this.context.BDVVerified !== true &&
          !accountState.loading &&
          this.context.BDV < 2 &&
          this.context.Continue === true && (
            <Paragraph
              aria-live="assertive"
              marginBottom={2}
              style={{
                color: "#d81118"
              }}
              id="BDVNotVerifiedError"
            >
              <span
                style={{
                  color: "#d81118",
                  marginRight: "0.25em",
                  marginTop: "-0.3em"
                }}
              >
                <IconStatusErrorOnlight inline width="1.5em" />
              </span>
              {values.sortCode.length !== 6 || values.accountNumber.length !== 8
                ? content.detailsInvalid
                : content.detailsNotVerified}
            </Paragraph>
          )}

        {!accountState.loading && (
          <>
            {this.context.BDVVerified === true &&
              values.sortCode === this.context.verifiedSortCode &&
              values.accountNumber === this.context.verifiedAccountNumber && (
                <Callout type="success" aria-live="assertive">
                  <Paragraph
                    style={{
                      fontWeight: "bold",
                      margin: 0
                    }}
                    className="bdvValid"
                  >
                    {content.detailsVerified}
                  </Paragraph>
                </Callout>
              )}
            {accountState.BDVCallError && this.context.BDV < 2 && (
              <Paragraph
                aria-live="assertive"
                marginBottom={2}
                style={{
                  fontWeight: "bold"
                }}
                className="bdvValid"
              >
                {content.accountDetails.BDVCallError.errorInvalid}
              </Paragraph>
            )}
          </>
        )}
      </div>
    );
  }
}

BankDetailsErrors.contextType = AppConsumer;

export default BankDetailsErrors;
