import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "babel-polyfill";
import { view, link } from "../../shared/analytics/tealium/events";
import { TRACK_PERSONAL_DETAILS } from "../../shared/analytics/pageData";
import { utagGlobal, validationErrors } from "../../shared/analytics/tealium";

/* eslint-env browser */
import { titles } from "../../i18n/lookups/titles";
import { maritalStatus } from "../../i18n/lookups/maritalstatus";
import gender from "../../i18n/lookups/gender";
import content from "../../i18n/content/personalDetails.js";
import { personalDetailsSchema } from "../../shared/validation-schema.js";
import Nationality from "../../organisms/Nationality";
import { sanitiseField } from "../../shared/utils";
import { AppConsumer } from "../../shared/appContext";
import Timer from "../../components/Timer";
import formatTitle from "../../shared/transformations/formatTitle";

import {
  Section,
  Heading,
  Paragraph,
  Expander,
  Reveal,
  Link,
  HorizontalRule,
  VisuallyHidden,
  Cell,
  Row,
  FormLayout
} from "@piggybank/core";

import {
  Label,
  Legend,
  Select,
  Radio,
  Form,
  Field,
  Fieldset,
  TextInput,
  NumberInput,
  DateInput,
  FieldFeedback,
  FormFeedback,
  Hint,
  YesNoRadio,
  AddAnother,
  Navigation
} from "@piggybank/form";
import StepTracker from "../../components/StepTracker/StepTracker";

const initialValues = {
  mobileNumber: "",
  title: "",
  forename: "",
  middlenames: "",
  surname: "",
  "more-names": "",
  previousNames: [
    { "previous-title": "", previousForename: "", previousSurname: "" }
  ],

  "date-of-birth": "",
  gender: "",
  "marital-status": "",
  "number-of-dependants": "",
  "country-of-birth": "",
  "country-of-residence": "GB",
  nationality: "",
  "additional-nationalities": []
};

// eslint-disable-next-line
// const initialValues = {
//   title: "mr",
//   forename: "Matthew",
//   surname: "Murdock",
//   "more-names": "no",
//   previousNames: [{}],
//   "date-of-birth": "1964-04-01",
//   gender: "M",
//   "marital-status": "S",
//   "number-of-dependants": "0",
//   "country-of-birth": "GB",
//   "country-of-residence": "GB",
//   nationality: "GB",
//   "additional-nationalities": [],
//   sortCode: "",
//   accountNumber: "",
//   bankDetailsCorrect: "N"
// };

const reviewData = values => {
  if (values["more-names"] === "no") {
    values["previousNames"] = [{}];
  }
};

const eventTaggingConstants = {
  page_name: TRACK_PERSONAL_DETAILS.page_name,
  page_url: TRACK_PERSONAL_DETAILS.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_PERSONAL_DETAILS.funnel_step_name
};

class PersonalDetails extends Component {
  componentDidMount = () => {
    TRACK_PERSONAL_DETAILS.start_amount = this.context.loanAmount;
    TRACK_PERSONAL_DETAILS.term = this.context.loanDuration;
    TRACK_PERSONAL_DETAILS.currency_code = "gbp";

    view(Object.assign(utagGlobal, TRACK_PERSONAL_DETAILS));
    document.title = formatTitle(content.pageTitle);
  };

  renderPreviousName = (path, values) => {
    return (
      <>
        <Field
          name={`${path}.previousForename`}
          onBlur={(next, rest) => {
            values.previousNames[path.slice(-1)].previousForename = sanitiseField(
              values.previousNames[path.slice(-1)].previousForename
            );
            next(rest);
          }}
        >
          <Label>{content.previousNames.forename.label}</Label>
          <TextInput fullWidth maxLength={20} />
          <FieldFeedback />
        </Field>

        <Field
          name={`${path}.previousMiddlenames`}
          onBlur={(next, rest) => {
            values.previousNames[
              path.slice(-1)
            ].previousMiddlenames = sanitiseField(
              values.previousNames[path.slice(-1)].previousMiddlenames
            );
            next(rest);
          }}
        >
          <Label>{content.previousNames.middlenames.label}</Label>
          <TextInput fullWidth maxLength={30} required={false} />
          <FieldFeedback />
        </Field>

        <Field
          name={`${path}.previousSurname`}
          onBlur={(next, rest) => {
            values.previousNames[path.slice(-1)].previousSurname = sanitiseField(
              values.previousNames[path.slice(-1)].previousSurname
            );

            next(rest);
          }}
        >
          <Label>{content.previousNames.surname.label}</Label>
          <TextInput fullWidth maxLength={25} />
          <FieldFeedback />
        </Field>
      </>
    );
  };

  render() {
    return (
      <FormLayout>
        <div className="PersonalDetails">
          <Section marginBottom={7}>
            <StepTracker />
            <Heading level={1}>{content.pageTitle}</Heading>
            <Paragraph lead>{content.pageIntro}</Paragraph>
            <Expander title={content.gdpr.title}>
              <Paragraph>
                {content.gdpr.contentPre}
                <Link external href={content.privacy.url}>
                  {content.privacy.link}
                </Link>
                {content.gdpr.contentPost}
              </Paragraph>
              <Paragraph marginBottom={0}>
                {content.gdpr.paragraphTwo}
              </Paragraph>
            </Expander>
          </Section>
          <Form
            initialValues={initialValues}
            validationSchema={personalDetailsSchema}
            onError={errors => {
              link({
                ...eventTaggingConstants,
                event_category: "error",
                event_action: "field validation",
                event_content: validationErrors(errors.errors)
              });
            }}
          >
            {({ values }) => (
              <Fragment>
                <FormFeedback />

                {/* take value from AppConsumer and pass to the form values  */}
                <AppConsumer.Consumer>
                  {val => {
                    val.loanAmount = values.loanAmount;
                    val.monthlyRepayments =
                      values.defer === "yes"
                        ? values.monthlyRepaymentsDefer
                        : values.monthlyRepayments;

                    val.apr = values.apr;
                    val.totalAmountPayable = values.totalAmountPayable;
                  }}
                </AppConsumer.Consumer>

                <Section marginBottom={6}>
                  <Heading level={2} data-bdd="about-you">
                    {content.aboutYouTitle}
                  </Heading>
                  <Field name="title">
                    <Label marginBottom={1}>
                      {content.currentName.title.label}
                    </Label>
                    <Row>
                      <Cell sizeMedium={3} sizeLarge={3}>
                        <Select
                          options={titles}
                          placeholder={content.currentName.title.default}
                        />
                      </Cell>
                    </Row>
                    <FieldFeedback />
                  </Field>

                  <Field
                    name="forename"
                    onBlur={(next, rest) => {
                      values.forename = values.forename
                        .trim()
                        .replace(/\s\s+/g, " ");
                      next(rest);
                    }}
                  >
                    <Label marginBottom={1}>
                      {content.currentName.forename.label}
                    </Label>
                    <Hint>{content.currentName.forename.hint}</Hint>
                    <Row>
                      <Cell sizeMedium={7} sizeLarge={5}>
                        <TextInput fullWidth maxLength={20} />
                      </Cell>
                    </Row>
                    <FieldFeedback />
                  </Field>

                  <Field
                    name="middlenames"
                    onBlur={(next, rest) => {
                      values.middlenames = values.middlenames
                        .trim()
                        .replace(/\s\s+/g, " ");
                      next(rest);
                    }}
                  >
                    <Label marginBottom={1}>
                      {content.currentName.middlenames.label}
                    </Label>
                    <Hint>{content.currentName.middlenames.hint}</Hint>
                    <Row>
                      <Cell sizeMedium={7} sizeLarge={5}>
                        <TextInput fullWidth maxLength={30} required={false} />
                      </Cell>
                    </Row>
                    <FieldFeedback />
                  </Field>

                  <Field
                    name="surname"
                    onBlur={(next, rest) => {
                      values.surname = values.surname
                        .trim()
                        .replace(/\s\s+/g, " ");
                      next(rest);
                    }}
                  >
                    <Label marginBottom={1}>
                      {content.currentName.surname.label}
                    </Label>
                    <Hint>{content.currentName.surname.hint}</Hint>
                    <Row>
                      <Cell sizeMedium={7} sizeLarge={5}>
                        <TextInput fullWidth maxLength={25} />
                      </Cell>
                    </Row>
                    <FieldFeedback />
                  </Field>
                  <Fieldset
                    name="more-names"
                    marginBottom={values["more-names"] === "yes" ? 4 : 0}
                  >
                    <Legend marginBottom={1}>
                      {content.previousNames.labelRadio}
                    </Legend>
                    <YesNoRadio />
                    <FieldFeedback marginTop={1} />
                  </Fieldset>
                  <Reveal marginBottom={4}>
                    {values["more-names"] === "yes" && (
                      <Fragment>
                        <Paragraph>{content.previousNames.introCopy}</Paragraph>
                        <AddAnother
                          fullWidth
                          marginBottom={3}
                          name="previousNames"
                          itemInitialValue={{}}
                          min={1}
                          max={5}
                          textMap={{
                            addButton: (
                              <>
                                Add another
                                <VisuallyHidden> previous name</VisuallyHidden>
                              </>
                            ),
                            removeButton: (
                              <>
                                Remove
                                <VisuallyHidden> previous name</VisuallyHidden>
                              </>
                            )
                          }}
                        >
                          {({ path }) => {
                            return this.renderPreviousName(path, values);
                          }}
                        </AddAnother>
                      </Fragment>
                    )}
                  </Reveal>

                  <Fieldset name="date-of-birth">
                    <Legend marginBottom={1}>{content.dob.label}</Legend>
                    <Hint marginBottom={1}>{content.dob.hint}</Hint>
                    <DateInput />
                    <FieldFeedback />
                  </Fieldset>

                  <Fieldset name="gender" marginBottom={4}>
                    <Legend marginBottom={1} id="gender-label">
                      {content.gender.label}
                    </Legend>
                    {gender.map(g => (
                      <Radio value={g.value} key={g.value}>
                        {g.label}
                      </Radio>
                    ))}
                    <FieldFeedback marginTop={1} />
                  </Fieldset>

                  <Field name="marital-status" data-bdd="marital-status">
                    <Label marginBottom={1}>
                      {content.maritalStatus.label}
                    </Label>
                    <Row>
                      <Cell sizeMedium={7} sizeLarge={5}>
                        <Select
                          fullWidth
                          options={maritalStatus}
                          placeholder={content.maritalStatus.placeholder}
                        />
                      </Cell>
                    </Row>
                    <FieldFeedback name="marital-status-feedback" />
                  </Field>

                  <Field
                    name="number-of-dependants"
                    data-bdd="number-of-dependants"
                    onBlur={(next, rest) => {
                      values["number-of-dependants"] = values[
                        "number-of-dependants"
                      ].trim();
                      next(rest);
                    }}
                  >
                    <Label>{content.numberOfDependants.label}</Label>
                    <Hint>{content.numberOfDependants.hint}</Hint>
                    <Row>
                      <Cell size={12}>
                        <NumberInput maxLength={1} style={{ width: "66px" }} />
                      </Cell>
                    </Row>
                    <FieldFeedback />
                  </Field>
                </Section>

                <Nationality values={values} />
                <Section>
                  <HorizontalRule />

                  <Navigation
                    textMap={{ previous: "Back", next: "Continue" }}
                    id="next"
                    onNext={(next, rest) => {
                      reviewData(values);
                      next(rest);
                    }}
                  />
                </Section>
              </Fragment>
            )}
          </Form>
          <Timer pageTags={eventTaggingConstants} />
        </div>
      </FormLayout>
    );
  }
}
PersonalDetails.propTypes = {
  values: PropTypes.object,
  navigateTo: PropTypes.func
};
PersonalDetails.displayName = "PersonalDetails";
PersonalDetails.contextType = AppConsumer;

export default PersonalDetails;
