import React, { Component } from "react";
import {
  Section,
  Heading,
  Paragraph,
  Button,
  IconStatusInformationOnlight,
  FormLayout
} from "@piggybank/core";
import content from "../../i18n/content/decline.js";
import { TRACK_DECLINE_PAGE } from "../../shared/analytics/pageData.js";
import { view } from "../../shared/analytics/tealium/events";
import { utagGlobal } from "../../shared/analytics/tealium";
import formatTitle from "../../shared/transformations/formatTitle";

class Decline extends Component {
  componentDidMount() {
    TRACK_DECLINE_PAGE.application_id = this.props.applicationId;
    view(Object.assign(utagGlobal, TRACK_DECLINE_PAGE));
    document.title = formatTitle(content.pageTitle);
  }

  handleRedirect() {
    window.location = "https://bank.marksandspencer.com/";
  }

  render() {
    return (
      <FormLayout>
        <Section>
          <div style={{ margin: "3px 0px 3px -2px", padding: "3px" }}>
            <IconStatusInformationOnlight width="36" data-bdd="decline-icon" />
          </div>
          <Heading level={1} data-bdd="page-heading">
            {content.pageHeading}
          </Heading>
          <Paragraph data-bdd="page-copy" marginBottom={6}>
            {content.pageCopy}
          </Paragraph>
          <Button
            data-bdd="home-page-button"
            type="button"
            onClick={e => {
              e.stopPropagation();
              this.handleRedirect();
            }}
          >
            {content.buttonLabel}
          </Button>
        </Section>
      </FormLayout>
    );
  }
}

export default Decline;
