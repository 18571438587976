import React, { Component, Fragment } from "react";
import "babel-polyfill";
import content from "../../i18n/content/PurposeOfLoan";
import { view, link } from "../../shared/analytics/tealium/events";
import { utagGlobal, validationErrors } from "../../shared/analytics/tealium";

import Timer from "../../components/Timer";

import {
  Row,
  Cell,
  Section,
  Heading,
  Paragraph,
  Reveal,
  HorizontalRule,
  Callout,
  IconCalendar,
  IconVehicle,
  IconHome,
  IconCard,
  IconTravel,
  FormLayout,
  Link
} from "@piggybank/core";
import WeddingIcon from "../../atoms/icons/wedding";
import FurnitureIcon from "../../atoms/icons/furniture";
import IncomeIcon from "../../atoms/icons/income";

import {
  Legend,
  Form,
  Fieldset,
  FieldFeedback,
  FormFeedback,
  Hint,
  YesNoRadio,
  Navigation,
  TileGroup,
  IconTile
} from "@piggybank/form";

import { purposeOfLoanSchema } from "../../shared/validation-schema";
import { TRACK_PURPOSE_OF_LOAN } from "../../shared/analytics/pageData";
import { AppConsumer } from "../../shared/appContext";
import { removeFields } from "../../shared/utils";
import formatTitle from "../../shared/transformations/formatTitle";

const initialValues = {
  existingCustomer: "",
  existingLoan: "",
  settlingExistingLoan: "",
  "select-purpose": []
};

const eventTaggingConstants = {
  page_name: TRACK_PURPOSE_OF_LOAN.page_name,
  page_url: TRACK_PURPOSE_OF_LOAN.page_url,
  page_type: utagGlobal.page_type,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_PURPOSE_OF_LOAN.funnel_step_name
};

const tealiumLoaded = utagGlobal => {
  if (typeof window.utag !== "undefined") {
    view(Object.assign(utagGlobal, TRACK_PURPOSE_OF_LOAN));
  } else {
    setTimeout(function() {
      tealiumLoaded(utagGlobal);
    }, 1000);
  }
};

const reviewData = values => {
  if (values.existingCustomer === "no") {
    removeFields(values, ["existingLoan", "settlingExistingLoan"]);
  }
};

class PurposeOfLoan extends Component {
  componentDidMount = () => {
    tealiumLoaded(utagGlobal);
    document.title = formatTitle(content.browserTitle);
  };

  allowUserContinue = values => {
    return values.existingLoan === "yes" &&
      values.existingCustomer === "yes" &&
      values.settlingExistingLoan === "yes"
      ? false
      : true;
  };

  formatLoanPurpose = loanPurposeValue => {
    return loanPurposeValue.replace(/-/g, " ");
  };

  render() {
    return (
      <FormLayout>
        <div className="purposeOfLoans">
          <Section marginBottom={7}>
            <Heading level={1}>{content.pageTitle}</Heading>
            <Paragraph lead>{content.subTitle}</Paragraph>
            <HorizontalRule />
          </Section>
          <Form
            initialValues={initialValues}
            validationSchema={purposeOfLoanSchema}
            onError={errors => {
              link({
                ...eventTaggingConstants,
                event_category: "error",
                event_action: "field validation",
                event_content: validationErrors(errors.errors)
              });
            }}
          >
            {({ values }) => (
              <Fragment>
                <FormFeedback />

                {/* take value from AppConsumer and pass to the form values  */}
                {/* set loanPurpose = "" so masthead is never displayed  */}
                <AppConsumer.Consumer>
                  {val => {
                    values.sourceCode = val.sourceCode;
                    val.loanPurpose = "";
                  }}
                </AppConsumer.Consumer>

                <Section data-bdd="existingCustomer">
                  <Heading level={2}>{content.existingCustomer.title}</Heading>

                  <Fieldset
                    name="existingCustomer"
                    marginBottom={values.existingCustomer === "yes" ? 4 : 0}
                    data-bdd="existingCustomer"
                  >
                    <Legend marginBottom={1}>
                      {content.existingCustomer.label}
                    </Legend>
                    <Hint>{content.existingCustomer.hint}</Hint>
                    <YesNoRadio data-bdd="existingCustomerRadio" />
                    <FieldFeedback marginTop={1} />
                  </Fieldset>

                  <Reveal data-bdd="existingLoanReveal">
                    {values.existingCustomer === "yes" && (
                      <>
                        <Fieldset
                          name="existingLoan"
                          marginBottom={values.existingLoan === "yes" ? 4 : 0}
                          data-bdd="existingLoan"
                        >
                          <Legend marginBottom={1}>
                            {content.existingLoan.label}
                          </Legend>
                          <YesNoRadio />
                          <FieldFeedback marginTop={1} />
                        </Fieldset>

                        <Reveal
                          marginBottom={
                            values.settlingExistingLoan === "yes" ? 6 : 0
                          }
                          data-bdd="settlingExistingLoanReveal"
                        >
                          {values.existingLoan === "yes" && (
                            <Fieldset
                              name="settlingExistingLoan"
                              data-bdd="settlingExistingLoan"
                              marginBottom={0}
                            >
                              <Legend marginBottom={1}>
                                {content.settlingExistingLoan.label}
                              </Legend>
                              <YesNoRadio />
                              <FieldFeedback marginTop={1} />
                            </Fieldset>
                          )}
                        </Reveal>
                      </>
                    )}
                  </Reveal>
                  <Reveal>
                    {this.allowUserContinue(values) === false && (
                      <Callout
                        marginBottom={0}
                        type="warning"
                        data-bdd="purposeOfLoansCallout"
                      >
                        {content.contactUs.copy}
                        <Link
                          href={content.contactUs.pageLinkUrl}
                          data-bdd="contact-us-link"
                        >
                          {content.contactUs.pageLink}
                        </Link>
                      </Callout>
                    )}
                  </Reveal>
                </Section>

                <Reveal>
                  {this.allowUserContinue(values) === true && (
                    <Section marginBottom={6} data-bdd="purposeForLoan">
                      <Heading level={2} marginBottom={3}>
                        {content.loanPurpose.title}
                      </Heading>
                      <Fieldset name="select-purpose" data-bdd="loanPurpose">
                        <Legend marginBottom={4}>
                          {content.loanPurpose.subTitle}
                        </Legend>
                        <Row>
                          <Cell sizeLarge={4}>
                            <TileGroup>
                              <IconTile
                                fullWidth
                                value="VEHICLE"
                                icon={IconVehicle}
                              >
                                {content.loanPurpose.purpose1}
                                <Hint> {content.loanPurpose.purpose1Hint}</Hint>
                              </IconTile>
                              <IconTile
                                fullWidth
                                value="HOLIDAY"
                                icon={IconTravel}
                              >
                                {content.loanPurpose.purpose2}
                              </IconTile>
                              <IconTile
                                fullWidth
                                value="ANNUAL"
                                icon={IconCalendar}
                              >
                                {content.loanPurpose.purpose3}
                                <Hint>{content.loanPurpose.purpose3Hint}</Hint>
                              </IconTile>
                              <IconTile
                                fullWidth
                                value="WEDDING"
                                icon={WeddingIcon}
                              >
                                {content.loanPurpose.purpose4}
                              </IconTile>
                              <IconTile fullWidth value="HOUSE" icon={IconHome}>
                                {content.loanPurpose.purpose5}
                              </IconTile>
                              <IconTile fullWidth value="DEBT" icon={IconCard}>
                                {content.loanPurpose.purpose6}
                                <Hint> {content.loanPurpose.purpose6Hint}</Hint>
                              </IconTile>
                              <IconTile
                                fullWidth
                                value="FURNITURE"
                                icon={FurnitureIcon}
                              >
                                {content.loanPurpose.purpose7}
                              </IconTile>
                              <IconTile
                                fullWidth
                                value="OTHER"
                                icon={IncomeIcon}
                              >
                                {content.loanPurpose.purpose8}
                              </IconTile>
                            </TileGroup>
                          </Cell>
                        </Row>
                        <FieldFeedback />
                      </Fieldset>
                    </Section>
                  )}
                </Reveal>

                <Section>
                  <HorizontalRule />

                  <Reveal marginBottom={4}>
                    {this.allowUserContinue(values) === true && (
                      <Navigation
                        textMap={{ next: "Continue" }}
                        id="next"
                        onNext={(next, rest) => {
                          reviewData(values);
                          next(rest);
                        }}
                      />
                    )}
                  </Reveal>
                </Section>
              </Fragment>
            )}
          </Form>
          <Timer pageTags={eventTaggingConstants} />
        </div>
      </FormLayout>
    );
  }
}

export default PurposeOfLoan;
