import React, { Component, Fragment } from "react";
import {
  Section,
  Heading,
  HorizontalRule,
  Link,
  IconEdit,
  PlaybackKey,
  PlaybackList,
  PlaybackValue,
  VisuallyHidden
} from "@piggybank/core";
import content from "../../i18n/content/review.js";
import contactDetailsContent from "../../i18n/content/contactDetails.js";
import { reviewAddress } from "../../shared/helpers";
import {
  residentialStatus,
  monthYearDateTransform
} from "../../shared/transformations";

class ReviewContactDetails extends Component {
  render() {
    const { values, onClick } = this.props;
    return (
      <Section marginBottom={7} data-bdd="contact-details-review">
        <div className="heading">
          <div className="heading heading--header">
            <Heading
              marginBottom={3}
              level={2}
              data-bdd="contact-details-heading"
            >
              {content.contactDetails.heading}
            </Heading>
          </div>
          <div className="heading heading--editlink">
            <Link
              elementType="button"
              type="button"
              buttonPadding
              data-bdd="contact-details-edit"
              id="contact-details-edit"
              onClick={onClick}
              strong
              iconSlotRight={<IconEdit inline />}
            >
              {content.editButton}
              <VisuallyHidden>{content.contactDetails.heading}</VisuallyHidden>
            </Link>
          </div>
        </div>
        <HorizontalRule />
        <Heading level={3} marginBottom={3}>
          {content.contactDetails.phoneNumbers}
        </Heading>
        <PlaybackList marginBottom={4} id="reviewPhoneNumbers">
          {values["mobile-number"] && (
            <>
              <PlaybackKey>{contactDetailsContent.telephone.label}</PlaybackKey>
              <PlaybackValue marginBottom={3} id="mobile-number">
                {values["mobile-number"].raw}
              </PlaybackValue>
            </>
          )}
          {values["home-number"] && values["home-number"].raw !== "" && (
            <>
              <PlaybackKey>
                {contactDetailsContent.telephoneHome.review}
              </PlaybackKey>
              <PlaybackValue marginBottom={3} id="home-number">
                {values["home-number"].raw}
              </PlaybackValue>
            </>
          )}
          {values["work-number"] && values["work-number"].raw !== "" && (
            <>
              <PlaybackKey>
                {contactDetailsContent.telephoneWork.review}
              </PlaybackKey>
              <PlaybackValue marginBottom={3} id="work-number">
                {values["work-number"].raw}
              </PlaybackValue>
            </>
          )}
        </PlaybackList>
        <Heading level={3} marginBottom={3}>
          {content.contactDetails.currentAddress}
        </Heading>
        <PlaybackList marginBottom={4} id="reviewCurrentAddress">
          <PlaybackKey>{content.contactDetails.addressLabel}</PlaybackKey>
          <PlaybackValue id="current-address" marginBottom={3}>
            {reviewAddress(values)}
          </PlaybackValue>
          <PlaybackKey>
            {contactDetailsContent.residentialStatus.label}
          </PlaybackKey>
          <PlaybackValue id="residential-status" marginBottom={3}>
            {residentialStatus(values["residential-status"])}
          </PlaybackValue>
          <PlaybackKey>{contactDetailsContent.moveDate.label}</PlaybackKey>
          <PlaybackValue id="date-moved" marginBottom={3}>
            {monthYearDateTransform(values["date-moved"])}
          </PlaybackValue>
        </PlaybackList>
        {values.previousAddresses && values.previousAddresses.length > 0 && (
          <>
            <Heading level={3} marginBottom={3}>
              {values.previousAddresses.length > 1
                ? content.contactDetails.previousAddresses
                : content.contactDetails.previousAddress}
            </Heading>
            <PlaybackList marginBottom={7} id="reviewPreviousAddresses">
              {values.previousAddresses.map(function(address, i) {
                return (
                  <Fragment key={i}>
                    <PlaybackKey>
                      {content.contactDetails.addressLabel}
                    </PlaybackKey>
                    <PlaybackValue
                      id={`previous-address-${i}`}
                      marginBottom={3}
                    >
                      {reviewAddress(
                        address,
                        address.country ? address.country : "GB"
                      )}
                    </PlaybackValue>
                    <PlaybackKey>
                      {contactDetailsContent.moveDate.label}
                    </PlaybackKey>
                    <PlaybackValue id={`date-moved-${i}`} marginBottom={3}>
                      {monthYearDateTransform(address["date-moved"])}
                    </PlaybackValue>
                  </Fragment>
                );
              })}
            </PlaybackList>
          </>
        )}
      </Section>
    );
  }
}

export default ReviewContactDetails;
