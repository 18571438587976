export default {
  pageTitle: "Personal details",
  pageIntro:
    "Please tell us a bit about yourself. All questions in the application are mandatory, unless indicated.",
  gdpr: {
    title: "What will we do with these details?",
    contentPre:
      "Once you've applied for this product, your information will be used in line with our ",
    contentPost: ".",
    paragraphTwo:
      "We use automated systems to help us detect and prevent fraud and to meet our legal obligations. We will decline your application if fraud is detected."
  },
  privacy: {
    link: "Privacy Notice",
    url: "https://bank.marksandspencer.com/pdf/MSBPrivacyNotice.pdf"
  },
  aboutYouTitle: "About you",
  currentName: {
    title: {
      label: "Title",
      default: "Please select",
      errorBlank: "Select your title"
    },
    forename: {
      label: "First name",
      hint: "As it appears on your identity documents.",
      errorBlank: "Enter your first name",
      errorMin: "Your first name must have at least 2 characters",
      errorInvalid:
        "Enter your first name using only the characters a-z, - and '"
    },
    middlenames: {
      label: "Middle name(s) (optional)",
      errorMin: "Your middle name must have at least 2 characters",
      hint: "As it appears on your identity documents.",
      errorInvalid:
        "Enter your middle name(s) using only the characters a-z, - and '"
    },
    surname: {
      label: "Surname or family name",
      hint: "As it appears on your identity documents.",
      errorBlank: "Enter your surname or family name",
      errorMin: "Your surname must have at least 1 character",
      errorInvalid:
        "Enter your surname or family name using only the characters a-z, - and '"
    }
  },
  previousNames: {
    labelRadio:
      "Have you had any previous names or are you known by any other names?",
    introCopy:
      "Provide full details of your previous names as they appeared on your identity documents. This should include your first name and surname at that time.",
    forename: {
      label: "Previous first name",
      errorBlank: "Enter your previous first name",
      errorMin: "Your previous first name must have at least 2 characters",

      errorInvalid:
        "Enter your previous first name using only the characters a-z, - and '"
    },
    middlenames: {
      label: "Previous middle name(s) (optional)",
      errorMin: "Your previous middle name must have at least 2 characters",
      errorInvalid:
        "Enter your previous middle name(s) using only the characters a-z, - and '"
    },
    surname: {
      label: "Previous surname or family name",
      errorBlank: "Enter your previous surname or family name",
      errorMin: "Your previous surname must have at least 1 character",
      errorInvalid:
        "Enter your previous surname / family name using only the characters a-z, - and '"
    },
    errorRadio:
      "Tell us if you have ever had any previous names or are you known by any other names?"
  },
  dob: {
    label: "Date of birth",
    hint: "For example 19 07 1998.",
    errorBlank: "Check your date of birth is correct",
    errorMax: "If you're under 18 we can not offer you this product",
    errorMin: "Check your date of birth is correct",
    errorInvalid: "Enter your date of birth in the format DD MM YYYY"
  },
  gender: {
    label: "Gender",
    error: "Select your gender"
  },
  maritalStatus: {
    label: "Marital status",
    placeholder: "Please select",
    error: "Select your marital status"
  },
  numberOfDependants: {
    label: "Number of dependent children",
    hint:
      "This is the number of children living in your household who are financially dependent on you.",
    error: "Number of dependents must be a number between 0 and 9"
  },
  nationalitySection: "Nationality and residency",
  countryOfBirth: {
    label: "Place of birth",
    placeholder: "Please select",
    error: "Tell us what country you were born in"
  },
  countryOfResidence: {
    label: "Country or region of permanent residence",
    error: "You must live in the UK to apply for this loan"
  },
  nationality: {
    label: "Nationality (country or region)",
    hint:
      "Please select your nationality and any countries you are a citizen of.",
    placeholder: "Please select",
    error: "Select your nationality or citizenship",
    errorAdditional: "Select your nationality or citizenship",
    labelAdditional: "Country"
  }
};
