import React from "react";
import {
  Grid,
  Row,
  Cell,
  Heading,
  IconConfirm,
  Section
} from "@piggybank/core";
import "./AcceptMasthead.css";
import content from "../../i18n/content/accept.js";

const AcceptMasthead = props => {
  return (
    <div
      id="acceptMasthead"
      className="acceptMasthead"
      style={{ display: "block" }}
    >
      <Grid>
        <Row>
          <Section marginBottom={0}>
            <div className="acceptMasthead__inner">
              <Cell offsetLarge={1} size={10} sizeMedium={9} sizeLarge={6}>
                <div className="acceptMasthead__description">
                  <div
                    style={{
                      color: "#4d8210",
                      margin: "3px 0px 3px -2px",
                      padding: "3px"
                    }}
                  >
                    <IconConfirm width="36" data-bdd="confirm-icon" />
                  </div>
                  <Heading level={1} data-bdd="page-heading" marginBottom={0}>
                    {content.pageHeading}
                  </Heading>
                </div>
              </Cell>
            </div>
          </Section>
        </Row>
      </Grid>
    </div>
  );
};

export default AcceptMasthead;
