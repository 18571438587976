export default {
  browserTitle: "Purpose for loan",
  pageTitle: "Before we start your application",
  subTitle:
    "Let us know if you already hold a Loan with M&S and the purpose for this new borrowing. All questions are mandatory, unless indicated.",

  existingCustomer: {
    title: "Existing customer",
    label: "Are you an existing M&S Bank customer?",
    hint:
      "Existing M&S Bank customers are those with an existing M&S loan or card (where you're the main cardholder).",
    errorBlank: "Tell us if you are an existing M&S Bank customer"
  },
  existingLoan: {
    label: "Do you already have a Personal Loan with us?",
    errorBlank: "Tell us if you already have a Personal Loan with us"
  },
  settlingExistingLoan: {
    label:
      "If so, would you like to repay your existing M&S loan with this new loan?",
    errorBlank:
      "Tell us if you would like to repay your existing loan with this new loan"
  },
  loanPurpose: {
    title: "Select the purpose for your loan",
    subTitle:
      "This information allows us to understand your borrowing needs better.",
    errorBlank: "Select the purpose for your loan",
    purpose1: "Vehicle purchase",
    purpose1Hint: "Car, boat, caravan.",
    purpose2: "Holiday",
    purpose3: "Annual commitments",
    purpose3Hint: "School fees, insurance.",
    purpose4: "Wedding",
    purpose5: "Home improvements",
    purpose6: "Debt consolidation",
    purpose6Hint: "Non-M&S debt.",
    purpose7: "Furniture & Electrical",
    purpose8: "Other"
  },
  contactUs: {
    pageLinkUrl: "https://bank.marksandspencer.com/explore/contact-us/",
    pageLink: " contact us.",
    copy: " To apply please "
  }
};
