import React from "react";

const WeddingIcon = props => (
  <span style={{ marginBottom: props.marginBottom }}>
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      version="1.1"
      viewBox="0 0 18 18"
      width={props.width}
      height={props.width}
    >
      <path d="M8.057.348L5.067.002a.368.368 0 0 0-.398.275C4.35 1.524 3.193 6.165 3.096 7.831c-.056.94.478 1.81 1.026 2.183.234.162.456.34.664.535a.565.565 0 0 1 .159.513l-.5 4.407-1.249-.144a.325.325 0 1 0-.075.645s3.176.362 3.186.36a.324.324 0 0 0 .303-.323.335.335 0 0 0-.302-.323l-1.157-.13.515-4.446a.551.551 0 0 1 .26-.424c.247-.142.504-.265.769-.37.622-.239 1.336-.963 1.497-1.891.285-1.645.215-6.427.19-7.713a.368.368 0 0 0-.325-.362zm-.38 3.1H4.614L5.265.69l2.526.306-.115 2.451zM15.321 17.067a.324.324 0 0 0-.376-.263l-1.17.208-.717-4.03c-.095-.643-.063-.767.06-.927.197-.207.408-.399.633-.576.526-.405 1-1.306.889-2.242-.193-1.604-1.638-6.206-2.036-7.44a.37.37 0 0 0-.417-.25l-2.96.53a.369.369 0 0 0-.304.378c.055 1.285.282 6.062.667 7.686.217.917.975 1.598 1.61 1.796.272.086.536.193.792.318.167.09.278.257.296.445 0 .006.81 4.431.81 4.431l-1.233.22a.335.335 0 0 0-.281.34c.009.172.151.307.324.307.018 0 3.151-.557 3.151-.557a.325.325 0 0 0 .262-.374zM9.781 4.716l-.154-2 2.411-.46.766 2.46H9.78z" />
    </svg>
  </span>
);

export default WeddingIcon;
