import React, { Component } from "react";
import BusinessType from "../BusinessType";
import { Section, Heading, Row, Cell, Reveal } from "@piggybank/core";
import {
  Field,
  Label,
  Select,
  FieldFeedback,
  DateInput,
  Legend,
  Fieldset,
  TextInput,
  Hint,
  Radio
} from "@piggybank/form";
import "./Employed.css";
import { occupation } from "../../i18n/lookups/occupation";
import { businessRole } from "../../i18n/lookups/businessRole";

const notEmployee = role =>
  ["BUSINESS_OWNER", "SOLE_TRADER", "KEY_CONTROLLER"].includes(role);

class Employed extends Component {
  render() {
    const { values, content } = this.props;

    return (
      <>
        <Field name="occupation" data-bdd="occupation">
          <Label>{content.occupation.label}</Label>
          <Row>
            <Cell sizeMedium={7} sizeLarge={5}>
              <Select
                options={occupation}
                placeholder="Please select"
                fullWidth
              />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>

        <Field
          name="job-title"
          data-bdd="job-title"
          onBlur={(next, rest) => {
            if (values["job-title"] != null) {
              values["job-title"] = values["job-title"].trim();
            }
            next(rest);
          }}
        >
          <Label>{content.jobTitle.label}</Label>
          <Row>
            <Cell sizeMedium={7} sizeLarge={5}>
              <TextInput fullWidth maxLength={100} />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>

        <Field
          name="company-name"
          data-bdd="company-name"
          onBlur={(next, rest) => {
            if (values["company-name"] != null) {
              values["company-name"] = values["company-name"].trim();
            }
            next(rest);
          }}
        >
          <Label>{content.companyName.label}</Label>
          <Row>
            <Cell sizeMedium={7} sizeLarge={5}>
              <TextInput fullWidth maxLength={25} />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>

        <Fieldset name="employment-start-date" data-bdd="employment-start-date">
          <Legend marginBottom={1}>{content.employmentStartDate.label}</Legend>
          <Hint marginBottom={1}>{content.employmentStartDate.hint}</Hint>
          <DateInput format="YYYY-MM" />
          <FieldFeedback />
        </Fieldset>

        <Fieldset
          name="role-in-business"
          data-bdd="role-in-business"
          marginBottom={7}
        >
          <Legend marginBottom={1} id="role-in-business">
            {content.roleInBusiness.label}
          </Legend>
          <Hint marginBottom={1}>{content.roleInBusiness.hint}</Hint>
          {businessRole
            .filter(
              ({ value }) =>
                !(
                  (value === "SOLE_TRADER" &&
                    values["employment-status"] !== "SELF") ||
                  (value === "EMPLOYEE" &&
                    values["employment-status"] === "SELF")
                )
            )
            .map((role, index) => (
              <Radio
                data-bdd={`role-in-business-option-${index}`}
                value={role.value}
                key={role.value}
              >
                {role.label}
              </Radio>
            ))}

          <FieldFeedback marginTop={1} />
        </Fieldset>

        <Reveal marginBottom={2} duration={400}>
          {notEmployee(values["role-in-business"]) && (
            <BusinessType
              values={values}
              content={content.business}
              setFieldValue={this.props.setFieldValue}
            />
          )}
        </Reveal>

        <Section>
          <Heading level={2} marginBottom={4}>
            {content.companyAddress.heading}
          </Heading>

          <Field
            name="address-line-1"
            data-bdd="address-line-1"
            onBlur={(next, rest) => {
              if (values["address-line-1"] != null) {
                values["address-line-1"] = values["address-line-1"].trim();
              }
              next(rest);
            }}
          >
            <Label>{content.addressLine1.label}</Label>
            <Row>
              <Cell sizeMedium={7} sizeLarge={5}>
                <TextInput fullWidth maxLength={30} />
              </Cell>
            </Row>
            <FieldFeedback />
          </Field>

          <Field
            name="address-line-2"
            data-bdd="address-line-2"
            onBlur={(next, rest) => {
              if (values["address-line-2"] != null) {
                values["address-line-2"] = values["address-line-2"].trim();
              }
              next(rest);
            }}
          >
            <Label>{content.addressLine2.label}</Label>
            <Row>
              <Cell sizeMedium={7} sizeLarge={5}>
                <TextInput fullWidth maxLength={30} />
              </Cell>
            </Row>{" "}
            <FieldFeedback />
          </Field>

          <Field
            name="postcode"
            data-bdd="postcode"
            onBlur={(next, rest) => {
              if (values["postcode"] != null) {
                values["postcode"] = values["postcode"].trim();
              }
              next(rest);
            }}
          >
            <Label>{content.postcode.label}</Label>
            <TextInput fullWidth />
            <FieldFeedback />
          </Field>
        </Section>
      </>
    );
  }
}

export default Employed;
