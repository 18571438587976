import React, { Component } from "react";
import {
  Section,
  Heading,
  Paragraph,
  Button,
  IconStatusInformationOnlight,
  FormLayout
} from "@piggybank/core";
import content from "../../i18n/content/applicationComplete.js";
import { TRACK_APPLICATION_COMPLETE } from "../../shared/analytics/pageData.js";
import { view } from "../../shared/analytics/tealium/events";
import { utagGlobal } from "../../shared/analytics/tealium";
import formatTitle from "../../shared/transformations/formatTitle";

const requiresGeneralCopy = pageType =>
  ["general", "income", "affordability"].includes(pageType);

class ApplicationComplete extends Component {
  taggingCopy = pageType => {
    if (pageType === "income" || pageType === "affordability") {
      return ["income", "and", "affordability"];
    } else {
      return [pageType];
    }
  };

  componentDidMount() {
    if (this.props.pageType !== "general") {
      TRACK_APPLICATION_COMPLETE.funnel_step_name = `referred - ${this.taggingCopy(
        this.props.pageType
      ).join(" ")}`;
      TRACK_APPLICATION_COMPLETE.page_name = `pws:loans:personal loan:application:referred:${this.taggingCopy(
        this.props.pageType
      ).join(" ")}`;
      TRACK_APPLICATION_COMPLETE.page_url = `/loans/personal-loan/application/referred/${this.taggingCopy(
        this.props.pageType
      ).join("-")}`;
    }
    TRACK_APPLICATION_COMPLETE.application_id = this.props.applicationId;
    view(Object.assign(utagGlobal, TRACK_APPLICATION_COMPLETE));
    document.title = formatTitle(content.pageTitle);
  }

  handleRedirect() {
    window.location = "https://bank.marksandspencer.com/";
  }

  render() {
    return (
      <FormLayout>
        <Section>
          <div style={{ marginLeft: "-5px" }}>
            <IconStatusInformationOnlight width="36" data-bdd="confirm-icon" />
          </div>

          <Heading level={1} data-bdd="page-title" marginBottom={5}>
            {content.pageTitle}
          </Heading>
          {requiresGeneralCopy(this.props.pageType) && (
            <Paragraph data-bdd="general-page-copy-1" marginBottom={6}>
              {content.generalPageCopy1}
            </Paragraph>
          )}
          {this.props.pageType === "exception" && (
            <Paragraph data-bdd="exception-page-copy-1" marginBottom={6}>
              {content.exceptionPageCopy1}
            </Paragraph>
          )}
          <Button
            data-bdd="home-page-button"
            type="button"
            onClick={e => {
              e.stopPropagation();
              this.handleRedirect();
            }}
          >
            {content.buttonLabel}
          </Button>
        </Section>
      </FormLayout>
    );
  }
}

export default ApplicationComplete;
