export const occupation = [
  {
    value: "PRO",
    label: "Professional"
  },
  {
    value: "MAN",
    label: "Managerial"
  },
  {
    value: "MS",
    label: "Manual skilled"
  },
  {
    value: "MSS",
    label: "Manual semi-skilled"
  },
  {
    value: "MIL",
    label: "Military"
  },
  {
    value: "CLE",
    label: "Clerical"
  },
  {
    value: "SAL",
    label: "Sales"
  }
];
