const view = (object, app = window, konsole = console) => {
  const tms = app.TMS;
  if (tms) {
    tms.trackView(object);
  } else {
    konsole.warn('TMS is not available'); // eslint-disable-line no-console
  }
};

const link = (object, app = window, konsole = console) => {
  const tms = app.TMS;
  if (tms) {
    tms.trackEvent(object);
  } else {
    konsole.warn('TMS is not available'); // eslint-disable-line no-console
  }
};

export { view, link };
