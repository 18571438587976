import React, { Component } from "react";
import Countdown from "../Countdown";
import {
  Paragraph,
  ModalDialog,
  Button,
  ButtonRow,
  HorizontalRule
} from "@piggybank/core";
import content from "../../i18n/content/activityWarning";

class ActivityWarningModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalActive: this.props.show
    };

    this.activateModal = this.activateModal.bind(this);
    this.deactivateModal = this.deactivateModal.bind(this);
  }

  activateModal = () => {
    this.setState({ modalActive: true });
  };

  deactivateModal = () => {
    this.setState({ modalActive: false });
  };

  render() {
    return (
      <ModalDialog
        title={content.modalTitle}
        show={this.state.modalActive}
        dismissible={false}
      >
        <Paragraph marginBottom={3}>{content.modalIntro}</Paragraph>
        <Paragraph marginBottom={3}>
          Time Remaining:{" "}
          <span className="modal__countdown">
            <Countdown ms={60000} />{" "}
          </span>
          {content.modalCountdown}
        </Paragraph>
        <Paragraph marginBottom={6}>{content.modalClose} </Paragraph>
        <HorizontalRule />
        <ButtonRow>
          <Button
            data-bdd="modal-continue-application"
            type="button"
            onClick={this.props.click}
          >
            {content.modalButton}
          </Button>
        </ButtonRow>
      </ModalDialog>
    );
  }
}
export default ActivityWarningModal;
