import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import AppContextWrapper from "./App";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./shared/appContext";
import "@piggybank/core/lib/styles.mands.css";
import "@piggybank/form/lib/styles.mands.css";
import "./index.css";

ReactDOM.render(
  <BrowserRouter>
    <AppProvider>
      <AppContextWrapper />
    </AppProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
