import React, { Component } from "react";
import {
  Section,
  Heading,
  HorizontalRule,
  Reveal,
  UnorderedList,
  ListItem,
  Link,
  PlaybackKey,
  PlaybackList,
  PlaybackValue,
  VisuallyHidden,
  IconEdit
} from "@piggybank/core";
import content from "../../i18n/content/review.js";
import personalDetailsContent from "../../i18n/content/personalDetails.js";
import {
  dateTransform,
  gender,
  maritalStatus,
  countryName
} from "../../shared/transformations";
import { reviewCurrentName, reviewPreviousNames } from "../../shared/helpers";

class ReviewPersonalDetails extends Component {
  render() {
    const { values, onClick } = this.props;
    return (
      <Section data-bdd="personal-details-review">
        <div className="heading">
          <div className="heading heading--header">
            <Heading
              marginBottom={3}
              level={2}
              data-bdd="personal-details-heading"
            >
              {content.personalDetails.heading}
            </Heading>
          </div>
          <div className="heading heading--editlink">
            <Link
              elementType="button"
              type="button"
              buttonPadding
              data-bdd="personal-details-edit"
              id="personal-details-edit"
              onClick={onClick}
              strong
              iconSlotRight={<IconEdit inline />}
            >
              {content.editButton}
              <VisuallyHidden>{content.personalDetails.heading}</VisuallyHidden>
            </Link>
          </div>
        </div>
        <HorizontalRule />
        <Heading level={3} marginBottom={3}>
          {content.personalDetails.subSectionOne}
        </Heading>
        <PlaybackList id="personalDetailsSectionOne">
          <PlaybackKey>{content.personalDetails.nameLabel}</PlaybackKey>
          <PlaybackValue marginBottom={3} id="customerName">
            {reviewCurrentName(values)}
          </PlaybackValue>
          <Reveal marginBottom={0}>
            {values["more-names"] === "yes" && (
              <>
                <PlaybackKey>
                  {content.personalDetails.previousNamesLabel}
                </PlaybackKey>
                <PlaybackValue id="prevNames" marginBottom={0}>
                  <UnorderedList type="none" marginBottom={3} spacing={1}>
                    {reviewPreviousNames(values.previousNames).map(function(
                      name,
                      i
                    ) {
                      return <ListItem key={i}>{name}</ListItem>;
                    })}
                  </UnorderedList>
                </PlaybackValue>
              </>
            )}
          </Reveal>
          <PlaybackKey>{personalDetailsContent.dob.label}</PlaybackKey>
          <PlaybackValue id="dob" marginBottom={3}>
            {dateTransform(values["date-of-birth"])}
          </PlaybackValue>
          <PlaybackKey>{personalDetailsContent.gender.label}</PlaybackKey>
          <PlaybackValue id="gender" marginBottom={3}>
            {gender(values.gender)}
          </PlaybackValue>
          <PlaybackKey>
            {personalDetailsContent.maritalStatus.label}
          </PlaybackKey>
          <PlaybackValue id="maritalStatus" marginBottom={3}>
            {maritalStatus(values["marital-status"])}
          </PlaybackValue>
          <PlaybackKey>
            {personalDetailsContent.numberOfDependants.label}
          </PlaybackKey>
          <PlaybackValue id="numberOfDependants" marginBottom={3}>
            {values["number-of-dependants"]}
          </PlaybackValue>
        </PlaybackList>
        <Heading level={3} marginBottom={3}>
          {content.personalDetails.subSectionTwo}
        </Heading>
        <PlaybackList id="personalDetailsSectionTwo">
          <PlaybackKey>
            {personalDetailsContent.countryOfBirth.label}
          </PlaybackKey>
          <PlaybackValue id="countryOfBirth" marginBottom={3}>
            {countryName(values["country-of-birth"])}
          </PlaybackValue>
          <PlaybackKey>
            {personalDetailsContent.countryOfResidence.label}
          </PlaybackKey>
          <PlaybackValue id="countryOfResidence" marginBottom={3}>
            {countryName(values["country-of-residence"])}
          </PlaybackValue>
          <PlaybackKey>{personalDetailsContent.nationality.label}</PlaybackKey>
          <PlaybackValue id="nationality" marginBottom={7}>
            <UnorderedList type="none" spacing={1}>
              <ListItem key={0}>{countryName(values.nationality)}</ListItem>
              <Reveal marginBottom={0}>
                {values["additional-nationalities"] !== undefined &&
                  values["additional-nationalities"].length > 0 && (
                    <>
                      {values["additional-nationalities"].map(function(
                        countryCode,
                        i
                      ) {
                        return (
                          <ListItem key={i + 1}>
                            {countryName(countryCode)}
                          </ListItem>
                        );
                      })}
                    </>
                  )}
              </Reveal>
            </UnorderedList>
          </PlaybackValue>
        </PlaybackList>
      </Section>
    );
  }
}

export default ReviewPersonalDetails;
