import residentialStatus from "../../i18n/lookups/residentialStatus";

export default code => {
  var status;
  for (var i = residentialStatus.length; i--; ) {
    if (residentialStatus[i].value === code) {
      status = residentialStatus[i].label;
      break;
    }
  }
  return status;
};
