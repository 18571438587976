export const titles = [
  { value: "MR", label: "Mr" },
  { value: "MRS", label: "Mrs" },
  { value: "MISS", label: "Miss" },
  { value: "MS", label: "Ms" },
  { value: "DR", label: "Dr" },
  { value: "LADY", label: "Lady" },
  { value: "LORD", label: "Lord" },
  { value: "REV", label: "Rev" }
];
