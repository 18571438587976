import React, { Component } from "react";
import {
  Section,
  Heading,
  Paragraph,
  Button,
  IconError,
  Link,
  HorizontalRule,
  FormLayout
} from "@piggybank/core";

import content from "../../i18n/content/error.js";
import { view } from "../../shared/analytics/tealium/events";
import { TRACK_TECHNICAL_ERROR } from "../../shared/analytics/pageData.js";
import { utagGlobal } from "../../shared/analytics/tealium";
import { AppConsumer } from "../../shared/appContext.js";
import Timer from "../../components/Timer";
import formatTitle from "../../shared/transformations/formatTitle";

const eventTaggingConstants = {
  page_url: TRACK_TECHNICAL_ERROR.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_TECHNICAL_ERROR.funnel_step_name
};
class Error extends Component {
  componentDidMount() {
    TRACK_TECHNICAL_ERROR.event_subcategory = this.props.errorCode;
    view(Object.assign(utagGlobal, TRACK_TECHNICAL_ERROR));
    document.title = formatTitle(content.pageTitle);
  }

  handleRedirect(queryString) {
    window.location = "/" + queryString;
  }

  render() {
    return (
      <FormLayout>
        <Section>
          <div style={{ margin: "3px 0px 3px -2px", padding: "3px" }}>
            <IconError width="36" data-bdd="error-icon" />
          </div>
          <Heading level={1} data-bdd="page-title">
            {content.pageTitle}
          </Heading>
          <Paragraph lead data-bdd="page-intro">
            {content.pageIntro}
          </Paragraph>
          <HorizontalRule marginBottom={7} />
          <Heading level={2}>{content.pageSubheading}</Heading>
          <Paragraph data-bdd="page-copy">
            {content.pageCopy}{" "}
            <Link data-bdd="contact-link" external href={content.link.url}>
              {content.link.copy}
            </Link>
          </Paragraph>
          {this.props.sourceCodeValid === true && (
            <AppConsumer.Consumer>
              {val => {
                return (
                  <Button
                    data-bdd="start-again-button"
                    type="button"
                    onClick={e => {
                      e.stopPropagation();
                      this.handleRedirect(val.queryString);
                    }}
                  >
                    {content.buttonLabel}
                  </Button>
                );
              }}
            </AppConsumer.Consumer>
          )}
          <Timer pageTags={eventTaggingConstants} />
        </Section>
      </FormLayout>
    );
  }
}

export default Error;
