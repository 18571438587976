import React, { Component } from "react";
import PropTypes from "prop-types";
import { DateTime, Interval } from "luxon";

import {
  Section,
  Heading,
  Paragraph,
  HorizontalRule,
  VisuallyHidden,
  Reveal,
  Callout,
  Link,
  IconAddThick,
  FormLayout
} from "@piggybank/core";
import {
  Label,
  Form,
  Field,
  TextInput,
  FieldFeedback,
  FormFeedback,
  Navigation,
  Hint,
  AddAnother,
  TelephoneInput
} from "@piggybank/form";
import content from "../../i18n/content/contactDetails.js";
import { contactDetailsSchema } from "../../shared/validation-schema.js";
import { AddressWrapper, UKAddress } from "../../organisms/AddressFields";
import { link, view } from "../../shared/analytics/tealium/events";
import residentialStatus from "../../i18n/lookups/residentialStatus";
import {
  valueToLabel,
  utagGlobal,
  validationErrors
} from "../../shared/analytics/tealium";

import Timer from "../../components/Timer";
import "./ContactDetails.css";
import { TRACK_CONTACT_DETAILS } from "../../shared/analytics/pageData.js";
import StepTracker from "../../components/StepTracker/StepTracker.js";
import formatTitle from "../../shared/transformations/formatTitle";

const initialValues = {
  email: "",
  "mobile-number": "",
  additionalTelNumbers: "",
  "post-code": "",
  address1: "",
  address2: "",
  "date-moved": "",
  previousAddresses: [],
  "residential-status": ""
};

const needMoreAddresses = values => {
  let prevAddressCount = values.previousAddresses
    ? values.previousAddresses.length
    : 0;
  if (prevAddressCount > 0 && prevAddressCount < 5) {
    if (timeAt(values["date-moved"]) > 3) {
      //remove any previous addresses if more than 3 yrs at current
      values.previousAddresses = [];
      return false;
    } else {
      for (let i = 0; i < prevAddressCount; i++) {
        let dateMoved = values.previousAddresses[i]["date-moved"];
        if (
          dateMoved === undefined ||
          dateMoved === "incomplete" ||
          dateMoved === "invalid" ||
          dateMoved.length !== 7
        ) {
          //don't ask for more until we know the date
          return false;
        } else if (timeAt(dateMoved) > 3) {
          //remove any further previous addresses if more than 3 yrs at this one
          values.previousAddresses = values.previousAddresses.slice(0, i + 1);
          return false;
        }
      }
      return true;
    }
  } else if (prevAddressCount === 5) {
    return false;
  } else {
    return timeAt(values["date-moved"]) < 3;
  }
};

const timeAt = dateMoved => {
  const today = new Date();
  return Interval.fromDateTimes(new Date(dateMoved), today).length("years");
};

const getLatestDateFormatted = values => {
  let date = lastDateInput(values);
  return date !== undefined &&
    date !== "incomplete" &&
    date !== "invalid" &&
    date.length === 7
    ? DateTime.fromISO(date + "-01").toFormat("MMMM yyyy")
    : "";
};

const lastDateInput = values => {
  let prevAddressCount = values.previousAddresses.length;
  if (prevAddressCount > 0) {
    return values.previousAddresses[prevAddressCount - 1]["date-moved"];
  } else {
    return values["date-moved"];
  }
};

const reviewData = values => {
  if (document.getElementById("home-number-field") === null) {
    values["home-number"] = undefined;
  }
  if (document.getElementById("work-number-field") === null) {
    values["work-number"] = undefined;
  }
  if (values.previousAddresses.length > 1) {
    for (var i = 1; i < values.previousAddresses.length; i++) {
      if (values.previousAddresses[i].country !== "GB") {
        values.previousAddresses[i]["post-code"] = undefined;
        values.previousAddresses[i]["house-number"] = undefined;
        values.previousAddresses[i]["flat-number"] = undefined;
        values.previousAddresses[i]["house-name"] = undefined;
      }
    }
  }
};

const eventTaggingConstants = {
  page_name: TRACK_CONTACT_DETAILS.page_name,
  page_url: TRACK_CONTACT_DETAILS.page_url,
  page_type: utagGlobal.page_type,
  funnel_name: utagGlobal.funnel_name,
  funnel_step: TRACK_CONTACT_DETAILS.funnel_step,
  funnel_step_name: TRACK_CONTACT_DETAILS.funnel_step_name
};

class ContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTelNumbers: false
    };
  }

  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_CONTACT_DETAILS));
    document.title = formatTitle(content.pageTitle);
  }

  areAdditionalNumbersPresent = values => {
    if (values) {
      const homeNo = values["home-number"];
      const workNo = values["work-number"];
      if (
        (homeNo === undefined || homeNo.raw === "") &&
        (workNo === undefined || workNo.raw === "")
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  renderEmail = values => {
    return (
      <Section>
        <Heading level={2}>{content.sectionTitle}</Heading>
        <Field
          name="email"
          onBlur={(next, rest) => {
            values.email = values.email.trim();
            next(rest);
          }}
        >
          <Label>{content.email.label}</Label>
          <Hint>{content.email.hint}</Hint>
          <TextInput fullWidth maxLength={55} />
          <FieldFeedback />
        </Field>
      </Section>
    );
  };

  renderAddInfo = () => {
    return (
      <Callout
        title="More address information needed"
        data-bdd="address-history-callout"
        type="information"
        marginBottom={0}
      >
        <Paragraph marginBottom={0}>{content.moveDate.hint}</Paragraph>
      </Callout>
    );
  };

  render() {
    return (
      <FormLayout>
        <div className="contact-details">
          <Section>
            <StepTracker />
            <Heading level={1}>{content.pageTitle}</Heading>
            <Paragraph lead>{content.pageIntro}</Paragraph>
            <HorizontalRule />
          </Section>
          <Form
            initialValues={initialValues}
            validationSchema={contactDetailsSchema}
            onError={errors => {
              link({
                ...eventTaggingConstants,
                event_category: "error",
                event_action: "field validation",
                event_content: validationErrors(errors.errors)
              });
            }}
            onSubmit={(next, rest) => {
              link({
                ...eventTaggingConstants,
                event_category: "content",
                event_action: "radio",
                event_content: `residential status: ${valueToLabel(
                  rest.values["residential-status"],
                  residentialStatus
                )}`
              });
              next(rest);
            }}
          >
            {({ values, setFieldValue }) => (
              <>
                <FormFeedback />
                {this.renderEmail(values)}
                <Section>
                  <Field name="mobile-number" data-bdd="mobile-number">
                    <Label>{content.telephone.label}</Label>
                    <Hint>{content.telephone.hint}</Hint>
                    <TelephoneInput
                      style={{ maxWidth: 190 }}
                      maxLength={14}
                      required={true}
                      extendedValues
                      countrySelect={false}
                    />
                    <FieldFeedback />
                  </Field>
                  <div>
                    <div style={{ marginBottom: "var(--unit4)" }}>
                      {!this.state.showTelNumbers &&
                        !this.areAdditionalNumbersPresent(values) && (
                          <Link
                            id="additionalTelNumbers"
                            hoverUnderline
                            style={{
                              fontWeight: "bold"
                            }}
                            elementType="button"
                            type="button"
                            iconSlotLeft={
                              <span style={{ marginRight: 5 }}>
                                <IconAddThick inline />
                              </span>
                            }
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                showTelNumbers: !this.state.showTelNumbers
                              });
                            }}
                          >
                            Add another phone number
                          </Link>
                        )}
                    </div>
                    <Reveal focus>
                      {(this.state.showTelNumbers ||
                        this.areAdditionalNumbersPresent(values)) && (
                        <>
                          <Field
                            name={"home-number"}
                            data-bdd="home-number"
                            id="home-number"
                          >
                            <Label>{content.telephoneHome.label}</Label>
                            <Hint>{content.telephoneHome.hint}</Hint>
                            <TelephoneInput
                              maxLength={14}
                              extendedValues
                              style={{ maxWidth: 190 }}
                              countrySelect={false}
                              required={false}
                            />
                            <FieldFeedback />
                          </Field>

                          <Field
                            name={"work-number"}
                            data-bdd="work-number"
                            id="work-number"
                          >
                            <Label>{content.telephoneWork.label}</Label>
                            <Hint>{content.telephoneWork.hint}</Hint>
                            <TelephoneInput
                              maxLength={14}
                              extendedValues
                              style={{ maxWidth: 190 }}
                              countrySelect={false}
                              required={false}
                            />
                            <FieldFeedback />
                          </Field>
                        </>
                      )}
                    </Reveal>
                  </div>{" "}
                </Section>
                <Section>
                  <Heading level={2}>{content.currentAddress.title}</Heading>
                  <Paragraph>{content.currentAddress.introCopy}</Paragraph>
                </Section>
                <UKAddress
                  content={content}
                  values={values}
                  setFieldValue={setFieldValue}
                  showResStatus={true}
                  path={""}
                />
                <AddAnother
                  name="previousAddresses"
                  data-bdd="previousAddresses"
                  fullWidth
                  itemInitialValue={{}}
                  max={5}
                  showAdd={needMoreAddresses(values)}
                  addInfoSlot={this.renderAddInfo()}
                  textMap={{
                    addButton: `Add your address before ${getLatestDateFormatted(
                      values
                    )}?`
                  }}
                  renderRemoveButtonText={({ index }) => (
                    <>
                      Remove
                      <VisuallyHidden>
                        {" "}
                        previous address {index + 1}
                      </VisuallyHidden>
                    </>
                  )}
                  duration={300}
                >
                  {({ path }) => (
                    <AddressWrapper
                      content={content}
                      values={values}
                      setFieldValue={setFieldValue}
                      path={path}
                    />
                  )}
                </AddAnother>

                <Section>
                  <HorizontalRule />

                  <Navigation
                    id="next"
                    textMap={{ previous: "Back", next: "Continue" }}
                    onNext={(next, rest) => {
                      reviewData(values);
                      next(rest);
                    }}
                  />
                </Section>
              </>
            )}
          </Form>
          <Timer pageTags={eventTaggingConstants} />
        </div>
      </FormLayout>
    );
  }
}

ContactDetails.propTypes = {
  values: PropTypes.object,
  navigateTo: PropTypes.func
};

export default ContactDetails;
