import React, { Component } from "react";
import PropTypes from "prop-types";
import ActivityWarningModal from "../ActivityWarningModal";
import { Redirect } from "react-router-dom";
import { link } from "../../shared/analytics/tealium/events";
import { TRACK_TIMEOUT_POPUP } from "../../shared/analytics/eventData";

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: "stable"
    };

    this.setTimeout = this.setTimeout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.checkTimeout = this.checkTimeout.bind(this);
  }

  componentDidMount() {
    this.events = ["load", "mousedown", "click", "scroll", "keydown"];

    this.events.forEach(event => {
      window.addEventListener(event, this.checkTimeout);
    });

    this.setTimeout();
  }

  componentWillUnmount() {
    this.events.forEach(event => {
      window.removeEventListener(event, this.checkTimeout);
    });
  }

  setTimeout() {
    const { warningTime, timeoutTime } = this.props;
    this.warningTimeout = setTimeout(() => {
      this.setState({ state: "warning" });
    }, warningTime);

    this.errorTimeout = setTimeout(() => {
      this.setState({ state: "timeout" });
    }, timeoutTime);
  }

  checkTimeout() {
    const { state } = this.state;
    if (state === "stable") {
      this.resetTimeout();
    }
  }

  resetTimeout() {
    if (this.warningTimeout) clearTimeout(this.warningTimeout);
    if (this.errorTimeout) clearTimeout(this.errorTimeout);
    this.setState({ state: "stable" });

    this.setTimeout();
  }

  render() {
    const { state } = this.state;
    const { pageTags } = this.props;

    if (state === "warning") {
      link(Object.assign(pageTags, TRACK_TIMEOUT_POPUP));
      return (
        <ActivityWarningModal
          show={state === "warning" ? true : false}
          click={this.resetTimeout}
        />
      );
    }
    if (state === "timeout") {
      return <Redirect to={{ pathname: "/timeout" }} />;
    }
    return null;
  }
}

Timer.defaultProps = {
  warningTime: 540000,
  timeoutTime: 600000
};

Timer.propTypes = {
  warningTime: PropTypes.number,
  timeoutTime: PropTypes.number
};

export default Timer;
