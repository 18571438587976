export default (code, options) => {
  if (
    code !== undefined &&
    code !== null &&
    code[0] !== undefined &&
    code[0] !== null
  ) {
    let purpose = code[0];
    for (var i = options.length; i--; ) {
      if (options[i].value === purpose) {
        return options[i].label;
      }
    }
    return "";
  } else {
    return "";
  }
};
