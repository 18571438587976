import React, { Component } from "react";
import {
  Section,
  Heading,
  HorizontalRule,
  Link,
  Reveal,
  PlaybackKey,
  PlaybackList,
  PlaybackValue,
  VisuallyHidden,
  IconEdit
} from "@piggybank/core";
import content from "../../i18n/content/review.js";
import {
  displayCurrency,
  capitalise,
  loanPurposeLookup
} from "../../shared/transformations";
import purposeContent from "../../i18n/content/PurposeOfLoan.js";

class ReviewLoanDetails extends Component {
  render() {
    const { values, onClick, loanPurposeOptions } = this.props;
    return (
      <Section data-bdd="loan-details-review" marginBottom={7}>
        <div className="heading">
          <div className="heading heading--header">
            <Heading marginBottom={3} level={2} data-bdd="loan-details-heading">
              {content.loanDetails.heading}
            </Heading>
          </div>
          <div className="heading heading--editlink">
            <Link
              elementType="button"
              type="button"
              buttonPadding
              data-bdd="loan-details-edit"
              id="loan-details-edit"
              onClick={onClick}
              strong
              iconSlotRight={<IconEdit inline />}
            >
              {content.editButton}
              <VisuallyHidden>{content.loanDetails.heading}</VisuallyHidden>
            </Link>
          </div>
        </div>
        <HorizontalRule />
        <PlaybackList id="existingLoanSection">
          <PlaybackKey>{purposeContent.existingCustomer.label}</PlaybackKey>
          <PlaybackValue id="existing-customer" marginBottom={3}>
            {capitalise(values.existingCustomer)}
          </PlaybackValue>
          <Reveal marginBottom={0}>
            {values.existingCustomer === "yes" && (
              <>
                <PlaybackKey>{purposeContent.existingLoan.label}</PlaybackKey>
                <PlaybackValue id="exisiting-loan" marginBottom={3}>
                  {capitalise(values.existingLoan)}
                </PlaybackValue>
              </>
            )}
          </Reveal>
          <Reveal marginBottom={0}>
            {values.existingLoan === "yes" && (
              <>
                <PlaybackKey>
                  {purposeContent.settlingExistingLoan.label}
                </PlaybackKey>
                <PlaybackValue id="repay-exisiting" marginBottom={3}>
                  {"No"}
                </PlaybackValue>
              </>
            )}
          </Reveal>
        </PlaybackList>
        <PlaybackList id="loanDetailsSection">
          <PlaybackKey>{content.loanDetails.loanPurpose}</PlaybackKey>
          <PlaybackValue marginBottom={3} id="loanPurpose">
            {loanPurposeLookup(values["select-purpose"], loanPurposeOptions)}
          </PlaybackValue>
          <PlaybackKey>{content.loanDetails.loanAmount}</PlaybackKey>
          <PlaybackValue marginBottom={3} id="loanAmount">
            {displayCurrency(values.loanAmount)}
          </PlaybackValue>
          <PlaybackKey>{content.loanDetails.numberOfPayments}</PlaybackKey>
          <PlaybackValue marginBottom={3} id="numberOfPayments">
            {values.loanDuration}
          </PlaybackValue>
          <PlaybackKey>{content.loanDetails.apr}</PlaybackKey>
          <PlaybackValue marginBottom={3} id="apr">
            {values.apr + "%"}
          </PlaybackValue>
          <PlaybackKey>{content.loanDetails.monthlyPayment}</PlaybackKey>
          <PlaybackValue marginBottom={3} id="monthlyPayment">
            {values.defer === "yes"
              ? displayCurrency(values.monthlyRepaymentsDefer)
              : displayCurrency(values.monthlyRepayments)}
          </PlaybackValue>
          <PlaybackKey>{content.loanDetails.totalPayment}</PlaybackKey>
          <PlaybackValue marginBottom={3} id="totalPayment">
            {values.defer === "yes"
              ? displayCurrency(values.totalAmountPayableDefer)
              : displayCurrency(values.totalAmountPayable)}
          </PlaybackValue>
          {/* <PlaybackKey>{content.loanDetails.deferPayment}</PlaybackKey>
          <PlaybackValue marginBottom={3} id="deferPayment">
            {capitalise(values.defer)}
          </PlaybackValue> */}
        </PlaybackList>
      </Section>
    );
  }
}

export default ReviewLoanDetails;
