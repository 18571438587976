import React, { Component } from "react";
import { getIn } from "formik";
import PropTypes from "prop-types";
import {
  Section,
  Heading,
  Paragraph,
  HorizontalRule,
  Reveal,
  Expander,
  Link,
  ListItem,
  UnorderedList,
  Row,
  Cell,
  VisuallyHidden,
  FormLayout
} from "@piggybank/core";
import {
  Form,
  Field,
  Fieldset,
  Label,
  FieldFeedback,
  FormFeedback,
  Navigation,
  CurrencyInput,
  DoorHanger,
  Legend,
  YesNoRadio,
  Hint,
  Radio,
  AddAnother,
  Select,
  DateInput
} from "@piggybank/form";
import { financialDetailsSchema } from "../../shared/validation-schema.js";
import content from "../../i18n/content/financialDetails.js";
import taxResidency from "../../i18n/lookups/taxResidency";
import { countries } from "../../i18n/lookups/countries";
import { link, view } from "../../shared/analytics/tealium/events";
import { utagGlobal, validationErrors } from "../../shared/analytics/tealium";
import { TRACK_FINANCIAL_DETAILS } from "../../shared/analytics/pageData.js";
import {
  TRACK_EXAMPLES_OF_CHANGE,
  TRACK_TAX_RESIDENCY_INFO
} from "../../shared/analytics/eventData.js";
import Timer from "../../components/Timer";
import StepTracker from "../../components/StepTracker/StepTracker.js";
import { removeFields } from "../../shared/utils";
import formatTitle from "../../shared/transformations/formatTitle";

const initialValues = {
  "personal-income": "",
  "tax-residency-type": "",
  "time-at-bank": "",
  "market-value": "",
  "mortgage-left-pay": "",
  "monthly-outgoings-mortgage": "",
  "monthly-outgoings-council": "",
  "monthly-outgoings-insurance": "",
  affordability: "",
  "tax-residencies": [""]
};

// eslint-disable-next-line
const testingValues = {};
const isJointMaritalStatus = status => ["M", "C"].includes(status);
const isLessThanTenThousand = income => income < 10000 && !isNaN(income);
const maxSelections = type => (type === "O" ? 2 : 3);
let personalIncomeCheck = false;

const eventTaggingConstants = {
  page_name: TRACK_FINANCIAL_DETAILS.page_name,
  page_url: TRACK_FINANCIAL_DETAILS.page_url,
  page_type: utagGlobal.page_type,
  funnel_step: TRACK_FINANCIAL_DETAILS.funnel_step,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_FINANCIAL_DETAILS.funnel_step_name
};
let exampleOfChangesEventFired = false;
let taxResidencyEventFired = false;

const reviewData = values => {
  if (values["residential-status"] !== "OWNER") {
    removeFields(values, ["market-value", "mortgage-left-pay"]);
  }
};
class FinancialDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      affordabilityDoorhangerOpen: false,
      taxResidencyDoorhangerOpen: false
    };
  }

  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_FINANCIAL_DETAILS));
    document.title = formatTitle(content.pageTitle);
  }

  additionalTaxResidenciesCopy = values => {
    if (values["tax-residency-type"] === "O") {
      return (
        <Paragraph data-bdd="additional-tax-residencies-copy">
          {content.taxResidency.ukAndOtherCountriesCopy}
        </Paragraph>
      );
    }
    if (values["tax-residency-type"] === "N") {
      return (
        <Paragraph data-bdd="additional-tax-residencies-copy">
          {content.taxResidency.nonUkCopy}
        </Paragraph>
      );
    }
  };

  render() {
    return (
      <FormLayout>
        <div className="financial-details">
          <Section marginBottom={7}>
            <StepTracker />
            <Heading level={1}>{content.pageTitle}</Heading>
            <Paragraph lead marginBottom={4}>
              {content.pageIntro}
            </Paragraph>
            <HorizontalRule />
          </Section>
          <Form
            initialValues={initialValues}
            validationSchema={financialDetailsSchema}
            onError={errors => {
              link({
                ...eventTaggingConstants,
                event_category: "error",
                event_action: "field validation",
                event_content: validationErrors(errors.errors)
              });
            }}
          >
            {({ values }) => (
              <>
                <FormFeedback />
                <Section marginBottom={6} data-bdd="personal-income-section">
                  <Heading
                    level={2}
                    marginBottom={4}
                    data-bdd="personal-income-heading"
                  >
                    {content.sectionTitle}
                  </Heading>
                  <Field
                    name="personal-income"
                    data-bdd="personal-income"
                    marginBottom={
                      isJointMaritalStatus(values["marital-status"]) &&
                      personalIncomeCheck
                        ? 5
                        : 0
                    }
                    onBlur={(next, rest) => {
                      personalIncomeCheck = isLessThanTenThousand(
                        parseInt(values["personal-income"])
                      );
                      next(rest);
                    }}
                  >
                    <Label>{content.personalIncome.label}</Label>
                    <CurrencyInput symbol="£" required={true} maxLength={6} />
                    <FieldFeedback />
                  </Field>

                  <DoorHanger
                    title={content.personalIncome.doorhangerTitle}
                    marginBottom={4}
                  >
                    <Paragraph>{content.personalIncome.doorhangerCopy.Paragraph1}</Paragraph>
                    <Paragraph>{content.personalIncome.doorhangerCopy.Paragraph2}</Paragraph>
                    <Paragraph>{content.personalIncome.doorhangerCopy.Paragraph3}</Paragraph>
                    <UnorderedList>
                      {content.personalIncome.doorhangerCopy.financialDetails.map((financialDetail, index) => (
                        <ListItem key={`item-${index}`}>{financialDetail}</ListItem>
                      ))}
                    </UnorderedList>
                    <Paragraph>{content.personalIncome.doorhangerCopy.Paragraph4}</Paragraph>
                  </DoorHanger>

                  <Field name="time-at-bank" data-bdd="time-at-bank">
                    <Legend marginBottom={1}>{content.timeAtBank.label}</Legend>
                    <Hint marginBottom={1}>{content.timeAtBank.hint}</Hint>
                    <DateInput format="YYYY-MM" />
                    <FieldFeedback />
                  </Field>

                  <Fieldset
                    name="affordability"
                    data-bdd="affordability"
                    marginBottom={0}
                  >
                    <Legend marginBottom={1} style={{ width: "100%" }}>
                      {content.affordability.labelRadio}
                    </Legend>
                    <YesNoRadio />
                    <FieldFeedback marginTop={1} />
                  </Fieldset>

                  <DoorHanger
                    title={content.affordability.doorhangerTitle}
                    onClick={() => {
                      this.setState(
                        {
                          affordabilityDoorhangerOpen: !this.state
                            .affordabilityDoorhangerOpen
                        },
                        () => {
                          if (
                            this.state.affordabilityDoorhangerOpen === true &&
                            exampleOfChangesEventFired !== true
                          ) {
                            exampleOfChangesEventFired = true;
                            link(
                              Object.assign(
                                utagGlobal,
                                TRACK_FINANCIAL_DETAILS,
                                TRACK_EXAMPLES_OF_CHANGE
                              )
                            );
                          }
                        }
                      );
                    }}
                  >
                    <UnorderedList>
                      <ListItem>
                        {content.affordability.doorhangerExample1}
                      </ListItem>
                      <ListItem>
                        {content.affordability.doorhangerExample2}
                      </ListItem>
                      <ListItem>
                        {content.affordability.doorhangerExample3}
                      </ListItem>
                    </UnorderedList>
                  </DoorHanger>
                </Section>

                {values["residential-status"] === "OWNER" && (
                  <Section marginBottom={7} data-bdd="about-property-section">
                    <Heading
                      name="about-property-heading"
                      level={2}
                      marginBottom={4}
                      data-bdd="about-property-heading"
                    >
                      {content.aboutProperty.sectionTitle}
                    </Heading>
                    <Paragraph data-bdd="about-property-intro">
                      {content.aboutProperty.sectionIntro}
                    </Paragraph>

                    <Field name="market-value" data-bdd="market-value">
                      <Label>{content.marketValue.label}</Label>
                      <Hint>{content.marketValue.hint}</Hint>
                      <CurrencyInput symbol="£" maxLength={6} />
                      <FieldFeedback />
                    </Field>
                    <Field
                      name="mortgage-left-pay"
                      data-bdd="mortgage-left-pay"
                    >
                      <Label>{content.mortgageLeftPay.label}</Label>
                      <CurrencyInput symbol="£" maxLength={6} />
                      <FieldFeedback />
                    </Field>
                  </Section>
                )}
                <Section marginBottom={7} data-bdd="monthly-outgoings-section">
                  <Heading
                    level={2}
                    marginBottom={4}
                    data-bdd="monthly-outgoings-heading"
                  >
                    {content.monthlyOutgoings.monthlyOutgoingsTitle}
                  </Heading>

                  <Paragraph data-bdd="monthly-outgoings-intro">
                    {content.monthlyOutgoings.label}
                  </Paragraph>

                  <Field
                    name="monthly-outgoings-mortgage"
                    data-bdd="monthly-outgoings-mortgage"
                  >
                    <Label>{content.monthlyOutgoingsMortgage.label}</Label>
                    <CurrencyInput symbol="£" maxLength={5} />
                    <FieldFeedback />
                  </Field>

                  <Field
                    name="monthly-outgoings-council"
                    data-bdd="monthly-outgoings-council"
                  >
                    <Label>{content.monthlyOutgoingsCouncil.label}</Label>
                    <CurrencyInput symbol="£" maxLength={4} />
                    <FieldFeedback />
                  </Field>
                  <Field
                    name="monthly-outgoings-insurance"
                    data-bdd="monthly-outgoings-insurance"
                  >
                    <Label>{content.monthlyOutgoingsInsurance.label}</Label>
                    <CurrencyInput symbol="£" maxLength={4} />
                    <FieldFeedback />
                  </Field>
                </Section>
                <Section marginBottom={5} data-bdd="tax-residency-section">
                  <Heading
                    level={2}
                    marginBottom={4}
                    data-bdd="tax-residency-heading"
                  >
                    {content.taxResidency.sectionTitle}
                  </Heading>
                  <Expander
                    marginBottom={5}
                    title={content.taxResidency.expanderTitle}
                    onClick={() => {
                      this.setState(
                        {
                          taxResidencyDoorhangerOpen: !this.state
                            .taxResidencyDoorhangerOpen
                        },
                        () => {
                          if (
                            this.state.taxResidencyDoorhangerOpen === true &&
                            taxResidencyEventFired !== true
                          ) {
                            taxResidencyEventFired = true;
                            link(
                              Object.assign(
                                utagGlobal,
                                TRACK_FINANCIAL_DETAILS,
                                TRACK_TAX_RESIDENCY_INFO
                              )
                            );
                          }
                        }
                      );
                    }}
                  >
                    <Paragraph>
                      {content.taxResidency.expanderContentPara1}
                    </Paragraph>
                    <Paragraph>
                      {content.taxResidency.expanderContentPara2}
                    </Paragraph>
                    <Paragraph>
                      {content.taxResidency.expanderContentPara3}
                    </Paragraph>
                    <Paragraph>
                      {content.taxResidency.expanderContentPara4}
                      <Link
                        href={content.taxResidency.expanderLinkHref}
                        external
                        textMap={{ newWindow: "Opens in a new window" }}
                      >
                        {content.taxResidency.expanderLinkTitle}.
                      </Link>
                    </Paragraph>
                  </Expander>
                  <Fieldset
                    name="tax-residency-type"
                    data-bdd="tax-residency-type"
                    marginBottom={0}
                    onChange={(next, { value, ...rest }) => {
                      if (value === "U") {
                        values["tax-residencies"] = [""];
                      } else {
                        values["tax-residencies"] = values[
                          "tax-residencies"
                        ].slice(0, maxSelections(value));
                      }
                      next({ value, ...rest });
                    }}
                  >
                    <Legend marginBottom={1}>
                      {content.taxResidency.label}
                    </Legend>
                    <Hint>{content.taxResidency.hint}</Hint>
                    {taxResidency.map((option, index) => (
                      <Radio
                        data-bdd={`tax-residency-option-${index}`}
                        value={option.value}
                        key={option.value}
                      >
                        {option.label}
                      </Radio>
                    ))}
                    <FieldFeedback marginTop={1} />
                  </Fieldset>

                  <Reveal duration={400} marginTop={4}>
                    {["O", "N"].includes(values["tax-residency-type"]) && (
                      <>
                        {this.additionalTaxResidenciesCopy(values)}

                        <Row>
                          <Cell sizeMedium={7} sizeLarge={5}>
                            <AddAnother
                              name="tax-residencies"
                              data-bdd="tax-residencies"
                              fullWidth
                              itemInitialValue=""
                              min={1}
                              max={maxSelections(values["tax-residency-type"])}
                              marginBottom={0}
                              textMap={{
                                addButton: (
                                  <>
                                    Add another
                                    <VisuallyHidden> country</VisuallyHidden>
                                  </>
                                ),
                                removeButton: (
                                  <>
                                    Remove
                                    <VisuallyHidden> country</VisuallyHidden>
                                  </>
                                )
                              }}
                            >
                              {({ path }) => (
                                <Field name={path} marginBottom={5}>
                                  <Label marginBottom={1}>
                                    {content.taxResidency.labelAdditional}
                                  </Label>
                                  <Select
                                    fullWidth
                                    placeholder={
                                      content.taxResidency.placeholder
                                    }
                                    options={countries.filter(
                                      ({ value }) =>
                                        value === getIn(values, path) ||
                                        (!values["tax-residencies"].includes(
                                          value
                                        ) &&
                                          !(value === "UK" || value === "GB"))
                                    )}
                                  />
                                  <FieldFeedback
                                    data-bdd={`additional-tax-residencies.${path}`}
                                  />
                                </Field>
                              )}
                            </AddAnother>
                          </Cell>
                        </Row>
                      </>
                    )}
                  </Reveal>
                </Section>
                <Section>
                  <HorizontalRule />
                  <Navigation
                    id="next"
                    textMap={{ previous: "Back", next: "Continue" }}
                    onNext={(next, rest) => {
                      reviewData(values);
                      next(rest);
                    }}
                  />
                </Section>
              </>
            )}
          </Form>
          <Timer pageTags={eventTaggingConstants} />
        </div>
      </FormLayout>
    );
  }
}
FinancialDetails.propTypes = {
  values: PropTypes.object
};
export default FinancialDetails;
