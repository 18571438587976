import { DateTime, Interval } from "luxon";

export const isBeforeDateOfBirth = function isBeforeDateOfBirth(msg) {
  return this.test({
    name: "isBeforeDateOfBirth",
    exclusive: false,
    message: msg,
    test: function () {
      var employmentStart = this.options.originalValue;
      var dob = this.parent["date-of-birth"];
      var startDate = new Date(employmentStart + "-01");
      var dateOfBirth = new Date(dob);
      return startDate > dateOfBirth;
    }
  });
};

export const isAddressOneOf = function isAddressOneOf(msg) {
  return this.test({
    name: "isAddressOneOf",
    exclusive: false,
    message: msg,
    test: function () {
      var isPreviousAddress = this.path.includes("previousAddress");
      var isFirstPreviousAddress = isPreviousAddress
        ? this.path.split(".")[0].includes("[0]")
        : false;
      var isUkAddress =
        isPreviousAddress && !isFirstPreviousAddress
          ? this.parent.country === "GB"
          : true;

      if (isUkAddress) {
        var name = this.parent["house-name"];
        var number = this.parent["house-number"];
        var flat = this.parent["flat-number"];
        return name || number || flat;
      }
      return true;
    }
  });
};

export const requiredForUKAddress = function requiredForUKAddress(msg) {
  return this.test({
    name: "requiredForUKAddress",
    exclusive: false,
    message: msg,
    test: function (value) {
      var firstPrev = this.path.split(".")[0].includes("[0]");
      var ukAddress = this.parent.country === "GB";
      return firstPrev || ukAddress ? value : true;
    }
  });
};

export const requiredForAddress2to5 = function requiredForAddress2to5(msg) {
  return this.test({
    name: "requiredForAddress2to5",
    exclusive: false,
    message: msg,
    test: function (value) {
      var firstPrev = this.path.split(".")[0].includes("[0]");
      return !firstPrev ? value : true;
    }
  });
};

export const requiredAfterCountry = function requiredAfterCountry(msg) {
  return this.test({
    name: "requiredAfterCountry",
    exclusive: false,
    message: msg,
    test: function (value) {
      var isPreviousAddress = this.path.includes("previousAddress");
      var isFirstPreviousAddress = isPreviousAddress
        ? this.path.split(".")[0].includes("[0]")
        : false;
      if (!isPreviousAddress || isFirstPreviousAddress) {
        return value;
      }
      return this.parent.country ? value : true;
    }
  });
};

export const areMoreAddressesRequired = function areMoreAddressesRequired(msg) {
  return this.test({
    name: "areMoreAddressesRequired",
    exclusive: false,
    message: msg,
    test: function (value) {

      var previousAddresses = this.parent.previousAddresses;
      var previousAddressCount = previousAddresses.length;
      var earliest;
      if (previousAddressCount > 0) {
        earliest = DateTime.fromJSDate(
          previousAddresses[previousAddressCount - 1]["date-moved"]
        );
      } else {
        earliest = DateTime.fromJSDate(value);
      }
      if (earliest.isValid && previousAddressCount < 5) {
        if (Interval.fromDateTimes(earliest, new Date()).length("years") < 3) {
          return this.createError({
            message: msg + earliest.toFormat("MMMM yyyy")
          });
        }
      }
      return true;
    }
  });
};

const isDateAfter = (dateOne, dateTwo) => {
  if (dateOne.isValid && dateTwo.isValid) {
    return dateOne > dateTwo;
  }
  // always return true for invalid date as this needs a different error.
  return true;
};

export const isAddressSequenceValid = function isAddressSequenceValid(msg) {
  return this.test({
    name: "isAddressSequenceValid",
    exclusive: false,
    message: msg,
    test: function (value) {
      var previousAddresses = this.parent.previousAddresses;
      var previousAddressCount = previousAddresses.length;
      if (previousAddressCount > 0) {
        // is previous address before current address.
        if (
          isDateAfter(
            DateTime.fromJSDate(value),
            DateTime.fromJSDate(previousAddresses[0]["date-moved"])
          )
        ) {
          // handle previous addresses.
          if (previousAddressCount > 1) {

            for (let i = 0; i < previousAddressCount; i++) {
              // don't check the last address in the array
              // it has already been compared with the previous one
              if (i !== previousAddressCount - 1) {

                // compare each address in the array
                // with the one after to make sure they are in chronological order
                if (
                  isDateAfter(
                    DateTime.fromJSDate(previousAddresses[i + 1]["date-moved"]),
                    DateTime.fromJSDate(previousAddresses[i]["date-moved"])
                  )
                ) {
                  // previous out of sequence
                  return false;
                }
              }
            }
            // all good
            return true;
          } else {
            // previous after current
            return true;
          }
        } else {
          // previous before current
          return false;
        }
      }
      // no previous
      return true;
    }
  });
};

export const isPersonalIncomeLessThanMaxValue = function isPersonalIncomeLessThanMaxValue(
  maxValue,
  msg
) {
  return this.test({
    name: "isPersonalIncomeLessThanMaxValue",
    exclusive: false,
    message: msg,
    test: function () {
      var input = parseInt(this.parent["personal-income"]);
      var max = maxValue;
      if (!isNaN(input)) {
        return input <= max;
      }
    }
  });
};

export const isNotCiiomPostcode = function isNotCiiomPostcode(msg) {
  return this.test({
    name: "isNotCiiomPostcode",
    exclusive: false,
    message: msg,
    test: function () {
      var postcode = this.parent["post-code"];
      if (postcode !== undefined) {
        return !["JE", "GY", "IM"].includes(
          postcode.toUpperCase().substring(0, 2)
        );
      } else return true;
    }
  });
};

export const requiredNotEmployee = function requiredNotEmployee(msg) {
  return this.test({
    name: "requiredNotEmployee",
    exclusive: false,
    message: msg,
    test: function (value) {
      var roleInbusiness = this.parent["role-in-business"];
      if (
        ["BUSINESS_OWNER", "SOLE_TRADER", "KEY_CONTROLLER"].includes(
          roleInbusiness
        )
      ) {
        return value;
      } else return true;
    }
  });
};

export const isAnOwner = function isAOwner(msg) {
  return this.test({
    name: "isAnOwner",
    exclusive: false,
    message: msg,
    test: function (value) {
      var residentialStatus = this.parent["residential-status"];
      return residentialStatus === "OWNER" ? value : true;
    }
  });
};

export const requiredWhenOverseasPayments = function requiredWhenOverseasPayments(
  msg
) {
  return this.test({
    name: "requiredWhenOverseasPayments",
    exclusive: false,
    message: msg,
    test: function (value) {
      return this.parent["overseas-radio"] === "yes" ? value : true;
    }
  });
};

export const requiredCountriesWhenOverseasPayments = function requiredCountriesWhenOverseasPayments(
  msg
) {
  return this.test({
    name: "requiredCountriesWhenOverseasPayments",
    exclusive: false,
    message: msg,
    test: function (value) {
      if (this.parent["overseas-radio"] === "yes") {
        if (value) {
          const additional = this.parent["overseas-country"];
          if (
            additional !== [] &&
            (additional[0] === "" || additional[1] === "")
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  });
};
