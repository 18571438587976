export default {
  pageTitle: "Employment details",
  pageIntro: "Next, we need to know your employment details.",
  employmentStatus: {
    heading: "Employment",
    label: "Employment status",
    error: "Select your employment status"
  },
  occupation: {
    label: "Occupation",
    error: "Select your occupation"
  },
  jobTitle: {
    label: "What is your job title?",
    errorBlank: "Tell us your job title",
    errorInvalid:
      "Enter your job title using only alphanumeric characters and basic punctuation"
  },
  companyName: {
    label: "Company name",
    errorBlank: "Enter the company name",
    errorMin: "Your company name must have at least 2 characters",
    errorInvalid:
      "Enter your company name using only alphanumeric characters and basic punctuation"
  },
  employmentStartDate: {
    label: "Employment start date",
    hint: "For example 07 1998.",
    errorBlank: "Enter when you started with your current employer",
    errorMin: "The date you entered is before your date of birth",
    errorMax: "The date you entered is in the future",
    errorInvalidMonth: "The month you entered is invalid",
    errorInvalidFormat: "Enter your employment start date in the format MM YYYY"
  },
  roleInBusiness: {
    label: "What is your role in this business?",
    errorBlank: "Tell us your role in this business",
    hint:
      "A Key controller is someone other than the Business owner with direct control over a business."
  },
  business: {
    type: {
      label: "Business type",
      errorBlank: "Select a business type"
    },
    category: {
      label: "Business category",
      errorBlank: "Select a business category"
    },
    subcategory: {
      label: "Business sub-category",
      errorBlank: "Select a business sub-category"
    }
  },
  companyAddress: {
    heading: "Company's address"
  },
  addressLine1: {
    label: "Address line 1",
    errorBlank: "Enter the first line of the address",
    errorInvalid:
      "Enter the address using only alphanumeric characters and basic punctuation"
  },
  addressLine2: {
    label: "Address line 2",
    errorBlank: "Enter the second line of the address",
    errorInvalid:
      "Enter the address using only alphanumeric characters and basic punctuation"
  },
  postcode: {
    label: "Postcode",
    errorBlank: "Enter your company’s address postcode",
    errorInvalid: "Enter a valid postcode"
  },
  atHome: {
    label: "Do you receive income from benefits payments?",
    errorBlank: "Tell us if you receive income from any benefits"
  },
  hoursWorked: {
    label: "Number of basic hours worked per week",
    errorBlank:
      "Number of basic hours worked per week must be a number between 0 and 99"
  },
  fixedTermContract: {
    label:
      "Has your contract been renewed at least once within last 12 months?",
    errorBlank:
      "Tell us if your contract has been renewed at least once within the last 12 months"
  },
  unemploymentBenefits: {
    label: "Do you receive unemployment benefit?",
    errorBlank: "Tell us if you receive unemployment benefit"
  },
  courseEndDate: {
    label: "Course end date",
    errorBlank: "Tell us your course end date",
    errorInvalidFormat: "Enter your course end date in the format DD MM YYYY",
    errorMin: "The date you entered is in the past"
  }
};
