import * as yup from "yup";
import {
  isBeforeDateOfBirth,
  isAddressOneOf,
  requiredForUKAddress,
  requiredForAddress2to5,
  requiredAfterCountry,
  isAddressSequenceValid,
  areMoreAddressesRequired,
  isPersonalIncomeLessThanMaxValue,
  isNotCiiomPostcode,
  requiredNotEmployee,
  isAnOwner,
  requiredWhenOverseasPayments,
  requiredCountriesWhenOverseasPayments
} from "./custom-validations.js";

yup.addMethod(yup.date, "isBeforeDateOfBirth", isBeforeDateOfBirth);
yup.addMethod(yup.string, "isAddressOneOf", isAddressOneOf);
yup.addMethod(yup.string, "requiredForUKAddress", requiredForUKAddress);
yup.addMethod(yup.string, "requiredForAddress2to5", requiredForAddress2to5);
yup.addMethod(yup.string, "requiredAfterCountry", requiredAfterCountry);
yup.addMethod(yup.date, "isAddressSequenceValid", isAddressSequenceValid);
yup.addMethod(yup.date, "areMoreAddressesRequired", areMoreAddressesRequired);
yup.addMethod(
  yup.string,
  "isPersonalIncomeLessThanMaxValue",
  isPersonalIncomeLessThanMaxValue
);
yup.addMethod(yup.string, "isNotCiiomPostcode", isNotCiiomPostcode);
yup.addMethod(yup.string, "requiredNotEmployee", requiredNotEmployee);
yup.addMethod(yup.string, "isAnOwner", isAnOwner);
yup.addMethod(
  yup.string,
  "requiredWhenOverseasPayments",
  requiredWhenOverseasPayments
);
yup.addMethod(
  yup.string,
  "requiredCountriesWhenOverseasPayments",
  requiredCountriesWhenOverseasPayments
);

export default yup;
