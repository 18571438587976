import React from "react";
import { WizardContext, ProgressIndicator } from "@piggybank/form";
import { withContext } from "@piggybank/core";

const StepTracker = ({ wizardContext }) => {
  const { currentPage, totalPages } = wizardContext;
  return (
    <ProgressIndicator
      current={currentPage - 1}
      total={totalPages - 2}
      marginBottom={5}
      renderStepText={({ current, total }) => (
        <>
          <strong>Application | </strong> Step {current} of {total}
        </>
      )}
    />
  );
};

// grab wizard context and pass it to component
export default withContext(WizardContext, wizardContext => ({ wizardContext }))(
  StepTracker
);
