export default {
  pageTitle: "Financial details",
  pageIntro: "Next, we need to know your income, outgoings and tax details.",
  sectionTitle: "Income",
  personalIncome: {
    label: "Personal annual income before tax",
    doorhangerTitle: "What should be included in annual income",
    doorhangerCopy: {
      Paragraph1: "You should include the annual income you receive before tax, National Insurance and any other deductions. This includes self-employed income and any pensions.",
      Paragraph2: "If you receive income from a zero hours' contract, please include your average earnings for the last 12 months with the same employer.",
      Paragraph3: "Please include any benefit income when guaranteed for the duration of the lending. These include, but are not limited to:",
      financialDetails: [
        "Universal Credit (not including Jobseeker's Allowance)",
        "Working Tax Credit or Child Tax Credit",
        "any maintenance or child support payments",
        "benefits for those with a disability or caring for a dependant",
      ],
      Paragraph4: "We may need to see evidence of these payments.",
    },
    errorBlank: "Enter your annual personal income",
    errorMin:
      "To apply for a loan you need a minimum annual earnings before tax of £10,000"
  },
  timeAtBank: {
    label: "When did you open your bank account?",
    hint: " We need this information for our credit checks.",
    errorBlank: "Tell us when you opened your bank account",
    errorInvalid:
      "Enter the date you opened your bank account in the format MM YYYY",
    errorMin:
      "You've entered a date that's before your birth date.  Check and re-enter",
    errorMax: "The date you entered is in the future"
  },
  affordability: {
    labelRadio:
      "Do you foresee any changes in your circumstances that may increase your outgoings or reduce your disposable income that would affect your ability to repay the borrowing you are applying for?",
    errorBlank: "Select whether you foresee any changes in your circumstances",
    doorhangerTitle: "Examples of change in circumstances",
    doorhangerExample1:
      "An increase in living costs such as a rent or mortgage repayments.",
    doorhangerExample2:
      "Changes in employment situation such as imminent retirement, maternity, paternity or extended leave.",
    doorhangerExample3:
      "A reduction in working hours, such as end of contract or zero hours contract."
  },

  aboutProperty: {
    sectionTitle: "About your property",
    sectionIntro: "We need to know more about your property."
  },

  marketValue: {
    label: "Market value of the property",
    hint:
      "If your property is valued at £1m or greater, please enter £999,999.",

    errorBlank: "Tell us the market value of your property"
  },
  mortgageLeftPay: {
    label: "Mortgage still outstanding (if any)",
    errorBlank:
      "Tell us the amount still outstanding on your mortgage. If this is not applicable to you, enter 0"
  },

  monthlyOutgoings: {
    monthlyOutgoingsTitle: "Monthly outgoings",
    label: "We need to know more about your monthly outgoings."
  },
  monthlyOutgoingsMortgage: {
    label: "Monthly mortgage or rent and secured loan payment",
    errorBlank:
      "Tell us the amount of your monthly mortgage, rent and secured loan payments. If this is not applicable to you, enter 0"
  },
  monthlyOutgoingsCouncil: {
    label: "Monthly council tax payment",
    errorBlank:
      "Tell us the amount of your monthly council tax. If this is not applicable to you, enter 0"
  },
  monthlyOutgoingsInsurance: {
    label: "Monthly life and other insurance payments",
    errorBlank:
      "Tell us the amount of your monthly life and other insurance payments. If this is not applicable to you, enter 0"
  },

  taxResidency: {
    sectionTitle: "Tax residency",
    expanderTitle: "Why do we need tax resident info?",
    expanderContentPara1:
      'National laws based on the requirements of the OECD Common Reporting Standard ("CRS") require Financial Institutions to collect information about an account holder\'s tax residence. This may be reported to the national tax authority if the account holder is tax resident outside the jurisdiction where they hold their account.',
    expanderContentPara2:
      "Under international agreements to exchange account information, the national tax authority may transfer this information to the tax authorities of other jurisdictions in which the account holder may be tax resident.",
    expanderContentPara3:
      "Each jurisdiction has its own rules for defining tax residence, but in general, tax residence is the jurisdiction in which you live. Special circumstances (such as studying abroad, working overseas, or extended travel) may cause you to be resident elsewhere, or resident in more than one jurisdiction at the same time (dual residency).",
    expanderContentPara4:
      "The jurisdiction/jurisdictions in which you pay income tax are therefore likely to be your jurisdiction/jurisdictions of tax residence. For more information about tax residency, please ask your tax adviser or visit the ",
    expanderLinkTitle: "OECD Automatic Exchange portal",
    expanderLinkHref: "https://www.oecd.org/tax/automatic-exchange",
    label: "Where are you a resident for tax purposes?",
    hint: "Your jurisdiction of tax residence.",
    ukAndOtherCountriesCopy:
      "Please select any other countries or jurisdictions where you are tax resident",
    nonUkCopy:
      "Please select any countries or jurisdictions where you are tax resident",
    labelAdditional: "Country or jurisdiction",
    placeholder: "Please select",
    errorBlank: "Tell us where you are resident for tax purposes",
    errorSelection:
      "Select any countries or jurisdictions where you are tax resident"
  }
};
