import React, { Component } from "react";
import {
  Paragraph,
  Footer,
  Masthead,
  Page,
  LogoMsGlobal,
  Row,
  Cell
} from "@piggybank/core";

class GenericPage extends Component {
  render() {
    const loanPurpose = this.props.context
      ? this.props.context.loanPurpose
      : "";
    return (
      <Page
        headerSlot={
          <Masthead
            logoSlot={
              <div style={{ display: "flex", height: 26 }}>
                <LogoMsGlobal />
              </div>
            }
            marginBottom={
              !this.props.requiresMastHead || loanPurpose === "" ? 5 : 0
            }
          />
        }
        footerSlot={
          <Footer textMap={{ footer: "" }}>
            <Row>
              <Cell offsetLarge={1}>
                <Paragraph marginBottom={0}>
                  &copy; Marks &amp; Spencer Financial Services plc{" "}
                  {new Date().getFullYear()}. All rights reserved.{" "}
                </Paragraph>
              </Cell>
            </Row>
          </Footer>
        }
      >
        {this.props.children}
      </Page>
    );
  }
}

export default GenericPage;
