import { sanitiseField } from "./utils";

export const afisCall = (values, sessionId) => {
  return {
    sessionId: sessionId,
    forename: sanitiseField(values.forename),
    surname: sanitiseField(values.surname),
    dateOfBirth: values["date-of-birth"],
    buildingNumber: sanitiseField(values["house-number"]),
    buildingName: sanitiseField(values["house-name"]),
    addressLine1: sanitiseField(values.address1),
    addressLine2: sanitiseField(values.address2),
    addressLine3: sanitiseField(values.address3),
    postcode: sanitiseField(values["post-code"]),
    country: values.country,
    emailAddress: sanitiseField(values.email),
    mobilePhone: values["mobile-number"].value.replace("+44", "0"),
    accountAddressCountry: "UK"
  };
};
