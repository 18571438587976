export const loanPurposeOptions = [
  {
    value: "VEHICLE",
    label: "Vehicle purchase"
  },
  {
    value: "HOLIDAY",
    label: "Holiday"
  },
  {
    value: "ANNUAL",
    label: "Annual commitments"
  },
  {
    value: "WEDDING",
    label: "Wedding"
  },
  {
    value: "HOUSE",
    label: "Home improvements"
  },
  {
    value: "DEBT",
    label: "Debt consolidation"
  },
  {
    value: "FURNITURE",
    label: "Furniture & Electrical"
  },
  {
    value: "OTHER",
    label: "Other"
  }
];
