import React, { Component } from "react";
import { Section, Row, Cell } from "@piggybank/core";
import {
  Label,
  Field,
  TextInput,
  FieldFeedback,
  Hint,
  Fieldset,
  Legend,
  DateInput
} from "@piggybank/form";

class OverseasAddress extends Component {
  trimField = (field, values) => {
    const parts = field.split(".");
    const value = values[parts[0]][parts[1]][parts[2]];
    if (value) {
      values[parts[0]][parts[1]][parts[2]] = value.trim();
    }
  };

  render() {
    const { values, content, path } = this.props;
    const pathDot = path ? `${path}.` : "";
    return (
      <Section aria-live="assertive" marginBottom={0}>
        <Field
          name={`${pathDot}address1`}
          data-bdd={`${pathDot}address1`}
          onBlur={(next, rest) => {
            this.trimField(`${pathDot}address1`, values);
            next(rest);
          }}
        >
          <Label>{content.address1.label}</Label>
          <Row>
            <Cell size={11} sizeMedium={7} sizeLarge={5}>
              <TextInput fullWidth maxLength={30} />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>
        <Field
          name={`${pathDot}address2`}
          data-bdd={`${pathDot}address2`}
          onBlur={(next, rest) => {
            this.trimField(`${pathDot}address2`, values);
            next(rest);
          }}
        >
          <Label>{content.address2.label}</Label>
          <Row>
            <Cell size={11} sizeMedium={7} sizeLarge={5}>
              <TextInput fullWidth maxLength={30} />
            </Cell>
          </Row>{" "}
          <FieldFeedback />
        </Field>

        <Field
          name={`${pathDot}address3`}
          data-bdd={`${pathDot}address3`}
          onBlur={(next, rest) => {
            this.trimField(`${pathDot}address3`, values);
            next(rest);
          }}
        >
          <Label>{content.address3.label}</Label>
          <Row>
            <Cell size={11} sizeMedium={7} sizeLarge={5}>
              <TextInput fullWidth required={false} maxLength={30} />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>

        <Fieldset name={`${pathDot}date-moved`}>
          <Legend marginBottom={1}>{content.moveDate.label}</Legend>
          <Hint marginBottom={1}>{content.moveDate.hint}</Hint>
          <DateInput format="YYYY-MM" />
          <FieldFeedback />
        </Fieldset>
      </Section>
    );
  }
}

export default OverseasAddress;
