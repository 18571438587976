export default (
  businessTypeCode,
  businessCatCode,
  businessSubCatCode,
  referenceData
) => {
  const businessArray = [];

  // DON'T TRY TO TRANSFORM INVALID DATA
  if (
    businessTypeCode &&
    businessCatCode &&
    (businessSubCatCode && typeof businessSubCatCode === "string") &&
    (referenceData && referenceData !== [])
  ) {
    // GET BUSINESS TYPE
    const types = Object.keys(referenceData);
    const typeValues = Object.values(referenceData);

    // TODO make this reusable
    for (var i = typeValues.length; i--; ) {
      if (types[i] === businessTypeCode) {
        businessArray.push(typeValues[i].label);

        break;
      }
    }

    // GET BUSINESS CATEGORY
    const businessCatIndex = types.indexOf(businessTypeCode);
    const businessCategories = Object.values(typeValues)[businessCatIndex];
    const businessCatType = Object.keys(businessCategories.category);
    const businessCatValues = Object.values(businessCategories.category);

    for (var j = businessCatValues.length; j--; ) {
      if (businessCatType[j] === businessCatCode) {
        businessArray.push(businessCatValues[j].label);

        break;
      }
    }

    // GET BUSINESS SUBCATEGORY
    const businessSubCatIndex = businessCatType.indexOf(businessCatCode);
    const businessSubCategories = Object.values(businessCatValues)[
      businessSubCatIndex
    ];
    const businessSubCatType = Object.keys(businessSubCategories.subcategory);
    const businessSubCatValues = Object.values(
      businessSubCategories.subcategory
    );

    for (var k = businessSubCatValues.length; k--; ) {
      if (businessSubCatType[k] === businessSubCatCode) {
        businessArray.push(businessSubCatValues[k]);

        break;
      }
    }
  }
  return businessArray;
};
