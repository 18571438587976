const randomNumber = max => {
  return ("0" + Math.floor(Math.random() * Math.floor(max))).slice(-2);
};

export const loanCalculator = {
  loanAmount: "1000",
  loanDuration: "12",
  defer: "yes"
};

export const purposeOfLoans = {
  existingCustomer: "yes",
  existingLoan: "yes",
  settlingExistingLoan: "no",
  "select-purpose": ["WEDDING"],
  apr: "2.9",
  monthlyRepayments: "91.29",
  monthlyRepaymentsDefer: "93.87",
  totalAmountPayable: "1095",
  totalAmountPayableDefer: "1,126.44"
};
export const personalDetails = {
  sourceCode: "LIQW",
  title: "MR",
  forename: "Anthony",
  middlenames: "Edward",
  surname: Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 15),
  "more-names": "yes",
  previousNames: [
    {
      previousForename: "Steve",
      previousSurname: "Rogers"
    },
    {
      previousForename: "Bruce",
      previousSurname: "Banner"
    },
    {
      previousForename: "Natasha",
      previousMiddlenames: "Alianovna",
      previousSurname: "Romanov"
    }
  ],
  "date-of-birth": `19${randomNumber(100)}-${randomNumber(13)}-${randomNumber(
    29
  )}`,
  gender: "M",
  "marital-status": "SINGLE",
  "number-of-dependants": "0",
  "country-of-birth": "GB",
  "country-of-residence": "GB",
  nationality: "GB",
  "additional-nationalities": ["US", "FR"]
};

export const contactDetails = {
  email: "loan@noexternalmail.hsbc.com",
  "mobile-number": {
    raw: "07700900000",
    value: "+447700900000"
  },
  "home-number": {
    raw: "01144960000",
    value: "+441144960000"
  },
  "work-number": {
    raw: "01144960000",
    value: "+441144960000"
  },
  "post-code": "LS98 1FD",
  "house-number": "40",
  address1: "Wakefield Road",
  address2: "Stourton",
  address3: "Leeds",
  "date-moved": "2018-11",
  previousAddresses: [
    {
      "post-code": "RG12 9FG",
      "house-number": "12",
      address1: "Picket Post Close",
      address2: "Bracknell",
      address3: "Berkshire",
      "date-moved": "2017-09"
    },
    {
      country: "GB",
      "post-code": "NW1 6XE",
      "house-number": "221",
      "flat-number": "B",
      address1: "Baker Street",
      address2: "London",
      "date-moved": "2017-05"
    },
    {
      country: "FR",
      address1: "35 Rue du Faubourg",
      address2: "75008 Paris",
      "date-moved": "2000-03"
    }
  ],
  "residential-status": "OWNER"
};

export const employmentDetails = {
  "employment-status": "PENSION"
};

export const financialDetails = {
  "personal-income": "10001",
  "time-at-bank": "2012-12",
  "market-value": "250000",
  "mortgage-left-pay": "70000",
  "monthly-outgoings-mortgage": "560",
  "monthly-outgoings-council": "102",
  "monthly-outgoings-insurance": "30",
  affordability: "yes",
  "tax-residency-type": "O",
  "tax-residencies": ["DE", "FR"]
};

export const setupPreferences = {
  bankDetailsCorrect: "Y",
  bankName: "Bobs bank",
  sortCode: "123456",
  accountNumber: "12345678",
  "account-holders-name": "Nick Fury",
  sourceOfIncome: "SALARY",
  additionalPayments: "no",
  "overseas-radio": "no"
};

export const review = {
  "email-confirm": true
};
