import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Section,
  Heading,
  Paragraph,
  Link,
  HorizontalRule,
  VisuallyHidden,
  IconEdit,
  FormLayout,
  Callout
} from "@piggybank/core";
import {
  Form,
  Navigation,
  Checkbox,
  Field,
  FieldFeedback,
  FormFeedback
} from "@piggybank/form";
import content from "../../i18n/content/review";
import { reviewSchema } from "../../shared/validation-schema.js";
import { link, view } from "../../shared/analytics/tealium/events";
import { TRACK_REVIEW_PAGE } from "../../shared/analytics/pageData";
import Timer from "../../components/Timer";
import { utagGlobal, validationErrors } from "../../shared/analytics/tealium";
import "./Review.css";
import StepTracker from "../../components/StepTracker/StepTracker";
import { loanPurposeOptions } from "../../i18n/lookups/reviewLoanPurpose"; //TODO replace this with a call to Ref Data API
import ReviewLoanDetails from "../../organisms/ReviewLoanDetails";
import ReviewPersonalDetails from "../../organisms/ReviewPersonalDetails";
import ReviewContactDetails from "../../organisms/ReviewContactDetails";
import ReviewEmploymentDetails from "../../organisms/ReviewEmploymentDetails";
import ReviewFinancialDetails from "../../organisms/ReviewFinancialDetails";
import ReviewSetUpPreferences from "../../organisms/ReviewSetUpPreferences";
import formatTitle from "../../shared/transformations/formatTitle";

const eventTaggingConstants = {
  page_name: TRACK_REVIEW_PAGE.page_name,
  page_url: TRACK_REVIEW_PAGE.page_url,
  page_type: utagGlobal.page_type,
  funnel_name: utagGlobal.funnel_name,
  funnel_step: TRACK_REVIEW_PAGE.funnel_step,
  funnel_step_name: TRACK_REVIEW_PAGE.funnel_step_name
};
class Review extends Component {
  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_REVIEW_PAGE));
    document.title = formatTitle(content.pageTitle);
  }
  render() {
    const { navigateTo } = this.props;
    return (
      <FormLayout>
        <div className="review">
          <Section marginBottom={7}>
            <StepTracker />
            <Heading level={1} data-bdd="page-title">
              {content.pageTitle}
            </Heading>
            <Paragraph lead data-bdd="page-intro">
              {content.pageIntro}
            </Paragraph>
            <Callout type="information" data-bdd="apr-callout">
              {content.callout}
            </Callout>
          </Section>
          <Form
            initialValues={{ "email-confirm": false }}
            validationSchema={reviewSchema}
            onError={errors => {
              link({
                ...eventTaggingConstants,
                event_category: "error",
                event_action: "field validation",
                event_content: validationErrors(errors.errors)
              });
            }}
          >
            {({ values }) => (
              <>
                <FormFeedback />
                {/* TODO check navigateTo when putting back in - page numbers are different */}
                <ReviewLoanDetails
                  loanPurposeOptions={loanPurposeOptions}
                  values={values}
                  onClick={() => {
                    link({
                      ...eventTaggingConstants,
                      event_category: "content",
                      event_action: "button",
                      event_content: "loan details: edit"
                    });
                    navigateTo(0);
                  }}
                />
                <ReviewPersonalDetails
                  values={values}
                  onClick={() => {
                    link({
                      ...eventTaggingConstants,
                      event_category: "content",
                      event_action: "button",
                      event_content: "personal details: edit"
                    });
                    navigateTo(2);
                  }}
                />
                <ReviewContactDetails
                  values={values}
                  onClick={() => {
                    link({
                      ...eventTaggingConstants,
                      event_category: "content",
                      event_action: "button",
                      event_content: "contact details: edit"
                    });
                    navigateTo(3);
                  }}
                />

                <ReviewEmploymentDetails
                  values={values}
                  onClick={() => {
                    link({
                      ...eventTaggingConstants,
                      event_category: "content",
                      event_action: "button",
                      event_content: "employment details: edit"
                    });
                    navigateTo(4);
                  }}
                />

                <ReviewFinancialDetails
                  values={values}
                  onClick={() => {
                    link({
                      ...eventTaggingConstants,
                      event_category: "content",
                      event_action: "button",
                      event_content: "financial details: edit"
                    });
                    navigateTo(5);
                  }}
                />

                <ReviewSetUpPreferences
                  values={values}
                  onClick={() => {
                    link({
                      ...eventTaggingConstants,
                      event_category: "content",
                      event_action: "button",
                      event_content: "loan setup & preferences: edit"
                    });
                    navigateTo(6);
                  }}
                />

                <div className="heading">
                  <div className="heading heading--header">
                    <Heading
                      marginBottom={3}
                      level={2}
                      data-bdd="email-confirm"
                    >
                      {content.emailConfirmation.title}
                    </Heading>
                  </div>
                  <div className="heading heading--editlink">
                    <Link
                      strong
                      data-bdd="email-confirm-edit"
                      id="edit-email"
                      onClick={() => {
                        navigateTo(3);
                      }}
                      elementType="button"
                      type="button"
                      buttonPadding
                      iconSlotRight={<IconEdit inline />}
                    >
                      {content.editButton}
                      <VisuallyHidden>
                        {content.emailConfirmation.hiddenCopy}
                      </VisuallyHidden>
                    </Link>
                  </div>
                </div>
                <HorizontalRule />
                {content.emailConfirmation.hint}
                <Section data-bdd="email-confirm" marginBottom={6}>
                  <Field name="email-confirm" data-bdd="email-confirm">
                    <Checkbox>
                      {content.emailConfirmation.label}
                      <span style={{ wordBreak: "break-word" }}>
                        {values.email}
                      </span>
                    </Checkbox>
                    <FieldFeedback />
                  </Field>
                </Section>
                <Section data-bdd="cra-fraud-prevention-copy">
                  <Heading level={2}>{content.craFraudHeading}</Heading>
                  <Heading
                    level={4}
                    marginBottom={3}
                    role="heading"
                    aria-level="3"
                  >
                    {content.cra.heading}
                  </Heading>
                  <Paragraph marginBottom={3}>
                    {content.cra.paragraph1}
                  </Paragraph>
                  <Paragraph marginBottom={3}>
                    {content.cra.paragraph2}
                  </Paragraph>
                  <Paragraph>{content.cra.paragraph3}</Paragraph>
                  <Heading
                    level={4}
                    marginBottom={3}
                    role="heading"
                    aria-level="3"
                  >
                    {content.fraud.heading}
                  </Heading>
                  <Paragraph marginBottom={3}>
                    {content.fraud.paragraph1}
                  </Paragraph>
                  <Paragraph marginBottom={3}>
                    {content.fraud.paragraph2Pre}
                    <Link external href={content.fraud.url}>
                      {content.fraud.link}
                    </Link>
                    {content.fraud.paragraph2Post}
                  </Paragraph>
                  <Paragraph marginBottom={6}>
                    {content.fraud.paragraph3}
                  </Paragraph>
                </Section>
                <HorizontalRule />
                <Section>
                  <Navigation id="next" />
                </Section>
              </>
            )}
          </Form>
          <Timer pageTags={eventTaggingConstants} />
        </div>
      </FormLayout>
    );
  }
}

Review.propTypes = {
  values: PropTypes.object,
  navigateTo: PropTypes.func
};

export default Review;
