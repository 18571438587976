import React, { Component } from "react";
import {
  Section,
  Heading,
  Paragraph,
  Button,
  IconError,
  FormLayout
} from "@piggybank/core";
import content from "../../i18n/content/applicationTimeout.js";
import { TRACK_APPLICATION_TIMEOUT } from "../../shared/analytics/pageData.js";
import { view } from "../../shared/analytics/tealium/events";
import { utagGlobal } from "../../shared/analytics/tealium";
import { AppConsumer } from "../../shared/appContext.js";
import formatTitle from "../../shared/transformations/formatTitle";

class ApplicationTimeout extends Component {
  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_APPLICATION_TIMEOUT));

    document.title = formatTitle(content.pageTitle);
  }

  handleRedirect(queryString) {
    window.location = "/" + queryString;
  }

  render() {
    return (
      <FormLayout>
        <Section>
          <div style={{ margin: "3px 0px 3px -2px", padding: "3px" }}>
            <IconError width="36" data-bdd="error-icon" />
          </div>
          <Heading level={1} data-bdd="page-title" marginBottom={5}>
            {content.pageTitle}
          </Heading>
          <Paragraph lead data-bdd="page-intro">
            {content.pageIntro}
          </Paragraph>
          <Paragraph data-bdd="page-copy" marginBottom={6}>
            {content.pageCopy}
          </Paragraph>
          <AppConsumer.Consumer>
            {val => {
              return (
                <Button
                  data-bdd="start-again-button"
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    this.handleRedirect(val.queryString);
                  }}
                >
                  {content.buttonLabel}
                </Button>
              );
            }}
          </AppConsumer.Consumer>
        </Section>
      </FormLayout>
    );
  }
}

export default ApplicationTimeout;
