export default [
  {
    value: "U",
    label: "UK only"
  },
  {
    value: "O",
    label: "UK and other countries or jurisdictions"
  },
  {
    value: "N",
    label: "Non UK countries or jurisdictions"
  }
];
