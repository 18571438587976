import React, { Component } from "react";
import {
  Fieldset,
  FieldFeedback,
  Legend,
  YesNoRadio,
  Hint
} from "@piggybank/form";

class RadioButton extends Component {
  render() {
    const { content, name } = this.props;
    return (
      <>
        <Fieldset name={name} marginBottom={4} data-bdd={name}>
          <Legend data-bdd={name + "-label"} marginBottom={1}>
            {content.label}
          </Legend>
          {this.props.hintText && <Hint>{content.hint}</Hint>}
          <YesNoRadio data-bdd={name + "-radio"} />
          <FieldFeedback marginTop={1} />
        </Fieldset>
      </>
    );
  }
}

export default RadioButton;
