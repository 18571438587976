export default {
  descriptiveText: "Please verify your account details",
  accountHolderName: {
    label: "Account holder name(s)"
  },
  accountDetails: {
    sortCode: {
      label: "Sort code",
      errorBlank: "Enter a valid sort code",
      errorInvalid: "Enter a valid sort code"
    },
    accountNumber: {
      label: "Account number",
      errorBlank: "Enter a valid account number"
    },
    errorFailed:
      "We're sorry, we are unable to verify your account details. We will contact you as part of setting up your new loan.",

    BDVCallError: {
      errorInvalid:
        "We're sorry, we are unable to verify your account details. Please try again.",
      errorFailed:
        "We're sorry, we are unable to verify your account details. We will contact you as part of setting up your new loan."
    }
  },
  detailsVerified: "Account details verified",
  detailsNotVerified: "Verify your bank details before continuing",
  detailsInvalid: "Enter a valid sort code and account number"
};
