import { title } from "../transformations";

export default values => {
  return (
    title(values.title) +
    " " +
    values.forename +
    " " +
    isNotBlank(values.middlenames) +
    values.surname
  );
};

const isNotBlank = name => {
  return name !== undefined ? name + " " : "";
};
