import { countryName } from "../transformations";

export default (address, country) => {
  const fields = [
    "house-number",
    "flat-number",
    "house-name",
    "address1",
    "address2",
    "address3",
    "post-code"
  ];
  var formatted = "";
  fields.forEach(field => {
    formatted += format(address[field]);
  });
  country
    ? (formatted += countryName(country))
    : (formatted = formatted.substring(0, formatted.length - 2));
  return formatted;
  
};

const format = field => {
  if (field === "") {
    return "";
  } else if (field !== undefined) {
    return field + ", ";
    } else {
    return "";
         }
  }
      

