import React, { Component } from "react";
import { Button, Spinner, VisuallyHidden } from "@piggybank/core";
import "./FindAddressButton.css";

class FindAddressButton extends Component {
  render() {
    const { loading, clickHandler, path, content, disabled } = this.props;
    const pathDot = path ? `${path}.` : "";
    return (
      <div aria-live="polite">
        <div
          className={`findAddressButton ${loading ? " spinnerHidden" : ""}`}
          id={`${pathDot}findAddressButton`}
        >
          <Button
            data-bdd="find-address-button"
            secondary={true}
            type="button"
            disabled={disabled}
            onClick={clickHandler}
          >
            {content.button}
            <VisuallyHidden> {content.buttonHidden}</VisuallyHidden>
          </Button>
        </div>
        <div
          className={`findAddressSpinner ${loading ? "" : " spinnerHidden"}`}
          id={`${pathDot}findAddressSpinner`}
        >
          <Spinner data-bdd="find-address-spinner" />
          <p>
            {content.spinner}
            <VisuallyHidden>{content.spinnerHidden}</VisuallyHidden>
          </p>
        </div>
      </div>
    );
  }
}

export default FindAddressButton;
