import React, { Component, Fragment } from "react";
import { Row, Cell } from "@piggybank/core";
import { Field, Label, Select, FieldFeedback } from "@piggybank/form";
import businessType from "../../i18n/lookups/businessType";

let categories;
let categoryValues;
let types;
let typeValues;

const businessTypeOptions = () => {
  let options = [];
  types.forEach((key, index) => {
    options.push(new optionsItem(key, typeValues[index].label));
  });
  return options;
};

const businessCategoryOptions = type => {
  let options = [];
  let selected =
    types.indexOf(type) !== -1 ? typeValues[types.indexOf(type)].category : "";
  categories = Object.keys(selected);
  categoryValues = Object.values(selected);
  categories.forEach((key, index) => {
    options.push(new optionsItem(key, categoryValues[index].label));
  });
  return options;
};

const businessSubCategoryOptions = chosen => {
  let options = [];
  let subCategory =
    categories.indexOf(chosen) !== -1
      ? categoryValues[categories.indexOf(chosen)].subcategory
      : "";
  let subKeys = Object.keys(subCategory);
  let subValues = Object.values(subCategory);
  subKeys.forEach((key, index) => {
    options.push(new optionsItem(key, subValues[index]));
  });
  return options;
};

class optionsItem {
  constructor(value, label) {
    this.value = value;
    this.label = label;
  }
}

class BusinessType extends Component {
  constructor(props) {
    super(props);
    const { values } = props;
    types = Object.keys(businessType);
    typeValues = Object.values(businessType);
    this.state = {
      type: values["business-type"],
      category: values["business-category"],
      categories: businessCategoryOptions(values["business-type"]),
      subcategories: businessSubCategoryOptions(values["business-category"])
    };
  }

  componentDidUpdate() {
    const { values, setFieldValue } = this.props;
    if (this.state.type !== values["business-type"]) {
      this.setState({
        type: values["business-type"],
        categories: businessCategoryOptions(values["business-type"]),
        subcategories: []
      });
      setFieldValue((values["business-category"], "", true));
      setFieldValue((values["business-subcategory"], "", true));
      values["business-category"] = "";
      values["business-subcategory"] = "";
    }
    if (this.state.category !== values["business-category"]) {
      this.setState({
        category: values["business-category"],
        subcategories: businessSubCategoryOptions(values["business-category"])
      });
      setFieldValue((values["business-subcategory"], "", true));
      values["business-subcategory"] = "";
    }
  }

  render() {
    const { content } = this.props;
    return (
      <Fragment>
        <Field name="business-type" data-bdd="business-type">
          <Label data-bdd="business-type-label">{content.type.label}</Label>
          <Row>
            <Cell sizeMedium={7} sizeLarge={5}>
              <Select
                data-bdd="business-type-select"
                fullWidth
                options={businessTypeOptions()}
                placeholder="Please select"
              />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>
        <Field name="business-category" data-bdd="business-category">
          <Label data-bdd="business-category-label">
            {content.category.label}
          </Label>
          <Row>
            <Cell sizeMedium={7} sizeLarge={5}>
              <Select
                data-bdd="business-category-select"
                fullWidth
                options={this.state.categories}
                placeholder="Please select"
              />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>
        <Field name="business-subcategory" data-bdd="business-subcategory">
          <Label data-bdd="business-subcategory-label">
            {content.subcategory.label}
          </Label>
          <Row>
            <Cell sizeMedium={7} sizeLarge={5}>
              <Select
                data-bdd="business-subcategory-select"
                fullWidth
                options={this.state.subcategories}
                placeholder="Please select"
              />
            </Cell>
          </Row>
          <FieldFeedback />
        </Field>
      </Fragment>
    );
  }
}

export default BusinessType;
