import React from "react";
import {
  Grid,
  Row,
  Cell,
  Heading,
  Paragraph,
  IconVehicle,
  IconCalendar,
  IconHome,
  IconCard,
  IconTravel
} from "@piggybank/core";
import "./LoanMasthead.css";
import content from "../../i18n/content/loanMasthead.js";
import { valueToLabel } from "../../shared/analytics/tealium";
import { loanPurpose } from "../../i18n/lookups/loanPurpose";
import { displayCurrency } from "../../shared/transformations";
import WeddingIcon from "../../atoms/icons/wedding";
import FurnitureIcon from "../../atoms/icons/furniture";
import IncomeIcon from "../../atoms/icons/income";

const retrieveLoanMastheadCopy = (purpose, amount) => {
  const borrowingAmount =
    amount !== "" ? "borrowing " + displayCurrency(amount) : "";
  const purposeOfLoan =
    purpose !== "" ? valueToLabel(purpose, loanPurpose) : "";
  return borrowingAmount + purposeOfLoan;
};

const LoanMasthead = props => {
  const loanPurpose =
    props.context.loanPurpose !== "" ? props.context.loanPurpose[0] : "";

  const loanAmount =
    props.context.loanAmount !== "" ? props.context.loanAmount : "";

  return (
    <>
      {loanPurpose !== "" && (
        <div
          id="loanMasthead"
          className="loanMasthead"
          style={{ display: "block" }}
        >
          <Grid>
            <Row>
              <Cell sizeMedium={10} offsetLarge={1}>
                <div className="loanMasthead__inner">
                  <div className="loanMasthead__icon">
                    {loanPurpose === "VEHICLE" && <IconVehicle width="2.8em" />}
                    {loanPurpose === "HOLIDAY" && <IconTravel width="2.8em" />}
                    {loanPurpose === "ANNUAL" && <IconCalendar width="2.8em" />}
                    {loanPurpose === "WEDDING" && <WeddingIcon width="2.8em" />}
                    {loanPurpose === "HOUSE" && <IconHome width="2.8em" />}
                    {loanPurpose === "DEBT" && <IconCard width="2.8em" />}
                    {loanPurpose === "FURNITURE" && (
                      <FurnitureIcon width="2.8em" />
                    )}
                    {loanPurpose === "OTHER" && <IncomeIcon width="2.8em" />}
                  </div>

                  <div className="loanMasthead__description">
                    <Paragraph id="loanMasthead__intro" marginBottom={0}>
                      {content.pageIntro}
                    </Paragraph>
                    <Heading
                      id="loanMasthead__heading"
                      level={3}
                      marginBottom={0}
                    >
                      {content.pageSubheading1}{" "}
                      {retrieveLoanMastheadCopy(loanPurpose, loanAmount)}
                    </Heading>
                  </div>
                </div>
              </Cell>
            </Row>
          </Grid>
        </div>
      )}
    </>
  );
};

export default LoanMasthead;
