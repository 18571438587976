import React, { Fragment, Component } from "react";
import { Section, Row, Cell } from "@piggybank/core";
import {
  Label,
  Field,
  TextInput,
  FieldFeedback,
  Hint,
  Fieldset,
  Radio,
  Legend,
  DateInput
} from "@piggybank/form";
import FindAddressButton from "../../components/FindAddressButton";
import buttonContent from "../../i18n/content/findAddressButton";
import FindAddressHint from "../../components/FindAddressHint";
import axios from "axios";
import { sanitiseField } from "../../shared/utils";
import residentialStatus from "../../i18n/lookups/residentialStatus";

class UKAddress extends Component {
  constructor(props) {
    super(props);
    const { values, path } = props;
    this.state = {
      loading: false,
      timerFinished: false,
      apiResponseReceived: false,
      addressFound: false,
      pafError: false,
      showAddress: this.showAddress(values, path),
      initialEntry: true
    };
  }

  componentDidUpdate() {
    if (this.state.timerFinished && this.state.apiResponseReceived) {
      this.setState({ loading: !this.state.loading });
      this.setState({ apiResponseReceived: !this.state.apiResponseReceived });
      this.setState({ timerFinished: !this.state.timerFinished });
      this.setState({ showAddress: true });
      this.setState({ initialEntry: false });
    }
  }

  showAddress = (values, path) => {
    var address = values.address1;
    let index = path ? path.split(".")[1] : undefined;
    if (index) {
      address = values.previousAddresses[index].address1;
    }
    return address;
  };

  updateAddress = (
    values,
    postCode,
    address1,
    address2,
    address3,
    previousAddress,
    index
  ) => {
    if (previousAddress) {
      values[previousAddress][index]["post-code"] = postCode;
      this.props.setFieldValue(
        (values[previousAddress][index]["post-code"], postCode, true)
      );
      values[previousAddress][index]["address1"] = address1;
      this.props.setFieldValue(
        (values[previousAddress][index]["address1"], address1, true)
      );
      values[previousAddress][index]["address2"] = address2;
      this.props.setFieldValue(
        (values[previousAddress][index]["address2"], address2, true)
      );
      values[previousAddress][index]["address3"] = address3;
      this.props.setFieldValue(
        (values[previousAddress][index]["address3"], address3, true)
      );
      values[previousAddress][index]["house-number"] = undefined;
      values[previousAddress][index]["house-name"] = undefined;
      values[previousAddress][index]["flat-number"] = undefined;
    } else {
      values["post-code"] = postCode;
      this.props.setFieldValue((values["post-code"], postCode, true));
      values.address1 = address1;
      this.props.setFieldValue((values.address1, address1, true));
      values.address2 = address2;
      this.props.setFieldValue((values.address2, address2, true));
      values.address3 = address3;
      this.props.setFieldValue((values.address3, address3, true));
      values["house-number"] = undefined;
      values["house-name"] = undefined;
      values["flat-number"] = undefined;
    }
  };

  callPafApi = (values, pathDot) => {
    const previousAddress = pathDot ? pathDot.split(".")[0] : "";
    const index = pathDot ? pathDot.split(".")[1] : "";
    const validPostcode = /^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}$/;
    let postCode = pathDot
      ? values[previousAddress][index]["post-code"]
      : values["post-code"];
    postCode = postCode !== undefined && postCode !== null ? postCode : "";
    postCode = postCode.match(validPostcode)
      ? postCode.toUpperCase()
      : postCode.replace(/\s/g, "").toUpperCase();
    if (postCode.match(validPostcode)) {
      const data = { postcode: postCode.replace(/\s/g, "") };
      axios({
        url: process.env.REACT_APP_PAF,
        method: "post",
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "X-HSBC-Channel-Id": "WEB",
          "X-HSBC-Chnl-CountryCode": "GB",
          "X-HSBC-Chnl-Group-Member": "MSRF",
          "X-HSBC-Locale": "en_GB"
        },
        validateStatus: status => {
          return status === 200 || status === 400;
        }
      })
        .then(response => {
          const address1 = sanitiseField(response.data.customerAddressLine1);
          const address2 = sanitiseField(response.data.customerAddressLine2);
          const address3 = sanitiseField(response.data.customerAddressLine3);
          this.updateAddress(
            values,
            postCode,
            address1,
            address2,
            address3,
            previousAddress,
            index
          );
          if (address1 || address2 || address3) {
            this.setState({ addressFound: true });
          } else {
            this.setState({ addressFound: false });
          }
        })
        .catch(() => this.setState({ pafError: true }))
        .then(() => this.setState({ apiResponseReceived: true }));
    } else {
      this.updateAddress(values, postCode, "", "", "", previousAddress, index);
      this.setState({ addressFound: false, apiResponseReceived: true });
    }
  };

  timer = time => {
    setTimeout(() => {
      this.setState({ timerFinished: !this.state.timerFinished });
    }, time);
  };

  clickHandler = async (values, pathDot) => {
    this.setState({
      loading: !this.state.loading,
      pafError: false,
      addressFound: false
    });
    this.timer(2000);
    this.callPafApi(values, pathDot);
    document.getElementById(`${pathDot}post-code-field`).focus();
    document.getElementById(`${pathDot}post-code-field`).click();
  };

  isOptional = values => {
    var name = values["house-name"];
    var number = values["house-number"];
    var flat = values["flat-number"];
    return !(name || number || flat);
  };

  trimField = (field, values) => {
    let value;
    let parts = field.split(".");
    if (parts.length === 3) {
      value = values[parts[0]][parts[1]][parts[2]];
      if (value) {
        values[parts[0]][parts[1]][parts[2]] = value.trim();
      }
    } else {
      value = values[field];
      if (value) {
        values[field] = value.trim();
      }
    }
  };

  render() {
    const { values, content, path, showResStatus } = this.props;
    const pathDot = path ? `${path}.` : "";
    let sizeSmall = path ? 11 : 12;
    return (
      <Section aria-live="assertive" marginBottom={0}>
        <Field
          name={`${pathDot}post-code`}
          data-bdd={`${pathDot}post-code`}
          onBlur={(next, rest) => {
            this.trimField(`${pathDot}post-code`, values);
            next(rest);
          }}
        >
          <Label>{content.postCode.label}</Label>
          <Section id="findAddressSection" marginBottom={0}>
            <TextInput fullWidth />
            <FindAddressButton
              path={path}
              content={buttonContent}
              loading={this.state.loading}
              clickHandler={e => {
                e.stopPropagation();
                this.clickHandler(values, pathDot);
              }}
            />
          </Section>
          <FieldFeedback />
        </Field>

        <FindAddressHint state={this.state} path={path} />

        {this.state.showAddress && (
          <Fragment>
            <Field
              name={`${pathDot}house-number`}
              data-bdd={`${pathDot}house-number`}
              onBlur={(next, rest) => {
                this.trimField(`${pathDot}house-number`, values);
                next(rest);
              }}
            >
              <Label>{content.houseNumber.label}</Label>
              <TextInput
                fullWidth
                style={{ display: "block", width: 190 }}
                required={this.isOptional(values)}
                maxLength={5}
              />
              <FieldFeedback />
            </Field>

            <Field
              name={`${pathDot}flat-number`}
              data-bdd={`${pathDot}flat-number`}
              onBlur={(next, rest) => {
                this.trimField(`${pathDot}flat-number`, values);
                next(rest);
              }}
            >
              <Label>{content.flatNumber.label}</Label>
              <TextInput
                fullWidth
                style={{ display: "block", width: 190 }}
                required={this.isOptional(values)}
                maxLength={16}
              />
              <FieldFeedback />
            </Field>
            <Field
              name={`${pathDot}house-name`}
              data-bdd={`${pathDot}house-name`}
              onBlur={(next, rest) => {
                this.trimField(`${pathDot}house-name`, values);
                next(rest);
              }}
            >
              <Label>{content.houseName.label}</Label>
              <TextInput
                fullWidth
                style={{ display: "block", width: 290 }}
                required={this.isOptional(values)}
                maxLength={26}
              />
              <FieldFeedback />
            </Field>
            <Field
              name={`${pathDot}address1`}
              data-bdd={`${pathDot}address1`}
              onBlur={(next, rest) => {
                this.trimField(`${pathDot}address1`, values);
                next(rest);
              }}
            >
              <Label>{content.address1.label}</Label>
              <Row>
                <Cell size={sizeSmall} sizeMedium={7} sizeLarge={5}>
                  <TextInput fullWidth maxLength={30} />
                </Cell>
              </Row>
              <FieldFeedback />
            </Field>
            <Field
              name={`${pathDot}address2`}
              data-bdd={`${pathDot}address2`}
              onBlur={(next, rest) => {
                this.trimField(`${pathDot}address2`, values);
                next(rest);
              }}
            >
              <Label>{content.address2.label}</Label>
              <Row>
                <Cell size={sizeSmall} sizeMedium={7} sizeLarge={5}>
                  <TextInput fullWidth maxLength={30} />
                </Cell>
              </Row>{" "}
              <FieldFeedback />
            </Field>

            <Field
              name={`${pathDot}address3`}
              data-bdd={`${pathDot}address3`}
              onBlur={(next, rest) => {
                this.trimField(`${pathDot}address3`, values);
                next(rest);
              }}
            >
              <Label>{content.address3.label}</Label>
              <Row>
                <Cell size={sizeSmall} sizeMedium={7} sizeLarge={5}>
                  <TextInput fullWidth required={false} maxLength={30} />
                </Cell>
              </Row>
              <FieldFeedback />
            </Field>

            {showResStatus === true && (
              <Fieldset name={`${pathDot}residential-status`} marginBottom={4}>
                <Legend marginBottom={1} id="residential-status-label">
                  {content.residentialStatus.label}
                </Legend>
                {residentialStatus.map((r, i) => (
                  <Radio
                    data-bdd={`residential-status-option-${i}`}
                    value={r.value}
                    key={r.value}
                  >
                    {r.label}
                  </Radio>
                ))}
                <FieldFeedback marginTop={1} />
              </Fieldset>
            )}

            <Fieldset name={`${pathDot}date-moved`}>
              <Legend marginBottom={1}>{content.moveDate.label}</Legend>
              <Hint marginBottom={1}>{content.moveDate.hint}</Hint>
              <DateInput format="YYYY-MM" />
              <FieldFeedback />
            </Fieldset>
          </Fragment>
        )}
      </Section>
    );
  }
}

export default UKAddress;
