import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "babel-polyfill";

/* eslint-env browser */
import content from "../../i18n/content/setUpPreferences.js";
import { setUpPreferencesSchema } from "../../shared/validation-schema.js";
import Account from "../../organisms/Account";
import { AppConsumer } from "../../shared/appContext";

import { link, view } from "../../shared/analytics/tealium/events";
import { TRACK_LOAN_SETUP } from "../../shared/analytics/pageData.js";
import {
  utagGlobal,
  validationErrors
} from "../../shared/analytics/tealium.js";

import {
  Section,
  Heading,
  HorizontalRule,
  Paragraph,
  FormLayout
} from "@piggybank/core";
import Timer from "../../components/Timer";
import {
  Checkbox,
  Form,
  Field,
  FormFeedback,
  Navigation
} from "@piggybank/form";
import StepTracker from "../../components/StepTracker/StepTracker.js";
import formatTitle from "../../shared/transformations/formatTitle";

const initialValues = {
  sortCode: "",
  accountNumber: "",
  "marketing-preferences": false,
  bankDetailsCorrect: "N"
};

const eventTaggingConstants = {
  page_name: TRACK_LOAN_SETUP.page_name,
  page_url: TRACK_LOAN_SETUP.page_url,
  page_type: utagGlobal.page_type,
  funnel_step: TRACK_LOAN_SETUP.funnel_step,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_LOAN_SETUP.funnel_step_name
};

const eventTagging = values => {
  if (values["marketing-preferences"] === true) {
    link({
      ...eventTaggingConstants,
      event_category: "content",
      event_action: "checkbox",
      event_content: "marketing preferences: yes"
    });
  }
};

class SetUpPreferences extends Component {
  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_LOAN_SETUP));
    document.title = formatTitle(content.pageTitle);
  }

  render() {
    return (
      <FormLayout>
        <div className="SetUpPreferences">
          <Section marginBottom={7}>
            <StepTracker />
            <Heading level={1}>{content.pageTitle}</Heading>
            <Paragraph lead>{content.pageIntro}</Paragraph>
            <HorizontalRule />
          </Section>
          <Form
            initialValues={initialValues}
            validationSchema={setUpPreferencesSchema}
            // test custom validation
            validate={values => {
              let errors = {};
              if (values.bankDetailsCorrect === "N" && this.context.BDV < 2) {
                errors.bankDetailsCorrect = content.bankDetails.errorBlank;
              }
              return errors;
            }}
            onError={errors => {
              link({
                ...eventTaggingConstants,
                event_category: "error",
                event_action: "field validation",
                event_content: validationErrors(errors.errors)
              });
            }}
            onSubmit={(next, rest) => {
              eventTagging(rest.values);
              next(rest);
            }}
          >
            {({ values, validateForm }) => (
              <Fragment>
                <FormFeedback />

                <Account
                  content={content}
                  values={values}
                  validateForm={validateForm}
                />
                <Section marginBottom={5}>
                  <Heading level={2} data-bdd="marketing-preferences-heading">
                    {content.marketingPreferences.sectionTitle}
                  </Heading>

                  <Section
                    data-bdd="marketing-preferences-copy"
                    marginBottom={0}
                  >
                    <Paragraph marginBottom={3}>
                      {content.marketingPreferences.paragraph}
                    </Paragraph>

                    <Section data-bdd="marketing-preferences" marginBottom={0}>
                      <Field
                        name="marketing-preferences"
                        data-bdd="marketing-preferences"
                        id="marketing-preferences"
                      >
                        <Checkbox>
                          {content.marketingPreferences.label}
                        </Checkbox>
                      </Field>
                    </Section>
                  </Section>
                </Section>
                <Section>
                  <HorizontalRule />
                  <Navigation
                    textMap={{ previous: "Back", next: "Continue" }}
                    id="next"
                    onNext={(next, rest) => {
                      if (this.context.BDVVerified !== true) {
                        this.context.BDVVerified = false;
                      }
                      this.context.Continue = true;
                      next(rest);
                    }}
                  />
                </Section>
              </Fragment>
            )}
          </Form>
          <Timer pageTags={eventTaggingConstants} />
        </div>
      </FormLayout>
    );
  }
}
SetUpPreferences.propTypes = {
  values: PropTypes.object,
  navigateTo: PropTypes.func
};
SetUpPreferences.displayName = "SetUpPreferences";
SetUpPreferences.contextType = AppConsumer;

export default SetUpPreferences;
