
import { isNotEmptyObject } from "../utils";

export default previousNames => {
  const names = [];
  for (var i = 0; i < previousNames.length; i++) {
    if (isNotEmptyObject(previousNames[i])) {
      names.push(
        previousNames[i].previousForename +
        " " +
        isNotBlank(previousNames[i].previousMiddlenames) +
        previousNames[i].previousSurname
      );
    }
  }
  return names;
};

const isNotBlank = name => {
  return name !== undefined ? name + " " : "";
};
