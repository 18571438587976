import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Section,
  Heading,
  Reveal,
  Paragraph,
  HorizontalRule,
  Row,
  Cell,
  FormLayout
} from "@piggybank/core";
import {
  Form,
  Field,
  Label,
  Select,
  FieldFeedback,
  FormFeedback,
  Navigation,
  NumberInput
} from "@piggybank/form";
import { employmentSchema } from "../../shared/validation-schema.js";
import content from "../../i18n/content/employmentDetails.js";
import RadioButton from "../../organisms/RadioButton";
import Employed from "../../organisms/Employed";
import { link, view } from "../../shared/analytics/tealium/events";
import { utagGlobal, validationErrors } from "../../shared/analytics/tealium";
import { TRACK_EMPLOYMENT_DETAILS } from "../../shared/analytics/pageData.js";
import Timer from "../../components/Timer";
import { removeFields } from "../../shared/utils";
import StepTracker from "../../components/StepTracker/StepTracker.js";
import formatTitle from "../../shared/transformations/formatTitle";
import { employmentStatus } from "../../i18n/lookups/employmentStatus";

const initialValues = {
  "employment-status": "",
  "fixed-term-contract": "",
  occupation: "",
  "job-title": "",
  "company-name": "",
  "employment-start-date": "",
  "role-in-business": "",
  "business-type": "",
  "business-category": "",
  "business-subcategory": "",
  "address-line-1": "",
  "address-line-2": "",
  postcode: "",
  hoursWorked: "",
  "income-from-benefits": ""
};

const reviewData = values => {
  if (!isEmployedStatus(values["employment-status"])) {
    removeFields(values, employedFields);
  }
  if (!isBusinessOwner(values["role-in-business"])) {
    removeFields(values, ownerFields);
  }
  if (!isAtHomeStatus(values["employment-status"])) {
    removeFields(values, atHomeFields);
  }
  if (!isFixedTermContract(values["employment-status"])) {
    removeFields(values, fixedTermFields);
  }
  if (!hoursWorkedNeeded(values["employment-status"])) {
    removeFields(values, hoursWorkedNeededFields);
  }
};

const hoursWorkedNeeded = status => ["PART", "SELF"].includes(status);
const isEmployedStatus = status =>
  ["FULL", "PART", "SELF", "FIXED"].includes(status);
const isAtHomeStatus = status => ["HOME"].includes(status);
const isFixedTermContract = status => ["FIXED"].includes(status);
const isBusinessOwner = role =>
  ["BUSINESS_OWNER", "SOLE_TRADER", "KEY_CONTROLLER"].includes(role);

const employedFields = [
  "occupation",
  "job-title",
  "role-in-business",
  "company-name",
  "employment-start-date",
  "address-line-1",
  "address-line-2",
  "postcode"
];
const ownerFields = [
  "business-type",
  "business-category",
  "business-subcategory"
];
const atHomeFields = ["income-from-benefits"];
const fixedTermFields = ["fixed-term-contract"];
const hoursWorkedNeededFields = ["hoursWorked"];

const eventTaggingConstants = {
  page_name: TRACK_EMPLOYMENT_DETAILS.page_name,
  page_url: TRACK_EMPLOYMENT_DETAILS.page_url,
  funnel_name: utagGlobal.funnel_name,
  funnel_step_name: TRACK_EMPLOYMENT_DETAILS.funnel_step_name
};

class EmploymentDetails extends Component {
  componentDidMount() {
    view(Object.assign(utagGlobal, TRACK_EMPLOYMENT_DETAILS));
    document.title = formatTitle(content.pageTitle);
  }

  render() {
    const context = this.props.context;
    return (
      <FormLayout>
        <div className="employment-details">
          <Section marginBottom={7}>
            <StepTracker />
            <Heading level={1}>{content.pageTitle}</Heading>
            <Paragraph lead marginBottom={4}>
              {content.pageIntro}
            </Paragraph>
            <HorizontalRule />
          </Section>

          <Form
            initialValues={initialValues}
            validationSchema={employmentSchema}
            onError={errors => {
              link({
                ...eventTaggingConstants,
                event_category: "error",
                event_action: "field validation",
                event_content: validationErrors(errors.errors)
              });
            }}
            onSubmit={(next, rest) => {
              link({
                ...eventTaggingConstants,
                event_category: "content",
                event_action: "radio",
                event_content: `employment status: ${rest.values[
                  "employment-status"
                ].toLowerCase()}`
              });
              next(rest);
            }}
          >
            {({ values, setFieldValue }) => (
              <>
                <FormFeedback />

                <Section marginBottom={3}>
                  <Heading level={2} marginBottom={4}>
                    {content.employmentStatus.heading}
                  </Heading>
                  <Field name="employment-status" data-bdd="employment-status">
                    <Label>{content.employmentStatus.label}</Label>
                    <Row>
                      <Cell sizeMedium={7} sizeLarge={5}>
                        <Select
                          fullWidth
                          options={employmentStatus}
                          placeholder="Please select"
                        />
                      </Cell>
                    </Row>
                    <FieldFeedback />
                  </Field>
                </Section>

                <Reveal duration={400}>
                  {hoursWorkedNeeded(values["employment-status"]) && (
                    <Field name="hoursWorked" data-bdd="hoursWorked">
                      <Label data-bdd="hoursWorked-label">
                        {content.hoursWorked.label}
                      </Label>
                      <NumberInput
                        data-bdd="hoursWorked-input"
                        style={{ maxWidth: 190 }}
                        maxLength={2}
                        required
                      />
                      <FieldFeedback />
                    </Field>
                  )}

                  {values["employment-status"] === "FIXED" && (
                    <RadioButton
                      content={content.fixedTermContract}
                      name="fixed-term-contract"
                    />
                  )}
                  {isEmployedStatus(values["employment-status"]) && (
                    <Employed
                      content={content}
                      values={values}
                      setFieldValue={setFieldValue}
                      context={context}
                    />
                  )}
                  {isAtHomeStatus(values["employment-status"]) && (
                    <RadioButton
                      content={content.atHome}
                      name="income-from-benefits"
                    />
                  )}
                </Reveal>

                <Section>
                  <HorizontalRule />
                  <Navigation
                    id="next"
                    textMap={{ previous: "Back", next: "Continue" }}
                    onNext={(next, rest) => {
                      reviewData(values);
                      next(rest);
                    }}
                  />
                </Section>
              </>
            )}
          </Form>
          <Timer pageTags={eventTaggingConstants} />
        </div>
      </FormLayout>
    );
  }
}
EmploymentDetails.propTypes = {
  values: PropTypes.object
};
export default EmploymentDetails;
