import {
  loanCalculator,
  personalDetails,
  contactDetails,
  employmentDetails,
  financialDetails,
  setupPreferences,
  purposeOfLoans
} from "./developmentValues";

export default [
  {
    index: 0,
    label: "Purpose of Loan",
    loadData: []
  },
  {
    index: 1,
    label: "Loan Calculator",
    loadData: { ...purposeOfLoans }
  },
  {
    index: 2,
    label: "Personal Details",
    loadData: { ...purposeOfLoans, ...loanCalculator }
  },
  {
    index: 3,
    label: "Contact Details",
    loadData: { ...purposeOfLoans, ...personalDetails, ...loanCalculator }
  },
  {
    index: 4,
    label: "Employment Details",
    loadData: {
      ...purposeOfLoans,
      ...personalDetails,
      ...contactDetails,
      ...loanCalculator
    }
  },
  {
    index: 5,
    label: "Financial Details",
    loadData: {
      ...loanCalculator,
      ...purposeOfLoans,
      ...personalDetails,
      ...contactDetails,
      ...employmentDetails
    }
  },
  {
    index: 6,
    label: "Setup and preferences",
    loadData: {
      ...loanCalculator,
      ...purposeOfLoans,
      ...personalDetails,
      ...contactDetails,
      ...employmentDetails,
      ...financialDetails
    }
  },
  {
    index: 7,
    label: "Review",
    loadData: {
      ...loanCalculator,
      ...purposeOfLoans,
      ...personalDetails,
      ...contactDetails,
      ...employmentDetails,
      ...financialDetails,
      ...setupPreferences
    }
  }
];
