import React from "react";

const FurnitureIcon = props => (
  <span style={{ marginBottom: props.marginBottom }}>
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      version="1.1"
      viewBox="0 0 18 18"
      width={props.width}
      height={props.width}
    >
      <g
        transform="translate(.25)"
        stroke="#000"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          strokeWidth="1.7"
          x=".605"
          y=".665"
          width="16.26"
          height="16.007"
          rx="2"
        />
        <path
          d="M8.97 1.084v15.17M.548 15.532v2.077"
          strokeWidth="1.7"
          strokeLinecap="square"
        />
        <path d="M16.916 17.699v-7" strokeWidth="1.6" strokeLinecap="square" />
        <circle fill="#000" fillRule="nonzero" cx="5.538" cy="8.488" r="1" />
        <circle fill="#000" fillRule="nonzero" cx="12.04" cy="8.488" r="1" />
      </g>
    </svg>
  </span>
);

export default FurnitureIcon;
