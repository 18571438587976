export default {
  pageTitle: "Application successful",
  pageHeading: "Congratulations, your application has been successful",
  offerSubHeading: "M&S Personal Loan",

  loanAmount: {
    label: "Your loan amount",
    copy: "£"
  },

  apr: {
    label: "Annual Percentage Rate (APR)",
    copy: "% APR"
  },
  termOfLoan: {
    label: "Term of loan",
    copy: "months"
  },
  firstRepayment: {
    label: "First repayment"
  },
  subsequentRepayments: {
    label: "Subsequent repayments"
  },
  totalAmount: {
    label: "Total amount payable"
  },
  defer: {
    label: "Defer first repayment for 3 months "
  },

  infoBanner:
    "Take the time to review this offer. Please note the rate you've been offered has changed from the representative example.",

  pageSubHeading: "What happens next?",
  pageCopy1: "We'll send you a link to your M&S loan documentation by e-mail. Simply click on the link to review and download your pre-contractual information and M&S loan agreement, and to sign your agreement. This will return a signed copy to M&S bank.",
  pageCopy2: "Once we receive it, we will sign the M&S loan agreement within 3 working days following any further checks. Once signed by us, we'll transfer the money to your account within 5 working days.",
  pageCopy3: "If you have any questions please ",
  pageLink: "contact us",
  pageLinkUrl: "https://bank.marksandspencer.com/explore/contact-us/",
  buttonLabel: "Go to M&S home page"
};
