import React, { Component } from "react";
import {
  Section,
  Heading,
  HorizontalRule,
  Link,
  IconEdit,
  PlaybackKey,
  PlaybackList,
  PlaybackValue,
  VisuallyHidden
} from "@piggybank/core";
import content from "../../i18n/content/review.js";
import employmentDetailsContent from "../../i18n/content/employmentDetails.js";
import {
  occupation,
  employmentStatus,
  monthYearDateTransform,
  companyAddress,
  capitalise,
  business,
  businessRole
} from "../../shared/transformations";
import employmentDetails from "../../i18n/content/employmentDetails.js";
import businesscategory from "../../i18n/lookups/businessType";

const isEmployedStatusFull = status =>
  ["FULL", "PART", "SELF", "FIXED"].includes(status);
const inEmployedStatusNotFull = status => ["PART", "SELF"].includes(status);
const fixedContract = status => ["FIXED"].includes(status);
const isAtHomeStatus = status => ["HOME"].includes(status);
const notEmployee = role =>
  ["BUSINESS_OWNER", "SOLE_TRADER", "KEY_CONTROLLER"].includes(role);

class ReviewEmploymentDetails extends Component {
  render() {
    const { values, onClick } = this.props;
    const typeOfBusiness = business(
      values["business-type"],
      values["business-category"],
      values["business-subcategory"],
      businesscategory
    );

    return (
      <Section data-bdd="employment-details-review">
        <div className="heading">
          <div className="heading heading--header">
            <Heading
              marginBottom={3}
              level={2}
              data-bdd="employment-details-heading"
            >
              {employmentDetailsContent.pageTitle}
            </Heading>
          </div>
          <div className="heading heading--editlink">
            <Link
              elementType="button"
              type="button"
              buttonPadding
              data-bdd="employment-details-edit"
              id="employment-details-edit"
              onClick={onClick}
              strong
              iconSlotRight={<IconEdit inline />}
            >
              {content.editButton}
              <VisuallyHidden>
                {employmentDetailsContent.pageTitle}
              </VisuallyHidden>
            </Link>
          </div>
        </div>
        <HorizontalRule />
        <PlaybackList marginBottom={7} id="employmentDetailsSection">
          <PlaybackKey>
            {employmentDetailsContent.employmentStatus.label}
          </PlaybackKey>
          <PlaybackValue marginBottom={3} id="employment-status">
            {employmentStatus(values["employment-status"])}
          </PlaybackValue>

          {isEmployedStatusFull(values["employment-status"]) && (
            <>
              <PlaybackKey>
                {employmentDetailsContent.occupation.label}
              </PlaybackKey>
              <PlaybackValue id="occupation" marginBottom={3}>
                {occupation(values.occupation)}
              </PlaybackValue>
              {fixedContract(values["employment-status"]) && (
                <>
                  <PlaybackKey>
                    {employmentDetails.fixedTermContract.label}
                  </PlaybackKey>
                  <PlaybackValue id="fixed-term-contract" marginBottom={3}>
                    {capitalise(values["fixed-term-contract"])}
                  </PlaybackValue>
                </>
              )}
              {inEmployedStatusNotFull(values["employment-status"]) && (
                <>
                  <PlaybackKey>
                    {employmentDetails.hoursWorked.label}
                  </PlaybackKey>
                  <PlaybackValue id="hoursWorked" marginBottom={3}>
                    {values["hoursWorked"]}
                  </PlaybackValue>
                </>
              )}
              <PlaybackKey>
                {employmentDetailsContent.jobTitle.label}
              </PlaybackKey>
              <PlaybackValue id="jobTitle" marginBottom={3}>
                {values["job-title"]}
              </PlaybackValue>
              <PlaybackKey>
                {employmentDetailsContent.companyName.label}
              </PlaybackKey>
              <PlaybackValue id="company-name" marginBottom={3}>
                {values["company-name"]}
              </PlaybackValue>
              <PlaybackKey>
                {employmentDetailsContent.employmentStartDate.label}
              </PlaybackKey>
              <PlaybackValue id="employment-start-date" marginBottom={3}>
                {monthYearDateTransform(values["employment-start-date"])}
              </PlaybackValue>
              <PlaybackKey>
                {employmentDetailsContent.roleInBusiness.label}
              </PlaybackKey>
              <PlaybackValue id="role-in-business" marginBottom={3}>
                {businessRole(values["role-in-business"])}
              </PlaybackValue>
              {notEmployee(values["role-in-business"]) && (
                <>
                  <PlaybackKey>
                    {employmentDetailsContent.business.type.label}
                  </PlaybackKey>

                  <PlaybackValue id="business-type" marginBottom={3}>
                    {typeOfBusiness[0]}
                  </PlaybackValue>

                  <PlaybackKey>
                    {employmentDetailsContent.business.category.label}
                  </PlaybackKey>
                  <PlaybackValue id="business-category" marginBottom={3}>
                    {typeOfBusiness[1]}
                  </PlaybackValue>
                  <PlaybackKey>
                    {employmentDetailsContent.business.subcategory.label}
                  </PlaybackKey>
                  <PlaybackValue id="business-subcategory" marginBottom={3}>
                    {typeOfBusiness[2]}
                  </PlaybackValue>
                </>
              )}
              <PlaybackKey>
                {employmentDetailsContent.companyAddress.heading}
              </PlaybackKey>
              <PlaybackValue id="company-address" marginBottom={3}>
                {companyAddress([
                  values["address-line-1"],
                  values["address-line-2"],
                  values["postcode"]
                ])}
              </PlaybackValue>
            </>
          )}

          {isAtHomeStatus(values["employment-status"]) && (
            <>
              <PlaybackKey>{employmentDetailsContent.atHome.label}</PlaybackKey>
              <PlaybackValue id="income-from-benefits" marginBottom={3}>
                {capitalise(values["income-from-benefits"])}
              </PlaybackValue>
            </>
          )}
        </PlaybackList>
      </Section>
    );
  }
}

export default ReviewEmploymentDetails;
