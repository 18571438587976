import React, { Component } from "react";
import URLSearchParams from "@ungap/url-search-params";

const AppContext = React.createContext();

export class AppProvider extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      loanPurpose: "",
      loanAmount: "",
      loanDuration: "",
      monthlyRepayments: "",
      apr: "",
      totalAmountPayable: "",
      BDV: 0
    };
    this.state = Object.assign(initialState, props);
  }

  componentWillMount() {
    // Take the url params
    const urlParams = new URLSearchParams(window.location.search.toLowerCase());

    const source = urlParams.get("source");

    // Check source and update state
    source
      ? source.length === 4
        ? this.setState({
            sourceCodeValid: true,
            queryString: window.location.search,
            sourceCode: source,
            paramsSet: true
          })
        : this.setState({
            sourceCodeValid: false,
            queryString: window.location.search,
            sourceCode: source,
            paramsSet: false
          })
      : this.setState({
          sourceCodeValid: false,
          queryString: window.location.search,
          sourceCode: source,
          paramsSet: false
        });
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export const AppConsumer = AppContext;
