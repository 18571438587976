import timeAt from "./timeAt";
import { sanitiseField } from "../utils";

export default (address, movedOut) => {
  // UK address unless explicitly specified.
  var type = address.country ? (address.country === "GB" ? "U" : "O") : "U";
  var countryCode = address.country ? address.country : "GB";
  return {
    addressType: type,
    houseNumber: sanitiseField(address["house-number"]),
    flatNumber: sanitiseField(address["flat-number"]),
    houseName: sanitiseField(address["house-name"]),
    addressLine1: sanitiseField(address.address1),
    addressLine2: sanitiseField(address.address2),
    addressLine3: sanitiseField(address.address3),
    postcode: sanitiseField(address["post-code"]),
    countryCode: countryCode,
    timeAtAddress: timeAt(address["date-moved"], movedOut)
  };
};
