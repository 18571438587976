export const displayCurrency = value => {
  if (value !== undefined) {
    return "£" + value.replace(/\B(?=(\d{3})+(?!\d))/g, "$&,");
  } else return "";
};

export const decimalPlaces = value => {
  const amount = value.toString();
  if (amount.includes(".")) {
    if (amount.split(".")[1].length !== 2) {
      return amount + "0";
    } else return amount;
  } else {
    return amount + ".00";
  }
};
