export const TRACK_PURPOSE_OF_LOAN = {
  funnel_step: "",
  funnel_step_name: "loan purpose",
  page_name: "pws:loans:personal loan:application:loan purpose",
  page_url: "/loans/personal-loan/application/loan-purpose",
  form_field_3: "",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_LOAN_CALCULATOR = {
  funnel_step: "",
  funnel_step_name: "calculate loan",
  page_name: "pws:loans:personal loan:application:calculate loan",
  page_url: "/loans/personal-loan/application/calculate-loan",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_PERSONAL_DETAILS = {
  funnel_step: 1,
  funnel_step_name: "personal details",
  page_name: "pws:loans:personal loan:application:personal details",
  page_url: "/loans/personal-loan/application/personal-details",
  event_category: "application",
  event_action: "start",
  form_field_3: "",
  event_content: ""
};

export const TRACK_CONTACT_DETAILS = {
  funnel_step: 2,
  funnel_step_name: "contact details",
  page_name: "pws:loans:personal loan:application:contact details",
  page_url: "/loans/personal-loan/application/contact-details",
  form_field_3: "",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_EMPLOYMENT_DETAILS = {
  funnel_step: 3,
  funnel_step_name: "employment details",
  page_name: "pws:loans:personal loan:application:employment details",
  page_url: "/loans/personal-loan/application/employment-details",
  form_field_3: "",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_FINANCIAL_DETAILS = {
  funnel_step: 4,
  funnel_step_name: "financial details",
  page_name: "pws:loans:personal loan:application:financial details",
  page_url: "/loans/personal-loan/application/financial-details",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_LOAN_SETUP = {
  funnel_step: 5,
  funnel_step_name: "loan setup",
  page_name: "pws:loans:personal loan:application:loan setup",
  page_url: "/loans/personal-loan/application/loan-setup",
  form_field_3: "",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_REVIEW_PAGE = {
  funnel_step: 6,
  funnel_step_name: "review details",
  page_name: "pws:loans:personal loan:application:review details",
  page_url: "/loans/personal-loan/application/review-details",
  form_field_3: "",
  event_category: "",
  event_action: "",
  event_content: ""
};

export const TRACK_PROCESSING_PAGE = {
  funnel_step: 7,
  funnel_step_name: "processing",
  page_name: "pws:loans:personal loan:application:processing",
  page_url: "/loans/personal-loan/application/processing",
  event_category: "application",
  event_action: "submit"
};

export const TRACK_ACCEPT_PAGE = {
  funnel_step: 8,
  funnel_step_name: "application complete",
  page_name: "pws:loans:personal loan:application:complete",
  page_url: "/loans/personal-loan/application/complete",
  event_category: "application",
  event_action: "submit",
  event_content: "completed"
};

export const TRACK_DECLINE_PAGE = {
  funnel_step: 8,
  funnel_step_name: "declined",
  page_name: "pws:loans:personal loan:application:declined",
  page_url: "/loans/personal-loan/application/declined",
  event_category: "application",
  event_action: "submit",
  event_content: "declined"
};

export const TRACK_APPLICATION_COMPLETE = {
  funnel_step: 8,
  funnel_step_name: "referred",
  page_name: "pws:loans:personal loan:application:referred",
  page_url: "/loans/personal-loan/application/referred",
  event_category: "application",
  event_action: "submit",
  event_content: "referred"
};

export const TRACK_APPLICATION_TIMEOUT = {
  funnel_step: "",
  funnel_step_name: "application timeout",
  page_name: "pws:loans:personal loan:application:timeout",
  page_url: "/loans/personal-loan/application/timeout	",
  form_field_3: "",
  page_type: "error",
  event_category: "error",
  event_action: "application",
  event_content: "timeout"
};

export const TRACK_TECHNICAL_ERROR = {
  funnel_step: "",
  funnel_step_name: "technical error",
  page_name: "pws:loans:personal loan:application:technical error",
  page_url: "/loans/personal-loan/application/technical-error",
  page_type: "error",
  form_field_3: "",
  event_category: "error",
  event_action: "system",
  event_content: "technical error"
};
