import React, { Component } from "react";
import {
  Section,
  Heading,
  HorizontalRule,
  UnorderedList,
  ListItem,
  Link,
  VisuallyHidden,
  IconEdit,
  PlaybackKey,
  PlaybackList,
  PlaybackValue
} from "@piggybank/core";
import content from "../../i18n/content/review.js";
import financialDetailsContent from "../../i18n/content/financialDetails.js";
import {
  countryName,
  monthYearDateTransform,
  displayCurrency,
  capitalise
} from "../../shared/transformations";

class ReviewFinancialDetails extends Component {
  render() {
    const { values, onClick } = this.props;
    const homeOwner = values["residential-status"] === "OWNER" ? true : false;
    return (
      <Section data-bdd="financial-details-review">
        <div className="heading">
          <div className="heading heading--header">
            <Heading marginBottom={3} level={2} id="financial-details-heading">
              {content.financialDetails.heading}
            </Heading>
          </div>
          <div className="heading heading--editlink">
            <Link
              elementType="button"
              type="button"
              buttonPadding
              data-bdd="financial-details-edit"
              id="financial-details-edit"
              onClick={onClick}
              strong
              iconSlotRight={<IconEdit inline />}
            >
              {content.editButton}
              <VisuallyHidden>
                {content.financialDetails.heading}
              </VisuallyHidden>
            </Link>
          </div>
        </div>
        <HorizontalRule />
        <Heading level={3} marginBottom={3}>
          {content.financialDetails.subSectionOne}
        </Heading>
        <PlaybackList id="financialDetailsSectionOne">
          <PlaybackKey>
            {financialDetailsContent.personalIncome.label}
          </PlaybackKey>
          <PlaybackValue marginBottom={3} id="personalIncome">
            {displayCurrency(values["personal-income"])}
          </PlaybackValue>
          <PlaybackKey>{financialDetailsContent.timeAtBank.label}</PlaybackKey>
          <PlaybackValue id="timeAtBank" marginBottom={3}>
            {monthYearDateTransform(values["time-at-bank"])}
          </PlaybackValue>
          <PlaybackKey>
            {financialDetailsContent.affordability.labelRadio}
          </PlaybackKey>
          <PlaybackValue id="affordability" marginBottom={4}>
            {capitalise(values.affordability)}
          </PlaybackValue>

          {homeOwner && (
            <>
              <Heading level={3} marginBottom={3}>
                {financialDetailsContent.aboutProperty.sectionTitle}
              </Heading>
              <PlaybackKey>
                {financialDetailsContent.marketValue.label}
              </PlaybackKey>
              <PlaybackValue marginBottom={3}>
                {displayCurrency(values["market-value"])}
              </PlaybackValue>
              <PlaybackKey>
                {financialDetailsContent.mortgageLeftPay.label}
              </PlaybackKey>
              <PlaybackValue>
                {displayCurrency(values["mortgage-left-pay"])}
              </PlaybackValue>
            </>
          )}

          <Heading level={3} marginBottom={3}>
            {financialDetailsContent.monthlyOutgoings.monthlyOutgoingsTitle}
          </Heading>
          <PlaybackKey>
            {financialDetailsContent.monthlyOutgoingsMortgage.label}
          </PlaybackKey>
          <PlaybackValue marginBottom={3}>
            {displayCurrency(values["monthly-outgoings-mortgage"])}
          </PlaybackValue>

          <PlaybackKey>
            {financialDetailsContent.monthlyOutgoingsCouncil.label}
          </PlaybackKey>
          <PlaybackValue marginBottom={3}>
            {displayCurrency(values["monthly-outgoings-council"])}
          </PlaybackValue>

          <PlaybackKey>
            {financialDetailsContent.monthlyOutgoingsInsurance.label}
          </PlaybackKey>
          <PlaybackValue>
            {displayCurrency(values["monthly-outgoings-insurance"])}
          </PlaybackValue>

          <Heading level={3} marginBottom={3}>
            {content.financialDetails.subSectionTwo}
          </Heading>
          <PlaybackKey>
            {financialDetailsContent.taxResidency.label}
          </PlaybackKey>

          <PlaybackValue id="taxResidency" marginBottom={7}>
            <UnorderedList type="none" spacing={1}>
              {(values["tax-residency-type"] === "O" ||
                values["tax-residency-type"] === "U") && (
                <ListItem>United Kingdom</ListItem>
              )}

              {values["tax-residencies"] !== undefined &&
                values["tax-residencies"].length > 0 && (
                  <>
                    {values["tax-residencies"].map(function(countryCode, i) {
                      return (
                        <ListItem key={i + 1}>
                          {countryName(countryCode)}
                        </ListItem>
                      );
                    })}
                  </>
                )}
            </UnorderedList>
          </PlaybackValue>
        </PlaybackList>
      </Section>
    );
  }
}
export default ReviewFinancialDetails;
