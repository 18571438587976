import { yesNo, timeAt, additionalTaxCountries } from "../transformations";
export default values => {
  const ukResident =
    values["tax-residency-type"] === "U" ||
    values["tax-residency-type"] === "O";

  const primaryTax = ukResident ? "GB" : values["tax-residencies"][0];
  const additional = values["tax-residencies"];
  if (!ukResident) {
    additional.shift();
  }

  return {
    applicantSalary: values["personal-income"],
    sustainabilityYN: yesNo(values.affordability),
    whenBankAccountOpened: timeAt(values["time-at-bank"]),
    propertyMarketValue: values["market-value"]
      ? values["market-value"]
      : undefined,
    outstandingMortgage: values["mortgage-left-pay"]
      ? values["mortgage-left-pay"]
      : undefined,
    monthlyMortgageOrRentPayment: values["monthly-outgoings-mortgage"],
    monthlyCouncilTaxPayment: values["monthly-outgoings-council"],
    monthlyInsurancePayments: values["monthly-outgoings-insurance"],
    resCntryCodeForTax: primaryTax,
    additionalTaxResidencies: additionalTaxCountries(additional)
  };
};
