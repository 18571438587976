export default {
  pageTitle: "Loan set-up and preferences",
  pageIntro:
    "Next, we need to set up your new loan and know how you'd like to be contacted",
  bankDetails: {
    sectionTitle: "Set up a new loan",
    sectionIntro:
      "Tell us the account we should pay the loan into. This account will also be used to make the loan repayments. Ensure you meet the following criteria:",
    criteria1: "The account to be debited is in your name.",
    criteria2: "The account is a personal account and not a business account.",
    criteria3:
      "You are the only person required to authorise Direct Debits from this bank account.",
    label: "Please tell us your sort code and account number.",
    errorBlank: "Verify your bank details before continuing"
  },
  marketingPreferences: {
    sectionTitle: "Marketing preferences",
    paragraph:
      "We, other members of the HSBC Group, members of Marks and Spencer Group and other organisations approved by Marks and Spencer plc (which may include insurance companies) would like to keep you informed about special offers, products and services available from the HSBC Group, the Marks and Spencer Group and selected third parties by post, telephone, email, mobile text and picture messaging.",
    label: "Tick this box if you wish to receive this information",
    review: "Do you wish to receive marketing information?"
  }
};
