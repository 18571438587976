import React, { Component } from "react";
import {
  Heading,
  Paragraph,
  Button,
  Row,
  Link,
  Grid,
  Cell,
  Callout
} from "@piggybank/core";
import content from "../content/accept.js";
import "./Accept.css";
import { view } from "../../shared/analytics/tealium/events";
import { utagGlobal, valueToLabel } from "../../shared/analytics/tealium";
import { TRACK_ACCEPT_PAGE } from "../../shared/analytics/pageData.js";
import { loanPurpose } from "../lookups/loanPurpose.js";
import {
  decimalPlaces,
  displayCurrency
} from "../../shared/transformations/currency.js";
import formatTitle from "../../shared/transformations/formatTitle";

class Accept extends Component {
  componentDidMount() {
    TRACK_ACCEPT_PAGE.application_id = this.props.applicationId;
    TRACK_ACCEPT_PAGE.term = this.props.acceptFields.loanTerm;
    TRACK_ACCEPT_PAGE.start_amount = this.props.context.monthlyRepayments;
    TRACK_ACCEPT_PAGE.interest_rate = this.props.acceptFields.loanApr;
    TRACK_ACCEPT_PAGE.amount = this.props.acceptFields.firstRepaymentAmount;
    TRACK_ACCEPT_PAGE.currency_code = "gbp";
    view(Object.assign(utagGlobal, TRACK_ACCEPT_PAGE));
    document.title = formatTitle(content.pageTitle);
  }

  handleRedirect() {
    window.location = "https://bank.marksandspencer.com/";
  }

  render() {
    const response = this.props.acceptFields;
    return (
      <div className="accept">
        <Grid>
          <Row>
            <Cell sizeLarge={7} offsetLarge={1} style={{}}>
              <Heading level={2} data-bdd="offer-sub-heading">
                {content.offerSubHeading}
                {valueToLabel(this.props.context.loanPurpose[0], loanPurpose)}
              </Heading>
              <ul className="loan-info">
                <li>
                  <span className="loan-info__title">
                    {content.loanAmount.label}
                  </span>
                  <span>{displayCurrency(response.loanAmount.toString())}</span>
                </li>
                <li>
                  <span className="loan-info__title">{content.apr.label}</span>
                  <span data-bdd="apr-amount">
                    {response.loanApr.toFixed(1)}
                    {content.apr.copy}
                  </span>
                </li>
                <li>
                  <span className="loan-info__title">
                    {content.termOfLoan.label}
                  </span>
                  <span>
                    {response.loanTerm} {content.termOfLoan.copy}
                  </span>
                </li>
                <li>
                  <span className="loan-info__title">
                    {content.firstRepayment.label}
                  </span>
                  <span>
                    {displayCurrency(
                      decimalPlaces(response.firstRepaymentAmount)
                    )}
                  </span>
                </li>
                <li>
                  <span className="loan-info__title">
                    {content.subsequentRepayments.label}
                  </span>
                  <span>
                    {displayCurrency(
                      decimalPlaces(response.subsequentRepaymentAmount)
                    )}
                  </span>
                </li>
                <li>
                  <span className="loan-info__title">
                    {content.totalAmount.label}
                  </span>
                  <span>
                    {displayCurrency(
                      decimalPlaces(response.totalAmountPayable)
                    )}
                  </span>
                </li>
                {response.isLoanDeferred === true && (
                  <li>
                    <span className="loan-info__title">
                      {content.defer.label}
                    </span>
                    <span>Yes</span>
                  </li>
                )}
              </ul>
              {(response.loanApr !== this.props.context.apr ||
                decimalPlaces(response.totalAmountPayable) !==
                  this.props.context.totalAmountPayable) && (
                <div className="accept-infoCallout">
                  <Callout
                    type="information"
                    marginBottom={6}
                    data-bdd="info-banner"
                  >
                    <Paragraph marginBottom={0}>{content.infoBanner}</Paragraph>
                  </Callout>
                </div>
              )}
              <Heading level={2} data-bdd="page-sub-heading">
                {content.pageSubHeading}
              </Heading>
              <Paragraph marginBottom={3} data-bdd="paragraph">
                {content.pageCopy1}
              </Paragraph>
              <Paragraph marginBottom={3} data-bdd="paragraph">
                {content.pageCopy2}
              </Paragraph>
              <Paragraph marginBottom={6} data-bdd="paragraph">
                {content.pageCopy3}
                <Link href={content.pageLinkUrl}>{content.pageLink}.</Link>
              </Paragraph>

              <Button
                data-bdd="home-page-button"
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  this.handleRedirect();
                }}
              >
                {content.buttonLabel}
              </Button>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Accept;
