import { employmentStatus } from "../../i18n/lookups/employmentStatus";

export default code => {
  var status;
  for (var i = employmentStatus.length; i--; ) {
    if (employmentStatus[i].value === code) {
      status = employmentStatus[i].label;
      break;
    }
  }
  return status;
};
